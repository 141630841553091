// packages/client/src/pages/Views.jsx
import React from "react";
import { Routes, Route } from "react-router-dom";

import ProtectedRoutes from "./ProtectedRoutes/ProtectedRoutes";
import { About, Login, Register, Lobby } from "pages";
import Landing from "pages/Landing/Landing";

import Chat from "./Chat/Chat";
import PageWrapper from "components/PageWrapper/PageWrapper";
import Profile from "./Profile/Profile";
import Chats from "./Chats/Chats";
import Privacy from "./Privacy/Privacy";
import Terms from "./Terms/Terms";
import Tags from "./Tags/Tags";
import TagDetail from "./Tags/TagDetail/TagDetail";
import NewStory from "./NewStory/NewStory";
import { Navbar } from "components";
import StoryDetail from "./StoryDetail/StoryDetail";
import NotFoundPage from "./NotFoundPage/NotFoundPage";

const Views = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PageWrapper ticker>
            <Landing />
          </PageWrapper>
        }
      />
      <Route
        element={<ProtectedRoutes loggedInOnly showOverlay redirectTo="/" />}
      >
        <Route path="/lobby" element={<Lobby />} />
      </Route>

      <Route path="/about" element={<About />} />
      <Route path="/login" element={<Login />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route
        path="/tags"
        element={
          <PageWrapper ticker>
            <Tags />
          </PageWrapper>
        }
      />
      <Route
        path="/tags/:tagName"
        element={
          <PageWrapper ticker>
            <TagDetail />
          </PageWrapper>
        }
      />
      <Route
        path="/stories/:storyId"
        element={
          <PageWrapper>
            <StoryDetail />
          </PageWrapper>
        }
      />
      <Route path="/new-story" element={<NewStory />} />

      <Route element={<ProtectedRoutes loggedInOnly redirectTo="/" />}>
        <Route path="/profile" element={<Profile />} />

        <Route path="/register" element={<Register />} />
        <Route path="/chats" element={<Chats />} />
        <Route path="/chat/:chatId" element={<Chat />} />
      </Route>
      <Route
        path="*"
        element={
          <PageWrapper>
            <NotFoundPage />
          </PageWrapper>
        }
      />
    </Routes>
  );
};

export default Views;
