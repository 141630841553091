import React from "react";
import styles from "./Modal.module.css";
import cn from "classnames";

const ModalHeader = ({ children, center }) => (
  <div className={cn(styles.modalHeader, { [styles.center]: center })}>
    {children}
  </div>
);

export default ModalHeader;
