// packages/client/src/components/Button/Button.jsx
import React from "react";
import cn from "classnames";
import styles from "./Button.module.css";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

/**
 * Button component to render a styled button with various themes and sizes.
 * It can also display a loading spinner when the `isLoading` prop is true.
 *
 * @param {string} className - Additional custom class for styling.
 * @param {string} color - Theme color of the button. Default is 'default'.
 * @param {string} size - Size of the button. Default is 'medium'.
 * @param {string} shape - Shape of the button. Default is 'rounded'.
 * @param {ReactNode} children - Content to be displayed inside the button.
 * @param {string} href - URL to navigate to on click.
 * @param {Function} onClick - Callback function that fires when the button is clicked.
 * @param {boolean} isLoading - If true, a loading spinner is displayed and button is disabled.
 * @param {string} cursor - The type of cursor to display on hover. Default is 'pointer'.
 * @param {string} buttonType - Type of the button ('button', 'submit', 'reset').
 * @param {string} ariaLabel - Accessible label for the button.
 * @param {boolean} disabled - If true, the button is disabled.
 */
const Button = ({
  className,
  color = "default", // default, primary, secondary, etc.
  size = "medium", // small, medium, large
  shape = "rounded", // rounded, square
  children,
  href,
  onClick,
  isLoading = false,
  cursor = "pointer",
  buttonType = "button", // button, submit, reset
  ariaLabel,
  disabled,
  ...rest
}) => {
  let navigate = useNavigate();

  const handleClick = (event) => {
    if (isLoading) return; // Prevent action if loading

    if (onClick) {
      onClick(event);
    }

    if (href) {
      navigate(href);
    }
  };

  return (
    <button
      type={buttonType}
      className={cn(
        className,
        styles.button,
        styles[color],
        styles[size],
        styles[shape],
        {
          [styles.loading]: isLoading,
          [styles.disabled]: disabled,
          [styles["default-cursor"]]: cursor === "default",
        }
      )}
      onClick={handleClick}
      aria-label={ariaLabel || children}
      disabled={disabled || isLoading}
      {...rest}
    >
      {isLoading ? <LoadingSpinner /> : <>{children}</>}
    </button>
  );
};

export default Button;
