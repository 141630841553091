import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale"; // Import updateLocale plugin

// Extend dayjs with the necessary plugins
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale); // Extend dayjs with updateLocale
// Update the relative time locale configuration
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "1 minute",
    mm: "%d minutes",
    h: "1 hour",
    hh: "%d hours",
    d: "1 day", // Customized
    dd: "%d days",
    M: "1 month",
    MM: "%d months",
    y: "1 year",
    yy: "%d years",
  },
});

/**
 * Calculates the time until the next review is allowed.
 *
 * @param {string|Date} lastReviewDate - The date of the last review.
 * @param {number} reviewCooldown - The cooldown period in days.
 * @returns {string} - A detailed message indicating when the next review can be submitted. Or false if review can be submitted.
 */
export const isReviewThrottled = (lastReviewDate, reviewCooldown) => {
  const nextReviewDate = dayjs(lastReviewDate).add(reviewCooldown, "day");
  const now = dayjs();

  if (nextReviewDate.isAfter(now)) {
    const diff = dayjs.duration(nextReviewDate.diff(now));
    const days = diff.days();
    const hours = diff.hours();
    const minutes = diff.minutes();

    let parts = [];

    if (days > 0) parts.push(`${days} day${days > 1 ? "s" : ""}`);
    if (hours > 0) parts.push(`${hours} hour${hours > 1 ? "s" : ""}`);
    if (minutes > 0) parts.push(`${minutes} minute${minutes > 1 ? "s" : ""}`);

    let message = parts.join(", ");

    return message;
  } else {
    return false;
  }
};

export const formatDate = (ISODate) => {
  const formattedDate = dayjs(ISODate).format("MMMM D, YYYY");
  return formattedDate;
};

/**
 * Formats a date into a relative time string (e.g., "1 min ago").
 *
 * @param {string|Date} date - The date to format.
 * @returns {string} - The formatted relative time string.
 */
export const formatRelativeTime = (date) => {
  return dayjs(date).fromNow();
};
