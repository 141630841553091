// packages/client/src/pages/Register/Register.jsx
import React, { useCallback, useContext, useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import { StepUsername } from "./StepUsername/StepUsername";
import { useMultistepForm } from "hooks/useMultistepForm";

import { StepPersonalInfo } from "./StepPersonalInfo/StepPersonalInfo";

import styles from "./Register.module.css";
import StepReview from "./StepReview/StepReview";

import StepLifeExperiences from "./StepLifeExperiences/StepLifeExperiences";
import Button from "components/Button/Button";

import { useNavigate } from "react-router-dom";
import { UserContext } from "contexts/UserContext";

import { ToastContext } from "contexts/ToastContext";

const INITIAL_DATA = {
  username: undefined,
  birthdate: undefined,
  location: "",
  lifeExperiences: [],
  gender: "",
};

export const Register = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    ...INITIAL_DATA,
  });
  const { updateUser } = useContext(UserContext);

  const [isCurrentStepValid, setIsCurrentStepValid] = useState(false);

  const updateFormData = useCallback((fields) => {
    setFormData((prev) => {
      return { ...prev, ...fields };
    });
  }, []);

  const handleStepValidity = useCallback((validity, message) => {
    setIsCurrentStepValid(validity);
  }, []);

  useEffect(() => {}, [isCurrentStepValid]);

  const {
    steps,
    currentStepIndex,
    step,
    isFirstStep,
    isLastStep,
    back,
    next,
    submit,
  } = useMultistepForm([
    <StepUsername
      data={formData}
      onSave={updateFormData}
      isCurrentStepValid={isCurrentStepValid}
      handleStepValidity={handleStepValidity}
    />,
    <StepPersonalInfo
      data={formData}
      onSave={updateFormData}
      isCurrentStepValid={isCurrentStepValid}
      handleStepValidity={handleStepValidity}
    />,
    <StepLifeExperiences
      data={formData}
      onSave={updateFormData}
      isCurrentStepValid={isCurrentStepValid}
      handleStepValidity={handleStepValidity}
    />,

    <StepReview data={formData} />,
  ]);

  useEffect(() => {}, [currentStepIndex, isFirstStep, isLastStep]);

  function handleBack() {
    if (!isFirstStep) {
      back();
    }
  }

  async function handleNext() {
    if (isLastStep) {
      addToast("Submitting form...", "success");

      try {
        const data = await submit(formData);
        addToast("Registration successful!", "success");

        // Additional success handling, like redirection
        updateUser(data.user);

        addToast("Redirecting...", "info");
        setTimeout(() => {
          navigate("/lobby"); // Replace '/lobby' with your desired route
        }, 2000);
      } catch (err) {
        addToast(err.message || "Registration failed", "error");
      }
    } else {
      next();
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (isCurrentStepValid) next();
  }

  const { addToast } = useContext(ToastContext);

  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit}>
        <AnimatePresence mode="wait">
          <motion.div className={styles.container}>
            <div className={styles.step}>
              {currentStepIndex + 1}/{steps.length}
            </div>
            <div className={styles.body}>{step}</div>
          </motion.div>
        </AnimatePresence>
        <div className={styles["btn-container"]}>
          {!isFirstStep && (
            <Button type="button" onClick={handleBack} disabled={isFirstStep}>
              Previous
            </Button>
          )}
          <Button
            type="button"
            onClick={handleNext}
            disabled={!isCurrentStepValid}
          >
            {isLastStep ? "Finish" : "Next"}
          </Button>
        </div>
      </form>
    </>
  );
};

export default Register;
