import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as API from "api/stories"; // Assume you have this API function
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import styles from "./StoryDetail.module.css";
import { UserContext } from "contexts/UserContext";
import cn from "classnames";
import { FaShare } from "react-icons/fa";
import { LuMessageSquarePlus } from "react-icons/lu";
import { formatRelativeTime } from "utils/dates";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";

function StoryDetail() {
  const { userData } = useContext(UserContext);
  const { storyId } = useParams();
  const [story, setStory] = useState(null);
  const [error, setError] = useState(""); // New state for storing the error message
  const [isLoading, setIsLoading] = useState(true); // Added loading state

  useEffect(() => {
    const loadStory = async () => {
      setIsLoading(true); // Begin loading
      try {
        const fetchedStory = await API.fetchStoryById(storyId);
        setStory(fetchedStory);
        setError(""); // Clear any previous errors
      } catch (error) {
        console.error("Failed to load story:", error);
        setError(error.message);
      } finally {
        setIsLoading(false); // End loading
      }
    };

    loadStory();
  }, [storyId]);

  const [isHugged, setIsHugged] = useState(false);

  const isHuggedByUser = (story) => {
    if (!userData || !story.hugs) {
      return false;
    }
    return story.hugs.includes(userData._id);
  };

  useEffect(() => {
    if (story && userData) {
      const isHugged = isHuggedByUser(story);
      setIsHugged(isHugged);
    }
  }, [story, userData]);

  const handleHug = async () => {
    // Toggle isHugged state optimistically
    setIsHugged(!isHugged);

    try {
      await API.hugStory(storyId);
      // If the API call succeeds, the optimistic update is correct, no action needed
      // Optionally, refresh the story or user data if needed to ensure consistency
    } catch (error) {
      console.error("Failed to toggle hug on the story:", error);
      // Revert the isHugged state if the API call fails
      setIsHugged(!isHugged);
    }
  };

  if (isLoading) {
    return (
      <div className={styles.container}>
        <div className={styles.loading}>
          <LoadingMessage message="Loading story details..." />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.container}>
        <div className={styles.loading}>
          <ErrorMessage message={error} />
        </div>
      </div>
    );
  }

  if (!story) {
    return (
      <div className={styles.container}>
        <div className={styles.loading}>
          <ErrorMessage message="Story not found." />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{story.title}</h1>
      <div className={styles.meta}>
        <p className={styles.author}>{story.author.username}</p>
        <p className={styles.date}>{formatRelativeTime(story.createdAt)}</p>
      </div>
      <div className={styles.actions}>
        {" "}
        <button
          className={cn(styles.actionButton, { [styles.hugged]: isHugged })}
          onClick={handleHug}
        >
          <span>🫂</span>
          <span>{isHugged ? "Hugged" : "Hug"}</span>
          <span> ({story.hugs ? story.hugs.length : 0})</span>
        </button>
        <button
          className={styles.actionButton}
          onClick={() => {
            /* Implement reach out functionality */
          }}
        >
          <LuMessageSquarePlus style={{ fontSize: "1.25rem" }} />
          <span>Reach Out</span>
        </button>
        <button
          className={styles.actionButton}
          onClick={() => {
            /* Implement share functionality */
          }}
        >
          <FaShare />
          <span>Share</span>
        </button>
      </div>
      <div
        className={styles.body}
        dangerouslySetInnerHTML={{ __html: story.body }}
      />
      {/* other story details */}
    </div>
  );
}

export default StoryDetail;
