// packages/client/src/components/ReviewModal/ReviewModal.jsx
import React, { useEffect, useRef, useState } from "react";
import styles from "./ReviewModal.module.css";

import ModalBody from "components/Modal/ModalBody";
import ModalHeader from "components/Modal/ModalHeader";
import Modal from "components/Modal/Modal";

import Button from "components/Button/Button";
import StarRating from "./StarRating";
import cn from "classnames";
import FloatingTooltip from "components/FloatingTooltip/FloatingTooltip";
import { isReviewThrottled } from "utils/dates";

const ReviewListModal = ({ chat, isOpen, onClose }) => {
  // Check if reviews are present or if the array is empty
  const hasReviews = chat.reviews && chat.reviews.length > 0;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>Previous Reviews</ModalHeader>
      <ModalBody>
        {hasReviews ? (
          <ul className={styles.reviewList}>
            {chat.reviews.map((review) => (
              <li key={review._id} className={styles.reviewItem}>
                <div className={styles.reviewRating}>
                  <StarRating rating={review.rating} totalStars={5} disabled />
                </div>
                <div className={styles.reviewText}>{review.comments}</div>
                <div className={styles.reviewDate}>
                  Reviewed on: {new Date(review.createdAt).toLocaleDateString()}
                </div>
                <div className={styles.reviewStatus}>
                  Status: {review.status}
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className={styles.noReviews}>No reviews yet.</p>
        )}
      </ModalBody>
    </Modal>
  );
};

const ReviewModal = ({ chat, reviewee, isOpen, onSubmit, onClose }) => {
  const [rating, setRating] = useState();
  const [ratingDesc, setRatingDesc] = useState("");
  const [comments, setComments] = useState();
  const [errors, setErrors] = useState({});
  const [reviewSubmitted, setReviewSubmitted] = useState(null);
  const [loading, setLoading] = useState(false);

  const validate = () => {
    const newErrors = {};

    if (!rating) {
      // Assuming rating is required and should be non-zero
      newErrors.rating = "Rating is required.";
    }

    if (!comments || comments.trim() === "") {
      newErrors.comments = "Comments are required.";
    }

    setErrors(newErrors);

    setTimeout(() => {
      setErrors({});
    }, 3000);

    // Return false if there are any errors
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      // API call to submit the review

      // Validate inputs before submission
      if (!validate()) {
        return; // Stop the submission if validation fails
      }

      setLoading(true);

      const res = await onSubmit({ chat, rating, comments });

      setLoading(false);
      setReviewSubmitted(true);

      // onClose(); // Close the modal after submission
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    switch (rating) {
      case 0:
        break;
      case 1:
        setRatingDesc(
          "Disconnected. Felt like talking to a wall. It was rude and left me feeling unheard and disrespected."
        );
        break;
      case 2:
        setRatingDesc(
          "Rough start. There were moments of understanding, but mostly it was challenging and felt off-track. "
        );
        break;
      case 3:
        setRatingDesc(
          "So-So. It was okay—not bad, but nothing standout. Felt like we were just going through the motions."
        );
        break;
      case 4:
        setRatingDesc(
          "Getting there. We're on the right path. Saw glimpses of a great chat, but there's still room for improvement."
        );
        break;
      case 5:
        setRatingDesc(
          "Perfect connection. Couldn't have gone better. Felt respected, understood, and genuinely connected."
        );
        break;
      default:
        setRatingDesc("");
    }
  }, [rating]);

  const [reviewThrottle, setReviewThrottle] = useState(null);

  useEffect(() => {
    // Function to update the next review available message
    const updateReviewAvailability = () => {
      if (chat.reviews && chat.reviews.length > 0) {
        // Assuming a cooldown period of 2 days
        const message = isReviewThrottled(chat.reviews[0].createdAt, 2);

        if (message) {
          setReviewThrottle(`You can submit your next review in ${message}.`);
        } else {
          setReviewThrottle(false);
        }
      } else {
        setReviewThrottle(false);
      }
    };

    updateReviewAvailability(); // Call it immediately to set initial value

    // Set an interval to update the message every minute (60,000 milliseconds)
    const intervalId = setInterval(updateReviewAvailability, 60000);

    // Cleanup function to clear the interval when the component unmounts or chat.reviews changes
    return () => clearInterval(intervalId);
  }, [chat.reviews]);

  useEffect(() => {}, [reviewThrottle]);

  const ratingLabelRef = useRef();
  const textAreaRef = useRef();

  const [showPreviousReviews, setShowPreviousReviews] = useState(false);

  const handleShowReviews = () => {
    setShowPreviousReviews(true);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>
        How is your chat with {reviewee.username} going?
      </ModalHeader>
      <ModalBody className={styles.body}>
        <p>
          This review will remain completely confidential and will help us
          improve our matching algorithm.
        </p>
        {reviewThrottle && (
          <p className={styles.nextReviewAvailable}>{reviewThrottle}</p>
        )}
        <div
          className={cn(styles.formContainer, {
            [styles.disabled]: reviewThrottle,
          })}
          onSubmit={handleSubmit}
        >
          <div className={cn(styles.formField, styles.rating)}>
            <label ref={ratingLabelRef} className={styles.formFieldLabel}>
              Rating:
            </label>
            <StarRating
              totalStars={5}
              rating={rating}
              setRating={setRating}
              disabled={reviewThrottle || reviewSubmitted}
            />
            <div className={styles.ratingDesc}>
              <p key={ratingDesc}>{ratingDesc}</p>
            </div>
          </div>
          {errors.rating && (
            <FloatingTooltip anchorRef={ratingLabelRef} show={errors.rating}>
              <p style={{ color: "white" }}>{errors.rating}</p>
            </FloatingTooltip>
          )}

          <div className={styles.formField}>
            <label className={styles.formFieldLabel}>Comments:</label>
            <textarea
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              ref={textAreaRef}
              disabled={reviewThrottle || reviewSubmitted}
            />
          </div>

          {errors.comments && (
            <FloatingTooltip anchorRef={textAreaRef} show={errors.comments}>
              <p style={{ color: "white" }}>{errors.comments}</p>
            </FloatingTooltip>
          )}

          <Button
            className={styles.submitBtn}
            onClick={handleSubmit}
            disabled={reviewThrottle || reviewSubmitted}
            isLoading={loading}
          >
            {!reviewSubmitted ? "Submit Review" : "Review submitted"}
          </Button>
          <Button onClick={handleShowReviews} size="small" shape="square">
            Show Previous Reviews ({chat.reviews.length})
          </Button>

          <ReviewListModal
            chat={chat}
            isOpen={showPreviousReviews}
            onClose={() => setShowPreviousReviews(false)}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ReviewModal;
