// packages/client/src/components/Modal/ModalButtons.jsx
import React from "react";

import styles from "./Modal.module.css";

const ModalButtons = ({ children }) => {
  return <div className={styles.btnContainer}>{children}</div>;
};

ModalButtons.isModalButtons = true;

export default ModalButtons;
