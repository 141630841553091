import PageWrapper from "components/PageWrapper/PageWrapper";
import React from "react";
import styles from "./Privacy.module.css";

const Privacy = () => {
  return (
    <PageWrapper footer>
      <div className={styles["privacy-policy"]}>
        <h1>Privacy</h1>
        <ol>
          <li>
            <h2>Introduction</h2>
            <ol>
              <li>
                Welcome to Stumble, an app designed to connect individuals
                through shared life experiences. This Privacy Policy outlines
                how we collect, use, and protect your personal data.
              </li>
            </ol>
          </li>
          <li>
            <h2>Information Collection</h2>
            <ol>
              <li>
                <span className={styles.highlighted}>Personal Data:</span>{" "}
                Includes name, email, age, and any information you allow us to
                collect from your social profiles.
              </li>
              <li>
                <span className={styles.highlighted}>Usage Data:</span> Includes
                IP address, geographical location, browser type, and interaction
                details with our service.
              </li>
              <li>
                <span className={styles.highlighted}>Content Data:</span>{" "}
                Includes Information you provide in your profile and through
                interactions within the app.
              </li>
            </ol>
          </li>
          <li>
            <h2>Use of Your Information</h2>
            <ol>
              <li>
                Your data is used to facilitate your experience on Stumble,
                including profile creation, service personalization, and
                community engagement.
              </li>
            </ol>
          </li>
          <li>
            <h2>Sharing of Information</h2>
            <ol>
              <li>
                We share information only when necessary to provide our
                services, comply with the law, or protect our rights and safety.
              </li>
            </ol>
          </li>
          <li>
            <h2>Your Rights</h2>
            <ol>
              <li>
                You have the right to access, update, or delete your personal
                data at any time.
              </li>
            </ol>
          </li>
          <li>
            <h2>Data Security</h2>
            <ol>
              <li>
                We implement robust measures to protect your information, though
                no system can be completely secure.
              </li>
            </ol>
          </li>
          <li>
            <h2>Changes to This Policy</h2>
            <ol>
              <li>
                Any changes will be communicated through our service. Your
                continued use after changes indicates acceptance.
              </li>
            </ol>
          </li>
          <li>
            <h2>Contact Us</h2>
            <ol>
              <li>
                For questions or concerns about our privacy practices, please
                contact us at help@stumble.chat.
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </PageWrapper>
  );
};

export default Privacy;
