// packages/client/src/api/users.js
import api from "./index"; // Ensure this path is correct

export const submitFormData = async (formData) => {
  try {
    const response = await api.post("/users/register", formData);

    // Directly return the response data; no need for explicit status check here
    return response.data;
  } catch (error) {
    // Errors are now handled by the interceptor or here if specific logic is needed
    handleError(error);
    // Optionally re-throw the error if you want calling code to handle it further
    throw error;
  }
};

export const updateUserData = async (userData) => {
  try {
    const response = await api.post("/users/update", userData);
    if (response.status !== 200) {
      throw new Error("Update user failed: " + response.data.message);
    }

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const checkAvailability = async (username) => {
  try {
    const response = await api.get("/users/check-availability", {
      params: { username },
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const getUserDataById = async (userId) => {
  try {
    const response = await api.get(`/users/${userId}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const fetchUserChats = async (userId) => {
  try {
    const res = await api.get(`/users/${userId}/chats`);
    return res.data;
  } catch (error) {
    handleError(error);
  }
};

export const createLifeExperience = async (userId, lifeExperienceData) => {
  try {
    const response = await api.post(
      `/users/${userId}/lifeexperiences`,
      lifeExperienceData
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const fetchLifeExperiences = async (userId) => {
  try {
    const res = await api.get(`/users/${userId}/lifeexperiences`);
    return res.data;
  } catch (err) {
    handleError(err);
  }
};

export const updateLifeExperience = async (
  experienceId,
  lifeExperienceData
) => {
  try {
    const response = await api.put(
      `/users/lifeexperiences/${experienceId}`,
      lifeExperienceData
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const deleteLifeExperience = async (experienceId) => {
  try {
    const response = await api.delete(`/users/lifeexperiences/${experienceId}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

function handleError(error) {
  console.error("API error: ", error);

  if (error.response) {
    // When the backend sends a structured error response
    if (
      error.response.data.errors &&
      Array.isArray(error.response.data.errors)
    ) {
      // Join all error messages into a single message
      const errorMessage = error.response.data.errors
        .map((e) => e.message)
        .join(", ");
      throw new Error(errorMessage);
    } else {
      // For any other type of error message format
      throw new Error(
        error.response.data.message || "Server responded with an error"
      );
    }
  } else if (error.request) {
    throw new Error("No response from server");
  } else {
    throw new Error("Error in setting up request: " + error.message);
  }
}
