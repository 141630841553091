import React, { useState, useEffect, useRef } from "react";

import styles from "./About.module.css";
import cn from "classnames";
import dalio_quote from "assets/dalio_quote.png";
import population_historical from "assets/population_historical.jpeg";
import maya_angelou from "assets/maya-angelou.jpg";
import deep_convo_space from "assets/deep_convo_space.png";

function SpeechBubble({ children, setActiveIndex, bubbleId }) {
  const [isVisible, setIsVisible] = useState(false);
  const bubbleRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Adjust the threshold as needed
    };

    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
      if (entry.isIntersecting) {
        setActiveIndex(bubbleId);
      }
    }, options);

    if (bubbleRef.current) {
      observer.observe(bubbleRef.current);
    }

    return () => {
      if (bubbleRef.current) {
        observer.unobserve(bubbleRef.current);
      }
    };
  }, []);

  return (
    <div className={styles.bubbleSpacer} ref={bubbleRef}>
      <div className={cn(styles.bubble, { [styles.visible]: isVisible })}>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
}

function ScrollUpButton() {
  return <div className={cn(styles.scrollButton, styles.scrollUp)}></div>;
}

function ScrollDownButton() {
  return <div className={cn(styles.scrollButton, styles.scrollDown)}></div>;
}

function About() {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {}, [activeIndex]);

  const bubbles = [
    <p>The premise of this app is simple.</p>,
    <>
      <img src={dalio_quote} />
      <p>- Ray Dalio</p>
    </>,
    <img src={maya_angelou} />,
    <img src={deep_convo_space} />,

    <p>
      As crazy as it sounds, we aren't the first to walk the paths we're on, and
      we won't be the last.
    </p>,
    <p>
      This app is a bet that there is someone out there, beyond your four walls
      of circumstance, who can understand your experience.
    </p>,
    <p>
      And also a bet that <span style={{ fontWeight: 800 }}>you</span> might be
      that someone, for someone else.
    </p>,
  ];

  // onScroll={handleScroll}

  return (
    <div className={styles.wrapper}>
      {bubbles.map((content, i) => (
        <SpeechBubble key={i} setActiveIndex={setActiveIndex} bubbleId={i}>
          {content}
        </SpeechBubble>
      ))}
      <ScrollDownButton></ScrollDownButton>
    </div>
  );
}

export default About;
