import handleError from "./handleError";
import api from "./index"; // Ensure this path is correct

export const fetchStoriesByTag = async (
  tagId,
  page = 1,
  limit = 10,
  sort = "createdAt"
) => {
  try {
    const response = await api.get(`/stories/by-tag/${tagId}`, {
      params: { page, limit, sort },
    });
    return response.data; // This should include stories, totalCount, and totalPages
  } catch (error) {
    console.error("Error fetching stories by tag:", error);
    throw error;
  }
};

export const submitStory = async (storyData) => {
  try {
    const response = await api.post("/stories", storyData);
    return response.data;
  } catch (error) {
    console.error("Error submitting story:", error);
    throw error;
  }
};

export const fetchStoryById = async (storyId) => {
  try {
    const response = await api.get(`/stories/${storyId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching story by id:", error);
    handleError(error);
  }
};

export const hugStory = async (storyId) => {
  try {
    const response = await api.post(`/stories/${storyId}/hug`);
    return response.data;
  } catch (error) {
    console.error("Error hugging story:", error);
    throw error;
  }
};
