// packages/client/src/pages/Lobby/FloatingTooltip/FloatingTooltip.jsx
import React, { forwardRef, useEffect, useState } from "react";
import { createPopper } from "@popperjs/core";

import styles from "./FloatingTooltip.module.css";

const FloatingTooltip = ({ anchorRef, children, show, placement = "top" }) => {
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  useEffect(() => {
    let popperInstance = null;
    if (show && anchorRef.current && popperElement) {
      popperInstance = createPopper(anchorRef.current, popperElement, {
        placement: placement, // or any other placement
        modifiers: [
          {
            name: "arrow",
            options: {
              element: arrowElement,
              padding: ({ popper, reference, placement }) => {
                return popper.width / 2;
              },
            },
          },
          { name: "offset", options: { offset: [0, 8] } },
        ],
      });
    }
    return () => {
      if (popperInstance) {
        popperInstance.destroy();
      }
    };
  }, [show, popperElement, arrowElement]);

  if (!show) return null;

  return (
    <div ref={setPopperElement} role="tooltip" className={styles.tooltip}>
      {children}
      <div ref={setArrowElement} data-popper-arrow className={styles.arrow} />
    </div>
  );
};

export default FloatingTooltip;
