// packages/client/src/pages/Register/StepPersonalInfo.jsx
import React, { useState, useEffect, useRef } from "react";
import countryList from "country-list";
import { motion } from "framer-motion";
import cn from "classnames";
import common from "../Register.module.css";
import self from "./StepPersonalInfo.module.css";

import Combobox from "components/Combobox/Combobox";

import DatePickerInput from "components/DatePickerInput/DatePickerInput";
import JoyrideWrapper from "components/JoyrideWrapper/JoyrideWrapper";
import debounce from "lodash/debounce";

const steps = [
  {
    target: '[data-tour="birthdate-input"]',
    title: "Why is Age Important?",
    content:
      "Age influences our perspectives and the advice we give. Knowing whether advice comes from someone in their 50s or 20s adds important context, allowing you to better understand their viewpoint based on their life experiences. It helps tailor conversations to your life stage, making interactions more relevant and relatable.",
    disableBeacon: true,
  },
  {
    target: '[data-tour="birthdate-input"]',
    title: "Keeping Age Private",
    content:
      "While we use your birthdate to understand your age group, this specific detail is kept confidential. We categorize ages (e.g., '20s', '30s') to maintain privacy while enabling meaningful exchanges. This way, you get advice suited to your life phase without revealing personal details, striking a balance between openness and privacy.",
    disableBeacon: true,
  },
  {
    target: '[data-tour="gender-input"]',
    title: "Why is Gender Important?",
    content:
      "Gender can deeply influence our experiences and the advice we share. Recognizing whether advice comes from a male or female perspective, or any gender identity, allows you to contextualize their insights against your own experiences. It enriches conversations by introducing diverse viewpoints, making the advice more nuanced and comprehensive.",
    disableBeacon: true,
  },
  {
    target: '[data-tour="country-input"]',
    title: "Why is Country Important?",
    content:
      "The cultural background of advice givers can significantly affect the relevance and applicability of their suggestions. Understanding whether someone comes from a liberal or conservative country, or any other cultural context, provides essential background for their advice. This knowledge enables more meaningful conversations by considering cultural nuances, ensuring advice feels more tailored and applicable to your own cultural perspective.",
    disableBeacon: true,
  },
];

export const StepPersonalInfo = ({ onSave, data, handleStepValidity }) => {
  // Local state for inputs
  const [birthdate, setBirthdate] = useState(data.birthdate || null);
  const [gender, setGender] = useState(data.gender || "");
  const [country, setCountry] = useState(data.location || "");
  const [age, setAge] = useState("");
  // State for validation errors
  const [errors, setErrors] = useState({
    birthdate: null,
    gender: null,
    country: null,
  });

  // Validate date of birth and age (must be 18 or older)
  const validateBirthdate = (dob) => {
    if (!dob) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        birthdate: "Date of birth is required.",
      }));
      return false;
    }

    // Calculate age
    const birthday = new Date(dob);
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);
    const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);

    // If date of birth is valid and age is 18 or older
    setErrors((prevErrors) => ({ ...prevErrors, birthdate: null }));
    return true;
  };

  // Validate gender
  const validateGender = (gender) => {
    if (!gender) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        gender: "Gender is required.",
      }));
      return false;
    }
    setErrors((prevErrors) => ({ ...prevErrors, gender: null }));
    return true;
  };

  // Validate country
  const validateCountry = (country) => {
    if (!country) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        country: "Country is required.",
      }));
      return false;
    }
    setErrors((prevErrors) => ({ ...prevErrors, country: null }));
    return true;
  };

  // Update the parent state and perform validation
  const handleDateOfBirthChange = (date) => {
    setBirthdate(date);
  };

  // Create a debounced version of the function that checks form validity
  const checkFormValidity = debounce(() => {
    const isFormValid =
      validateBirthdate(birthdate) &&
      validateGender(gender) &&
      validateCountry(country);

    handleStepValidity(isFormValid);
  }, 500); // Adjust the debounce delay as needed

  useEffect(() => {
    // Call the debounced function in the effect
    checkFormValidity();

    // Cleanup function to cancel the debounced calls if the component unmounts
    return () => {
      checkFormValidity.cancel();
    };
  }, [birthdate, gender, country]);

  useEffect(() => {
    return () => {
      handleStepValidity(false, "reset");
    };
  }, []);

  // Effect to calculate age when date of birth changes
  useEffect(() => {
    if (birthdate) {
      const birthday = new Date(birthdate);
      const ageDifMs = Date.now() - birthday.getTime();
      const ageDate = new Date(ageDifMs);
      setAge(Math.abs(ageDate.getUTCFullYear() - 1970));
      // Update the parent state
      onSave({ birthdate: birthdate });
    }
  }, [birthdate, onSave]);

  // Update the parent state when gender or country changes
  useEffect(() => {
    onSave({ gender, location: country });
  }, [gender, country, onSave]);

  // Options for the gender select
  const genderOptions = ["Female", "Male", "Other"];

  // Get the list of countries from country-list
  const countryOptions = countryList.getData().map((country) => country.name); // Returns an array of { code: 'XX', name: 'CountryName' }

  const [animationStep, setAnimationStep] = useState(0);

  const variants = {
    hidden: { opacity: 0, pointerEvents: "none" },
    visible: { opacity: 1, pointerEvents: "auto" },
  };

  const [startTour, setStartTour] = useState(false);

  useEffect(() => {
    if (animationStep === 3) setStartTour(true);
  }, [animationStep]);

  const isFirstOpen = useRef(true); // Add this line to track the first open

  useEffect(() => {
    if (startTour) {
      isFirstOpen.current = false;
    }
  }, [startTour]);

  return (
    <>
      {startTour && (
        <JoyrideWrapper steps={steps} runOnInit={isFirstOpen.current} />
      )}
      <div style={{ marginBottom: "10px" }}>
        <motion.div
          className={common.header}
          variants={variants}
          transition={{ ease: "easeIn", duration: 0.5 }}
          initial="hidden"
          animate={animationStep >= 0 ? "visible" : "hidden"}
          onAnimationComplete={() => {
            if (animationStep === 0)
              setTimeout(() => {
                setAnimationStep(1);
              }, 500);
          }}
        >
          <h1>Hi, {data.username}.</h1>
        </motion.div>
        <motion.div
          variants={variants}
          transition={{ ease: "easeIn", duration: 0.5 }}
          initial="hidden"
          animate={animationStep >= 1 ? "visible" : "hidden"}
          onAnimationComplete={() => {
            if (animationStep === 1) setAnimationStep(2);
          }}
        >
          <div className={cn(common["with-tooltip"])}>
            <span style={{ fontSize: "20px", textAlign: "center" }}>
              Help us find others like you.
            </span>
          </div>
        </motion.div>
      </div>
      <motion.div
        className={cn(common["overflow-wrapper"], self.container)}
        data-tour="form-body"
        variants={variants}
        transition={{ ease: "easeIn", duration: 0.5 }}
        initial="hidden"
        animate={animationStep >= 2 ? "visible" : "hidden"}
        onAnimationComplete={() => {
          if (animationStep === 2) setAnimationStep(3);
        }}
      >
        <div className={common["input-group"]} data-tour="birthdate-input">
          <label>Date of Birth</label>
          <DatePickerInput
            value={birthdate}
            onChange={handleDateOfBirthChange}
            className={common.input}
          />
          {errors.birthdate && (
            <div className={common.errorMessage}>{errors.birthdate}</div>
          )}
        </div>
        <div className={common["input-group"]} data-tour="gender-input">
          <label>Gender</label>
          <Combobox
            options={genderOptions}
            value={gender}
            onSelect={setGender}
            placeholder="Select Gender"
          />
          {errors.gender && (
            <div className={common.errorMessage}>{errors.gender}</div>
          )}
        </div>
        <div
          className={cn(common["input-group"], self.countryInput)}
          data-tour="country-input"
        >
          <label>Country</label>
          <Combobox
            options={countryOptions}
            value={country}
            onSelect={setCountry}
            placeholder="Select Country"
          />
          {errors.country && (
            <div className={common.errorMessage}>{errors.country}</div>
          )}
        </div>
      </motion.div>
    </>
  );
};
