// packages/client/src/pages/Profile/LifeExperienceContent.jsx
import React, { useContext, useEffect, useState } from "react";

import styles from "./LifeExperienceContent.module.css";
import { UserContext } from "contexts/UserContext"; // Import your UserContext

import cn from "classnames";

import * as USERS_API from "api/users";

import { ToastContext } from "contexts/ToastContext";
import LifeExperience from "components/LifeExperience/LifeExperience";
import Button from "components/Button/Button";
import Modal from "components/Modal/Modal";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import { useTags } from "hooks/useTags";
import { useLifeExperiences } from "hooks/useLifeExperience";

const AddLifeExpModal = ({
  isOpen,
  availableTags,
  onAdd,
  onCancel,
  isTagAlreadyUsed,
}) => {
  if (!isOpen) return null;

  const emptyData = { tag: { _id: "", name: "" }, story: "" };

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <LifeExperience
        availableTags={availableTags}
        data={emptyData}
        onSave={onAdd}
        onCancel={onCancel}
        isTagAlreadyUsed={isTagAlreadyUsed} // You would need to ensure this function is accessible here
        initMode="edit"
        editModeDisplay="inline"
      />
    </Modal>
  );
};

const LifeExperienceContent = () => {
  const { addToast } = useContext(ToastContext);
  const { userData } = useContext(UserContext);
  const availableTags = useTags();
  const {
    lifeExperiences,
    addLifeExperience,
    editLifeExperience,
    setLifeExperiences,
  } = useLifeExperiences();

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    // Function to fetch life experiences
    const loadLifeExperiences = async () => {
      try {
        setIsLoading(true);
        const experiences = await USERS_API.fetchLifeExperiences(userData._id);
        setIsLoading(false);
        setLifeExperiences(experiences); // Set the fetched life experiences in state
      } catch (err) {
        setIsLoading(false);
        console.error("Error fetching life experiences:", err);
        // Optionally use addToast from ToastContext to display an error message
        addToast("Error fetching life experiences.", "error");
      }
    };

    if (userData._id) {
      loadLifeExperiences();
    }
  }, [userData?._id]); // Dependency array to re-run the effect when userData._id changes

  // Function to initialize tagStories
  function initializeTagStories() {
    // Check if data has tagStories and they are not empty
    if (userData.tagStories && userData.tagStories.length > 0) {
      return userData.tagStories;
    }
    // Default to one blank tag story if none exist
    return [{ tag: { _id: "", name: "" }, story: "" }];
  }

  const [tagStories, setTagStories] = useState(initializeTagStories);

  useEffect(() => {}, [tagStories]);

  // Update logic for save
  async function handleSave(index, lifeExperience) {
    const { tag, story } = lifeExperience;

    const newLifeExperiences = [...lifeExperiences];
    const experience = newLifeExperiences[index];
    if (experience && experience._id) {
      // Check if it's an existing experience
      await USERS_API.updateLifeExperience(experience._id, { tag, story });
    } else {
      // If it's new, create it
      const newExperience = await USERS_API.createLifeExperience(userData._id, {
        tag,
        story,
      });
      newLifeExperiences.push(newExperience);
    }
    setLifeExperiences(newLifeExperiences);
    addToast("Life experience saved successfully.", "success");
  }

  function handleCancel(index) {
    // Revert to the original life experience
    const originalLifeExperience = lifeExperiences[index];

    if (isBlankLifeExperience(originalLifeExperience)) {
      // If the life experience is blank (newly added but not saved), remove it unless it's the only one
      if (lifeExperiences.length > 1) {
        const updatedLifeExperiences = lifeExperiences.filter(
          (_, i) => i !== index
        );
        setLifeExperiences(updatedLifeExperiences);
      } else {
        // If it's the only life experience, reset it to default
        setLifeExperiences([{ tag: { _id: "", name: "" }, story: "" }]);
      }
    } else {
      // If the life experience was edited but now we want to cancel the edit, revert changes
      const updatedLifeExperiences = [...lifeExperiences];
      updatedLifeExperiences[index] = originalLifeExperience;
      setLifeExperiences(updatedLifeExperiences);
    }
  }

  function isBlankLifeExperience(lifeExp) {
    return !lifeExp.story && !lifeExp.tag._id && !lifeExp.tag.name;
  }

  function isTagAlreadyUsed(selectedTagId, excludeIndex) {
    return lifeExperiences.some(
      (lifeExperience, index) =>
        index !== excludeIndex && lifeExperience.tag._id === selectedTagId
    );
  }

  async function handleDelete(experienceId, index) {
    await USERS_API.deleteLifeExperience(experienceId);
    const updatedLifeExperiences = lifeExperiences.filter(
      (_, i) => i !== index
    );
    setLifeExperiences(updatedLifeExperiences);
    addToast("Life experience deleted successfully.", "success");
  }

  const [isEditing, setIsEditing] = useState(false); // New state to track edit mode

  function handleStartEdit() {
    setIsEditing(true);
  }

  function handleEndEdit() {
    setIsEditing(false);
  }

  const [isAdding, setIsAdding] = useState(false);

  const toggleIsAdding = () => {
    setIsAdding((prev) => !prev);
  };

  return (
    <div>
      {isLoading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <LoadingMessage message="Loading life experiences" />
        </div>
      )}
      <div className={styles["tag-story-grid"]}>
        {lifeExperiences.map((experience, index) => (
          <div key={index} className={cn(styles["tag-story-wrapper"])}>
            <LifeExperience
              key={index}
              index={index}
              availableTags={availableTags}
              data={experience}
              onSave={(tag, story) => handleSave(index, tag, story)}
              onCancel={() => handleCancel(index)}
              onDelete={() => handleDelete(experience._id, index)}
              isTagAlreadyUsed={isTagAlreadyUsed}
              onStartEdit={handleStartEdit}
              onEndEdit={handleEndEdit}
              editModeDisplay="modal"
              className={cn(styles["tag-story"], styles.ricePaper)}
            />
          </div>
        ))}
        <div className={cn(styles.ricePaper, styles["add-new-tag"])}>
          <Button onClick={toggleIsAdding}>Add New Life Experience</Button>
        </div>
      </div>
      {isAdding && (
        <AddLifeExpModal
          isOpen={isAdding}
          availableTags={availableTags}
          onAdd={(lifeExp) => handleSave(null, lifeExp)}
          onCancel={() => setIsAdding(false)}
          isTagAlreadyUsed={isTagAlreadyUsed}
        />
      )}
    </div>
  );
};

export default LifeExperienceContent;
