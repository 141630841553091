// packages/client/src/api/quotes.js
import api from "api";

export const getQuotesByTags = async (tags) => {
  try {
    const res = await api.post("/quotes/by-tags", {
      tags,
    });

    const quotes = res.data;

    return quotes;
  } catch (error) {
    console.error("Axios error: ", error);

    // Handle specific error scenarios if needed
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx

      throw new Error(error.response.data.message || "Error in fetching data");
    } else if (error.request) {
      // The request was made but no response was received

      throw new Error("No response from server");
    } else {
      // Something happened in setting up the request that triggered an Error

      throw new Error("Error in setting up request");
    }
  }
};
