// packages/client/src/pages/Register/StepLifeExperiences.jsx
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import common from "../Register.module.css";
import self from "./StepLifeExperiences.module.css";
import { motion } from "framer-motion";
import Button from "components/Button/Button";
import LifeExperience from "components/LifeExperience/LifeExperience";
import { ToastContext } from "contexts/ToastContext";
import Modal from "components/Modal/Modal";
import { ReactTyped } from "react-typed";
import { useTags } from "hooks/useTags";
import { useLifeExperiences } from "hooks/useLifeExperience";
import JoyrideWrapper from "components/JoyrideWrapper/JoyrideWrapper";

const steps = [
  {
    target: '[data-tour="editor"]',
    title: "What is a Life Experience?",
    content: (
      <p>
        Life experiences include any significant struggles or challenges you've
        faced or are currently facing.
      </p>
    ),
    disableBeacon: true,
  },
  {
    target: '[data-tour="editor"]',
    title: "What is a Life Experience?",
    content: (
      <div style={{ textAlign: "start", display: "grid", gap: "1rem" }}>
        <p>For instance, you might share about: </p>
        <ol style={{ marginLeft: "20px" }}>
          <li>coping with the loss of a loved one,</li>
          <li>navigating the complexities of a medical condition,</li>
          <li>tackling relationship issues,</li>
          <li>facing academic or career pressures,</li>
          <li>overcoming financial difficulties.</li>
        </ol>
        By sharing these experiences, you provide insight into your journey,
        helping us to understand you better and to foster connections with
        others who can relate to what you've been through.
      </div>
    ),
    disableBeacon: true,
  },
  {
    target: '[data-tour="story-editor"]',
    title: "The Story",
    content:
      "Each life experience has a story. This is your space to share the journey of your struggle or challenge in detail. Telling your story helps give context to your experiences, making it easier for others to understand and relate to you.",
    disableBeacon: true,
  },
  {
    target: '[data-tour="tag-editor"]',
    title: "The Tag",
    content:
      "Along with your story, select a tag that best represents the theme of your experience. Tags help categorize your experiences, making it easier to connect you with others who have faced similar challenges.",
    disableBeacon: true,
  },
  {
    target: '[data-tour="editor"]',
    title: "Why Share Life Experiences?",
    content:
      "Sharing your life experiences aims to help you find others who've walked similar paths. It's about building connections based on shared struggles and triumphs, fostering a community of support and understanding.",
    disableBeacon: true,
  },

  {
    target: '[data-tour="editor"]',
    title: "Confidentiality of Your Experiences",
    content:
      "Your life experiences are private and shared only with Stumble and those you choose. We're committed to maintaining your confidentiality, ensuring a safe space for you to share and connect.",
    disableBeacon: true,
  },
  {
    target: '[data-tour="editor"]',
    title: "The Role of Your Experiences in Matching",
    content:
      "While your experiences remain confidential, they play a crucial role behind the scenes. By understanding your journey, we can better match you with someone who truly understands, ensuring more meaningful and supportive conversations.",
    disableBeacon: true,
  },
];

const AddLifeExpModal = ({
  isOpen,

  onAdd,
  onCancel,
  isTagAlreadyUsed,
  isFirstOpen,
}) => {
  const emptyData = { tag: { _id: "", name: "" }, story: "" };

  // note: I've placed the data-tour attributes inside the LifeExperience, TagEditor, and StoryEditor components themselves

  const [startTour, setStartTour] = useState(false);

  // Will run after commit/mount of Modal and LifeExperience
  // This assumes the Modal and LifeExperience are ready to be interacted with.
  useEffect(() => {
    if (isOpen) {
      setStartTour(true); //startTour renders JoyrideWrapper
    }
  }, []); // Depend on isOpen to re-evaluate when the modal opens

  useEffect(() => {
    if (startTour) {
      isFirstOpen.current = false;
    }
  }, [startTour]);

  if (!isOpen) return null;

  return (
    <>
      <Modal isOpen={isOpen} onClose={onCancel}>
        <LifeExperience
          data={emptyData}
          onSave={onAdd}
          onCancel={onCancel}
          isTagAlreadyUsed={isTagAlreadyUsed} // You would need to ensure this function is accessible here
          initMode="edit"
          editModeDisplay="inline"
        />
      </Modal>
      {startTour && (
        <JoyrideWrapper steps={steps} runOnInit={isFirstOpen.current} />
      )}
    </>
  );
};

const LifeExperienceList = ({
  lifeExperiences,

  isTagAlreadyUsed,
  onSave,
  onCancel,
  onDelete,
  onStartEdit,
  onEndEdit,
}) => {
  return (
    <>
      {lifeExperiences.length > 0 &&
        lifeExperiences.map((lifeExp, index) => (
          <LifeExperience
            key={index}
            index={index}
            data={lifeExp}
            onSave={(tag, story) => onSave(index, tag, story)}
            onCancel={() => onCancel(index)}
            onDelete={() => onDelete(index)}
            isTagAlreadyUsed={isTagAlreadyUsed}
            onStartEdit={onStartEdit}
            onEndEdit={onEndEdit}
            initMode="view"
            editModeDisplay="modal"
          />
        ))}
    </>
  );
};

const StepLifeExperiences = ({ data, onSave, handleStepValidity }) => {
  const { toastList, addToast, removeToast } = useContext(ToastContext);

  const [isEditing, setIsEditing] = useState(false); // New state to track edit mode
  const [isAdding, setIsAdding] = useState(false);

  const {
    lifeExperiences,
    addLifeExperience,
    editLifeExperience,
    setLifeExperiences,
  } = useLifeExperiences(data);

  // Update parent form data
  useEffect(() => {
    console.log("Updating form data");
    onSave({ lifeExperiences });
  }, [lifeExperiences, onSave]);

  const isTagAlreadyUsed = useCallback(
    (selectedTagId, excludeIndex) => {
      return lifeExperiences.some(
        (lifeExp, index) =>
          index !== excludeIndex && lifeExp.tag._id === selectedTagId
      );
    },
    [lifeExperiences]
  );

  const handleSave = (index, lifeExperience) => {
    const { tag, story } = lifeExperience;

    // Adding a new experience
    if (index === null) {
      if (isTagAlreadyUsed(tag._id)) {
        addToast("This tag has already been selected", "error");
        return;
      }
      addLifeExperience({ tag, story });
    } else {
      // Editing an existing experience
      if (
        isTagAlreadyUsed(tag._id, index) &&
        lifeExperiences[index].tag._id !== tag._id
      ) {
        addToast("This tag has already been selected", "error");
        return;
      }
      editLifeExperience(index, { tag, story });
    }

    setIsAdding(false);
    addToast("Life experiences successfully updated", "success");
  };

  const isBlankLifeExp = (lifeExp) => {
    return !lifeExp.story && !lifeExp.tag._id && !lifeExp.tag.name;
  };

  const handleStartEdit = () => {
    setIsEditing(true);
  };

  const handleEndEdit = () => {
    setIsEditing(false);
  };

  const handleCancel = (index) => {
    // Revert to the original tag story
    const original = data.lifeExperiences[index];

    if (isBlankLifeExp(original)) {
      handleDelete(index);
      return;
    } else {
      const updatedLifeExperiences = [...lifeExperiences];
      updatedLifeExperiences[index] = original;
      setLifeExperiences(updatedLifeExperiences);
    }
  };

  const handleDelete = (index) => {
    if (lifeExperiences.length > 1) {
      const updatedLifeExperiences = lifeExperiences.filter(
        (_, i) => i !== index
      );
      setLifeExperiences(updatedLifeExperiences);
    } else {
      // Handle the case where this is the only tag story
      // For example, reset to default or show a message
      setLifeExperiences([]);
    }
  };

  // Count the non-blank tag stories
  const nonBlank = lifeExperiences.filter(
    (lifeExp) => !isBlankLifeExp(lifeExp)
  ).length;

  // Function to check if the tag stories meet the validity criteria
  const checkValidity = () => {
    // Valid if there's at least one non-blank tag story and not more than three
    return nonBlank >= 1 && nonBlank <= 3 && !isEditing;
  };

  // Call this function whenever tagStories change or edit mode changes
  useEffect(() => {
    const isValid = checkValidity();
    handleStepValidity(isValid);
  }, [lifeExperiences, isEditing]);

  const canAddNew = !isEditing;

  // What has life been like for you, {username}?
  // typewritten

  const [animationStep, setAnimationStep] = useState(1);

  const variants = {
    hidden: { opacity: 0, pointerEvents: "none" },
    visible: { opacity: 1, pointerEvents: "auto" },
  };

  const isFirstOpen = useRef(true);

  const handleAddClick = () => {
    setIsAdding(true);
  };

  // Use this `handleAddClick` for the button's onClick event handler

  return (
    <>
      <div className={common.header}>
        <ReactTyped
          strings={[`What has life been like for you,^500 ${data?.username}?`]}
          typeSpeed={50}
          backSpeed={35}
          showCursor
          onComplete={(el) => {
            setTimeout(() => {
              el.cursor.hidden = true;
            }, 1500);
            setAnimationStep((prev) => prev + 1);
          }}
        />
      </div>

      <motion.div
        className={self.container}
        variants={variants}
        transition={{ ease: "easeIn", duration: 1 }}
        initial="hidden"
        animate={animationStep >= 1 ? "visible" : "hidden"}
        onAnimationComplete={() => {
          if (animationStep === 1) setAnimationStep(2);
        }}
      >
        <LifeExperienceList
          lifeExperiences={lifeExperiences}
          isTagAlreadyUsed={isTagAlreadyUsed}
          onSave={handleSave}
          onCancel={handleCancel}
          onDelete={handleDelete}
          onStartEdit={handleStartEdit} // Pass callback to start edit
          onEndEdit={handleEndEdit} // Pass callback to end edit
        />
        <div className={self.actionMenu}>
          {canAddNew && (
            <Button onClick={handleAddClick}>Write about an experience</Button>
          )}
        </div>
      </motion.div>
      {isAdding && (
        <AddLifeExpModal
          isOpen={isAdding}
          onAdd={(lifeExp) => handleSave(null, lifeExp)}
          onCancel={() => setIsAdding(false)}
          isTagAlreadyUsed={isTagAlreadyUsed}
          isFirstOpen={isFirstOpen}
        />
      )}
    </>
  );
};

export default StepLifeExperiences;
