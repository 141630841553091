import React, { useCallback, useEffect, useState } from "react";
import * as TAGS_API from "api/tags";
import styles from "./Tags.module.css";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import Modal from "../../components/Modal/Modal"; // Adjust the path as necessary
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { MdSearch } from "react-icons/md";
import { FaXmark } from "react-icons/fa6";
import cn from "classnames";
import { formatDate } from "utils/dates";
import SearchInput from "components/SearchInput/SearchInput";

const Card = ({ data, onClick }) => {
  const formattedDate = formatDate(data.createdAt);

  return (
    <div className={styles.card} onClick={(event) => onClick(data, event)}>
      <div className={styles.cardHeader}>
        <h3>{data.name}</h3>
        <span className={styles.hits}>{data.hits} hits</span>
      </div>

      <div className={styles.description}>
        <p>{data.description}</p>
      </div>
      {/* Display other data as needed */}
      <div className={styles.createdAt}>
        <p>created {formattedDate}</p>
      </div>
    </div>
  );
};

// const SearchInput = ({ value, onChange, onClear }) => {
//   const [isFocused, setIsFocused] = useState(false);

//   return (
//     <div className={styles.inputContainer}>
//       <MdSearch className={styles.icon} />
//       <input
//         className={cn(styles.input, { [styles.focused]: isFocused })}
//         type="text"
//         placeholder="Type a sentence..."
//         value={value}
//         onChange={onChange}
//         onFocus={() => setIsFocused(true)} // Assuming you add isFocused state
//         onBlur={() => setIsFocused(false)} // Assuming you add isFocused state
//       />
//       {value && (
//         <button
//           className={cn(styles["clear-btn"], styles.icon)}
//           onClick={(e) => {
//             e.stopPropagation();
//             onClear();
//           }}
//         >
//           &times;
//         </button>
//       )}
//     </div>
//   );
// };

function Tags() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState("relevance"); //relevance || hits || new
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const debouncedFetchTags = debounce(async () => {
      setIsLoading(true);
      try {
        const data = await TAGS_API.searchTags({
          query: searchTerm,
          type: "fullText",
          sort: sortOrder,
          page: currentPage,
          limit: 20,
        });
        setSearchResults(data.tags); // Assuming the API returns an object with tags and total pages
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error("Error fetching tags:", error);
      } finally {
        setIsLoading(false);
      }
    }, 500);

    debouncedFetchTags();

    // Cleanup function to cancel the debounce on component unmount or before re-running the effect
    return () => {
      debouncedFetchTags.cancel();
    };
  }, [searchTerm, sortOrder, currentPage]);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCardClick = (tagData, event) => {
    // Generate the URL for the new page with the hyphenated tag name
    const hyphenatedName = tagData.name.toLowerCase().replace(/\s+/g, "-");
    const url = `/tags/${hyphenatedName}`;

    // Check if Cmd (on macOS) or Ctrl (on Windows/Linux) is pressed during the click
    if (event.metaKey || event.ctrlKey) {
      // Open the URL in a new tab if Cmd or Ctrl is pressed
      window.open(url, "_blank");
    } else {
      // Navigate in the same tab if no modifier keys are pressed
      navigate(url);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const clearInput = () => {
    setSearchTerm("");
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Explore Tags</h1>
        <p>Find and discover unique tags</p>
      </div>

      <div className={styles.controls}>
        <SearchInput
          value={searchTerm}
          onChange={handleInputChange}
          onClear={clearInput}
        />
        <div className={styles.sort}>
          <button
            className={`${styles.sortButton} ${
              sortOrder === "relevance" ? styles.active : ""
            }`}
            onClick={() => setSortOrder("relevance")}
          >
            Relevance
          </button>
          <button
            className={`${styles.sortButton} ${
              sortOrder === "hits" ? styles.active : ""
            }`}
            onClick={() => setSortOrder("hits")}
          >
            Hits
          </button>
          <button
            className={`${styles.sortButton} ${
              sortOrder === "new" ? styles.active : ""
            }`}
            onClick={() => setSortOrder("new")}
          >
            New
          </button>
        </div>
      </div>

      {isLoading ? (
        <div className={styles.loading}>
          <LoadingMessage message="Loading tags..." />
        </div>
      ) : (
        <div className={styles.results}>
          {searchResults?.map((result) => (
            <Card key={result._id} data={result} onClick={handleCardClick} />
          ))}
        </div>
      )}
      <div className={styles.pagination}>
        {" "}
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i}
            className={`${styles.btn} ${
              currentPage === i + 1 ? styles.active : ""
            }`}
            onClick={() => handlePageChange(i + 1)}
          >
            {i + 1}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Tags;
