import * as TAGS_API from "api/tags";
import _ from "lodash";
const { createContext, useState, useEffect, useCallback } = require("react");

const LobbyContext = createContext();

const LobbyContextProvider = ({ children }) => {
  const [tags, setTags] = useState([]);
  const [story, setStory] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Add this line
  const [selectedTags, setSelectedTags] = useState([]);
  const [query, setQuery] = useState("");

  function selectTag(tag) {
    console.log("handleSelectTag", tag);
    const selectedIndex = selectedTags.findIndex((t) => t._id === tag._id);

    console.log("selectedIndex", selectedIndex);
    if (selectedIndex > -1) {
      // Tag is already selected, remove it
      const newSelected = [...selectedTags];
      newSelected.splice(selectedIndex, 1);
      setSelectedTags(newSelected);
    } else {
      // Tag is not selected, add it
      setSelectedTags((prev) => [...prev, tag]);
    }
  }

  function isTagSelected(tag) {
    return selectedTags.some((t) => t._id === tag._id);
  }

  useEffect(() => {
    console.log("selectedTags", selectedTags);
  }, [selectedTags]);

  return (
    <LobbyContext.Provider
      value={{
        selectTag,
        selectedTags,
        isTagSelected,
        query,
        setQuery,

        story,
        setStory,
      }}
    >
      {children}
    </LobbyContext.Provider>
  );
};

export { LobbyContextProvider, LobbyContext };
