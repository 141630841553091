// packages/client/src/components/InputWrapper/InputWrapper.jsx
import React from "react";
import { motion } from "framer-motion";
import cn from "classnames";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import styles from "./InputWrapper.module.css";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

const InputWrapper = ({ children, valid, helperText, isLoading = false }) => {
  return (
    <>
      <div className={cn(styles.inputWrapper, { [styles.error]: !valid })}>
        {children}
        {isLoading ? (
          <motion.div
            className={styles.icon}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <LoadingSpinner />
          </motion.div>
        ) : valid ? (
          <motion.div
            className={styles.icon}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <FaCheckCircle />
          </motion.div>
        ) : (
          <motion.div
            className={styles.icon}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <FaTimesCircle />
          </motion.div>
        )}
      </div>
      {helperText.length > 0 && (
        <p className={cn(styles.helperText, { [styles.error]: !valid })}>
          {helperText}
        </p>
      )}
    </>
  );
};

export default InputWrapper;
