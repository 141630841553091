import React from "react";
import LoadingSpinner from "./LoadingSpinner";
import styles from "./LoadingMessage.module.css";
import cn from "classnames";

const LoadingMessage = ({ className, message }) => {
  return (
    <div className={cn(styles.container, className)}>
      <LoadingSpinner />
      {message}
    </div>
  );
};

export default LoadingMessage;
