// packages/client/src/components/LifeExperience/StoryEditor.module.css
import React, { useEffect, useRef, useState } from "react";

import cn from "classnames";

import styles from "./LifeExperience.module.css";

import { FaEllipsis } from "react-icons/fa6";

import Button from "components/Button/Button";
import TagEditor from "./TagEditor";
import StoryEditor from "./StoryEditor";
import { createPopper } from "@popperjs/core";
import Portal from "components/Portal/Portal";
import * as TAGS_API from "api/tags";

import RecommendedTags from "pages/Lobby/RecommendedTags/RecommendedTags";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import Modal from "components/Modal/Modal";
import FloatingTooltip from "components/FloatingTooltip/FloatingTooltip";
import ModalHeader from "components/Modal/ModalHeader";
import ModalBody from "components/Modal/ModalBody";
import ModalButtons from "components/Modal/ModalButtons";

function InlineEditor(props) {
  const {
    selectedTag,
    tagError,
    isLoadingRecommendedTags,
    recommendedTags,
    story,
    storyError,
    handleSave,
    handleCancel,

    setSelectedTag,
    handleSetStory,
    className,
  } = props;
  const tagEditorRef = useRef(null);
  const storyEditorRef = useRef(null);

  return (
    <div
      className={cn(styles.container, styles["edit-mode"])}
      data-tour="editor"
    >
      <StoryEditor
        className={styles["story-editor"]}
        ref={storyEditorRef}
        error={storyError}
        story={story}
        setStory={handleSetStory}
      />
      <Portal>
        <FloatingTooltip
          anchorRef={storyEditorRef}
          show={!!storyError}
          placement="bottom-start"
        >
          {storyError}
        </FloatingTooltip>
      </Portal>
      <TagEditor
        error={tagError}
        ref={tagEditorRef}
        selected={selectedTag}
        onSelect={setSelectedTag}
        className={styles["tag-editor"]}
      />
      <Portal>
        <FloatingTooltip
          anchorRef={tagEditorRef}
          show={!!tagError}
          placement="bottom-start"
        >
          {tagError}
        </FloatingTooltip>
      </Portal>

      {isLoadingRecommendedTags ? (
        <LoadingMessage message="Loading recommended tags..." />
      ) : (
        recommendedTags.length > 0 && (
          <RecommendedTags
            recommendedTags={recommendedTags}
            isLoading={isLoadingRecommendedTags}
            onSelect={setSelectedTag}
            selected={selectedTag}
            isTagSelected={(tag) => selectedTag?._id === tag._id}
          />
        )
      )}
      <p className={styles.helper}>
        <a href="/tags" target="_blank">
          {" "}
          How do I find the right tag?{" "}
        </a>
      </p>
      <div className={styles.actionMenu}>
        <Button onClick={handleSave}>Save Tag</Button>
        <Button color="error" onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
}

function EditModal(props) {
  return (
    <Modal isOpen={true} onClose={props.handleCancel}>
      <InlineEditor {...props} />
    </Modal>
  );
}

function DeleteConfirmationModal({ isOpen, onCancel, onConfirm, children }) {
  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalHeader>Are you sure you want to delete this tag story?</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalButtons>
        <Button onClick={onConfirm}>Yes (Proceed)</Button>
        <Button onClick={onCancel} color="error">
          No (Go Back)
        </Button>
      </ModalButtons>
    </Modal>
  );
}

const EllipsisMenu = ({ onDelete, onEdit }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const ellipsisRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    let popperInstance = null;
    if (isDropdownOpen && ellipsisRef.current && dropdownRef.current) {
      popperInstance = createPopper(ellipsisRef.current, dropdownRef.current, {
        placement: "bottom-end",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 2], // Adjust these values to control the dropdown's offset from the input field
            },
          },
          {
            name: "preventOverflow",
            options: {
              boundary: "document", // Ensures the popper can overflow containers but not the viewport
            },
          },
        ],
      });
    }

    const handleClickOutside = (event) => {
      // Check if the click is outside of both the ellipsis and the dropdown
      if (
        ellipsisRef.current &&
        dropdownRef.current &&
        !ellipsisRef.current.contains(event.target) &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    // Add event listener when the dropdown is open
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      if (popperInstance) {
        popperInstance.destroy();
        popperInstance = null;
      }

      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <>
      <div ref={ellipsisRef}>
        <FaEllipsis
          className={styles["ellipsis-icon"]}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        />
      </div>
      <Portal>
        <ul
          ref={dropdownRef}
          className={cn(styles.dropdown, { [styles.show]: isDropdownOpen })}
        >
          <li onClick={onEdit}>Edit</li>
          <li onClick={onDelete}>Delete</li>
        </ul>
      </Portal>
    </>
  );
};

function Card(props) {
  if (props.isEditMode && props.editModeDisplay === "inline")
    return <InlineEditor {...props.editModeProps} />;

  return (
    <div className={cn(styles.container, styles.viewMode, props.className)}>
      {" "}
      <div className={cn(styles.tag, styles.selectedTag)}>
        {props.selectedTag?.name}
      </div>
      <EllipsisMenu
        onEdit={props.handleEdit}
        onDelete={() => props.setShowDeleteConfirm(true)}
      />
      <div className={styles.story}>{props.story}</div>
    </div>
  );
}

/**
 * Represents a single Tag Story component for viewing or editing tag stories.
 * It operates in "view" or "edit" mode based on `initMode` prop. In "edit" mode, it can display
 * an editing interface either inline or within a modal, depending on `editModeDisplay`.
 *
 * The component supports validation to ensure a tag is selected and a story is provided
 * before saving. It allows users to edit the details of a tag story, including selecting a tag
 * and writing a story.
 *
 * @param {Object} props - Component props.
 * @param {Array} props.availableTags - A list of available tags for selection.
 * @param {Object} props.data - Initial data for the tag story, containing the tag and story.
 * @param {Function} props.onSave - Callback function to handle saving the edited or new tag story.
 *                                  Expects an object `{ tag, story }` as parameter. Should return a promise
 *                                  that resolves upon successful save operation.
 * @param {Function} props.onCancel - Callback function to handle cancellation of edits. This function
 *                                    is called when the user cancels editing, reverting any changes.
 *                                    Should return `void`.
 * @param {Function} props.onDelete - Callback function to handle deletion of the tag story. This function
 *                                    is invoked when the user decides to delete the current tag story.
 *                                    Should return a promise that resolves upon successful deletion.
 * @param {Function} props.isTagAlreadyUsed - Function to check if a tag is already used within the current
 *                                            list of tag stories to prevent duplication. Returns `boolean`.
 * @param {number} props.index - Index of the current tag story in the list, useful for identifying
 *                               which tag story is being edited or deleted.
 * @param {Function} [props.onStartEdit=() => {}] - Optional callback function called when editing starts.
 *                                                  Allows a parent component to react to the start of editing.
 *                                                  Should return `void`.
 * @param {Function} [props.onEndEdit=() => {}] - Optional callback function called when editing ends.
 *                                                Allows a parent component to react to the completion
 *                                                or cancellation of editing. Should return `void`.
 * @param {string} props.initMode - Initial mode of the component. Can be "view" for displaying the tag story
 *                                  or "edit" for editing. Defaults to "view".
 * @param {string} props.editModeDisplay - Determines how the edit mode is displayed. Can be "inline" for
 *                                         inline editing or "modal" for editing in a modal dialog.
 * @param {string} [props.className] - Optional additional CSS class to apply to the component for custom styling.
 * @param {boolean} [props.isModalOpen=false] - Optional flag to control the visibility of the edit modal.
 * @param {Function} [props.closeModal=() => {}] - Optional function to close the edit modal.
 *
 * @returns {JSX.Element} The LifeExperience component, rendered based on the provided props and the current editing state.
 */
const LifeExperience = ({
  data,
  onSave,
  onCancel,
  onDelete,
  isTagAlreadyUsed,
  index,
  initMode = "view",
  editModeDisplay = "modal",
  onStartEdit = () => {},
  onEndEdit = () => {},
  className,
}) => {
  const [isEditMode, setIsEditMode] = useState(initMode === "edit");

  const [selectedTag, setSelectedTag] = useState(data.tag);
  const [story, setStory] = useState(data.story);

  const [tagError, setTagError] = useState("");
  const [storyError, setStoryError] = useState("");

  const isBlankLifeExp = (lifeExp) => {
    return !lifeExp?.story && !lifeExp?.tag?._id && !lifeExp?.tag?.name;
  };

  useEffect(() => {
    setSelectedTag(data.tag);
    setStory(data.story);
    if (isBlankLifeExp(data)) {
      setIsEditMode(true);
      onStartEdit();
    }
  }, [data]);

  const handleSetStory = (text) => {
    setStory(text);
  };

  const hasError = () => {
    let hasError = false;

    // Check if tag is selected
    if (!selectedTag?._id) {
      setTagError("Please select a tag.");
      hasError = true;
    } else if (isTagAlreadyUsed(selectedTag._id, index)) {
      setTagError("This tag has already been selected.");
      hasError = true;
    } else {
      setTagError("");
    }

    // Check if story is provided
    if (!story.trim()) {
      setStoryError("Please write a story.");
      hasError = true;
    } else {
      setStoryError("");
    }

    setTimeout(() => {
      setTagError("");
      setStoryError("");
    }, 3000);
    return hasError;
  };

  useEffect(() => {}, [tagError, storyError]);

  const handleSave = () => {
    let error = hasError();

    if (selectedTag && story && !error) {
      // Package the tag and story into a single object
      onSave({ tag: selectedTag, story });
      setIsEditMode(false); // Switch to read-only mode after saving
      onEndEdit(); // Call when edit mode ends after saving
    }
  };

  const handleEdit = () => {
    setIsEditMode(true); // Switch back to edit mode
    onStartEdit(); // Call when edit mode starts
  };

  const revert = () => {
    setSelectedTag(data.tag);
    setStory(data.story);
  };

  const handleCancel = () => {
    onCancel();
    revert();
    setIsEditMode(false); // Switch back to edit mode
    onEndEdit(); // Call when edit mode ends after saving
  };

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleDelete = () => {
    onDelete();
    onEndEdit(); // Call when edit mode ends after saving
  };

  const [recommendedTags, setRecommendedTags] = useState([]);
  const [isLoadingRecommendedTags, setIsLoadingRecommendedTags] =
    useState(false);

  const fetchRecommendedTags = async (storyContent) => {
    if (!storyContent.trim()) return; // Avoid fetching with empty or trivial content

    setIsLoadingRecommendedTags(true);
    try {
      // Assuming TAGS_API.searchTags can accept story content and return relevant tags
      const data = await TAGS_API.searchTags({
        query: storyContent,
        type: "fullText",
        limit: 3,
      });
      setRecommendedTags(data.tags);
    } catch (err) {
      console.error("Error fetching recommended tags:", err);
      setRecommendedTags([]); // Consider clearing existing recommendations on error
    } finally {
      setIsLoadingRecommendedTags(false);
    }
  };

  useEffect(() => {
    // Debounce to avoid too many calls during typing
    const handler = setTimeout(() => fetchRecommendedTags(story), 500);
    return () => clearTimeout(handler);
  }, [story]);

  const viewModeProps = {
    className,
    selectedTag,
    setShowDeleteConfirm,
    handleEdit,
    story,
    isEditMode,
    editModeDisplay,
  };

  const editModeProps = {
    selectedTag,
    tagError,
    isLoadingRecommendedTags,
    recommendedTags,
    story,
    storyError,
    handleSave,
    handleEdit,
    handleCancel,

    setSelectedTag,
    handleSetStory,
    className,
  };

  return (
    <>
      {isEditMode && editModeDisplay === "modal" && (
        <EditModal {...editModeProps} />
      )}

      <Card {...viewModeProps} editModeProps={editModeProps} />
      <DeleteConfirmationModal
        isOpen={showDeleteConfirm}
        onCancel={() => setShowDeleteConfirm(false)}
        onConfirm={() => {
          handleDelete(); // Assuming handleDelete takes care of deleting the LifeExperience
          setShowDeleteConfirm(false); // Hide the modal
        }}
      >
        {" "}
        <div className={cn(styles.container, styles.viewMode)}>
          {" "}
          <div className={cn(styles.tag, styles.selectedTag)}>
            {selectedTag?.name}
          </div>
          <div className={styles.story}>{story}</div>
        </div>
      </DeleteConfirmationModal>
    </>
  );
};

export default LifeExperience;
