import { fetchTagByHyphenatedName } from "api/tags";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./TagDetail.module.css";
import { IoArrowBackOutline } from "react-icons/io5";
import { fetchStoriesByTag } from "api/stories";
import { MdSearch } from "react-icons/md";
import cn from "classnames";
import SearchInput from "components/SearchInput/SearchInput";
import Button from "components/Button/Button";
import DOMPurify from "dompurify";

const StoryCard = ({ story }) => {
  console.log("story", story);
  const cleanHTML = DOMPurify.sanitize(story.body);
  const navigate = useNavigate();

  const handleCardClick = () => {
    // Navigate to the story detail page
    navigate(`/stories/${story._id}`);
  };

  return (
    <div className={styles.storyCard} onClick={handleCardClick}>
      <div className={styles.header}>
        <h2>{story.title}</h2>
        <div>{new Date(story.createdAt).toLocaleDateString()}</div>
      </div>
      <div className={styles.content}>
        <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />
      </div>
      <div className={styles.footer}>
        <span>Tag: {story.tag.name}</span>
        <span>Author: {story.author.username}</span>
        <span>Hugs: {story.hugs.length}</span>
      </div>
    </div>
  );
};

function TagDetail() {
  let { tagName } = useParams();

  const [tagDetails, setTagDetails] = useState(null);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);

  useEffect(() => {
    const fetchTagDetails = async () => {
      setIsLoadingDetails(true);
      try {
        const fetchedTag = await fetchTagByHyphenatedName(tagName);
        setTagDetails(fetchedTag);
      } catch (error) {
        console.error("Failed to fetch tag details:", error);
        // Handle error (e.g., show an error message)
      } finally {
        setIsLoadingDetails(false);
      }
    };

    fetchTagDetails();
  }, [tagName]); // Re-run the effect if the tagName changes

  const [stories, setStories] = useState([]);
  const [currentStoriesPage, setCurrentStoriesPage] = useState(1);

  const [totalStoriesPages, setTotalStoriesPages] = useState(0);

  useEffect(() => {
    const loadStories = async () => {
      if (tagDetails) {
        try {
          const { stories, totalPages } = await fetchStoriesByTag(
            tagDetails._id,
            currentStoriesPage,
            10
          ); // Assuming default limit is 10
          setStories(stories);
          setTotalStoriesPages(totalPages);
        } catch (error) {
          console.error("Failed to fetch stories:", error);
        }
      }
    };

    loadStories();
  }, [tagDetails, currentStoriesPage]);

  const handleStoriesPageChange = (newPage) => {
    setCurrentStoriesPage(newPage);
  };

  const [quotes, setQuotes] = useState([]);
  // Conditionally render content based on loading state and tag data
  const navigate = useNavigate();

  if (isLoadingDetails) {
    return (
      <div className={styles.container}>
        <div className={styles.loading}>
          <LoadingMessage message="Loading tag details..." />
        </div>
      </div>
    );
  }

  if (!isLoadingDetails && !tagDetails) {
    return (
      <div className={styles.container}>
        <div className={styles.loading}>
          <p>Tag not found.</p>
        </div>
      </div>
    );
  }

  // Format date
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className={styles.container}>
      <div className={styles.toolbarContainer}>
        <span
          onClick={() => {
            navigate("/tags");
          }}
          className={styles.backToTags}
        >
          <IoArrowBackOutline /> Back to tags
        </span>
      </div>
      <div className={styles.detailsContainer}>
        <div className={styles.infoBubble}>
          <h3 className={styles.tagName}>{tagDetails.name}</h3>
          {tagDetails.description && (
            <p className={styles.description}>{tagDetails.description}</p>
          )}
          {tagDetails.keywords && tagDetails.keywords.length > 0 && (
            <p className={styles.keywords}>
              Keywords:{" "}
              <span className={styles.keyword}>
                {tagDetails.keywords.join(", ")}
              </span>
            </p>
          )}
          <div className={styles.metaInfo}>
            {tagDetails.createdAt && (
              <p>Created on: {formatDate(tagDetails.createdAt)}</p>
            )}
            {typeof tagDetails.hits === "number" && (
              <p>Hits: {tagDetails.hits}</p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.storiesContainer}>
        <div className={styles.titleContainer}>
          <h2 className={styles.title}>Stories</h2>
          <Button
            shape="square"
            onClick={() => window.open(`/new-story?tag=grief`, "_blank")}
            className={styles.tellYourStoryButton}
            color="primary" // Adjust based on your color scheme
          >
            Write
          </Button>
        </div>
        <SearchInput placeholder="Search stories..." />
        <div className={styles.storyList}>
          {" "}
          {stories.map((story) => (
            <StoryCard key={story._id} story={story} />
          ))}
        </div>
        <div className={styles.pagination}>
          {Array.from({ length: totalStoriesPages }, (_, i) => (
            <button
              key={i}
              className={`${styles.pageButton} ${
                currentStoriesPage === i + 1 ? styles.active : ""
              }`}
              onClick={() => handleStoriesPageChange(i + 1)}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>
      <div className={styles.quotesContainer}>
        <h2 className={styles.title}>Quotes</h2>
        <div className={styles.body}></div>
      </div>
    </div>
  );
}

export default TagDetail;
