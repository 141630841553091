// packages/client/src/pages/Chat/Chat.jsx
import PageWrapper from "components/PageWrapper/PageWrapper";
import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Chat.module.css";
import { ChatContext } from "contexts/ChatContext";
import Button from "components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";

import useMediaQuery from "hooks/useMediaQuery";

import cn from "classnames";

import { HiOutlineInformationCircle } from "react-icons/hi";
import ChatMessage from "./ChatMessage";
import Modal from "components/Modal/Modal";
import { fetchChatData, fetchMessagesForChat } from "api/chats";
import { getUserDataById } from "api/users";
import { UserContext } from "contexts/UserContext";
import { ToastContext } from "contexts/ToastContext";

import { FaPhone } from "react-icons/fa6";
import { FaPhoneSlash } from "react-icons/fa6";
import { motion, AnimatePresence } from "framer-motion";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { isEmpty } from "lodash";
import formatGender from "utils/formatGender";
import ReviewModal from "components/ReviewModal/ReviewModal";
import { submitReview } from "api/reviews";

const UserProfile = ({ otherParticipant, matchedUserData }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (isEmpty(matchedUserData)) {
      // getUserDataById(otherParticipant._id).then((data) =>
      //   setMatchedUserData(data)
      // );
    } else {
      setLoading(false);
    }
  }, [matchedUserData, otherParticipant]);

  const renderProfile = () => {
    return (
      <>
        {" "}
        <h2 className={styles.username}>{matchedUserData?.username}</h2>
        <div className={cn(styles.section, styles.profile)}>
          <div className={styles.infoLine}>
            <div className={styles.label}>Age:</div>
            <div className={styles.data}>{matchedUserData?.age}</div>
          </div>
          <div className={styles.infoLine}>
            <div className={styles.label}>Gender:</div>
            <div className={styles.data}>
              {matchedUserData?.gender
                ? formatGender(matchedUserData?.gender)
                : "Not set"}
            </div>
          </div>
          <div className={styles.infoLine}>
            <div className={styles.label}>Location:</div>
            <div className={styles.data}>
              {matchedUserData?.location || "Not set"}
            </div>
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.lifeExperiences}>
            <h2>Life Experiences</h2>
            <ul>
              {matchedUserData?.lifeExperiences?.map((item) => (
                <li key={item._id} className={styles.lifeExperienceItem}>
                  <span className={styles.tagName}>{item.tag.name}</span>
                  <span className={styles.story}>{item.story}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </>
    );
  };
  return (
    <div className={styles.profileContainer}>
      {loading ? (
        <div style={{ padding: "10px 0" }}>
          <LoadingSpinner />
        </div>
      ) : (
        renderProfile()
      )}
    </div>
  );
};

const SideMenu = ({
  openProfileModal,
  isSideMenuVisible,
  openReviewModal,
  otherParticipant,
  chatData,
}) => {
  const renderSharedTags = () => {
    if (chatData && chatData.sharedTags && chatData.sharedTags.length > 0) {
      return (
        <div className={styles.sharedTags}>
          {chatData.sharedTags.map(({ tag, addedAt }, i) => (
            <div className={styles.sharedTag} key={i}>
              <div className={styles.tagName}>{tag.name}</div>
              <div className={styles.addedOn}>
                <p>added on {new Date(addedAt).toLocaleDateString()}</p>
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      return null;
    }
  };
  return (
    <div
      className={cn(styles.sideMenu, {
        [styles.sideMenuVisible]: isSideMenuVisible,
      })}
    >
      <ul className={styles.sideMenuList}>
        {" "}
        {otherParticipant && (
          <li className={styles.sideMenuItem}>
            <div className={styles.flexColCenter}>
              <p className={styles.sectionTitle}>You are chatting with: </p>
              <Button className={styles.userButton} onClick={openProfileModal}>
                {otherParticipant.username}
              </Button>
            </div>
          </li>
        )}
        <li className={cn(styles.sideMenuItem, styles.flexColCenter)}>
          <Button onClick={openReviewModal}>Review</Button>
        </li>
        {chatData && (
          <li className={styles.sideMenuItem}>
            <div className={styles.flexColCenter}>
              <p className={styles.sectionTitle}>
                You've shared the ff. tags:{" "}
              </p>

              {renderSharedTags()}
            </div>
          </li>
        )}
      </ul>
    </div>
  );
};

const Toolbar = ({
  handleInitiateCall,
  toggleSideMenu,

  otherParticipant,
  callStatus,
  openProfileModal,
  isMobileView,
  isSideMenuVisible,
}) => {
  return (
    <div className={styles.toolbar}>
      {!(isMobileView && isSideMenuVisible) &&
        (otherParticipant?.username ? (
          <div className={styles.username} onClick={openProfileModal}>
            {otherParticipant.username}
          </div>
        ) : (
          <LoadingSpinner />
        ))}
      <div className={styles.buttonMenu}>
        {callStatus === "idle" && (
          <div className={styles.button} onClick={handleInitiateCall}>
            <FaPhone style={{ height: "20px", width: "20px" }} />
          </div>
        )}

        <div className={styles.button} onClick={toggleSideMenu}>
          <HiOutlineInformationCircle />
        </div>
      </div>
    </div>
  );
};

const CallStatusBar = ({
  callStatus,
  otherParticipant,
  callChatId,
  chatId,
  handleAcceptCall,
  handleEndCall,
}) => {
  let message;

  switch (callStatus) {
    case "calling":
      message = `Calling ${otherParticipant.username}...`;
      break;
    case "receivingCall":
      message = `Incoming call from ${otherParticipant.username}...`;
      break;
    case "inCall":
      message = `In call with ${otherParticipant.username}`;
      break;
    case "callEnded":
      message = `Call ended`;
      break;
    case "idle":
    default:
      message = null; // No status bar for idle state
  }

  const { callDuration } = useContext(ChatContext);

  const variants = {
    enter: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5, ease: "easeOut" },
    },
    exit: {
      y: -100, // Adjust this value based on the actual size of your component or desired effect
      opacity: 0,
      transition: { duration: 0.5, ease: "easeIn" },
    },
  };

  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    // Convert minutes and seconds to strings and pad them with zeros if needed
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

    // Only include hours in the output if they are greater than zero
    const formattedHours =
      hours > 0 ? `${hours.toString().padStart(2, "0")}:` : "";

    return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
  };

  return (
    <AnimatePresence>
      {callChatId === chatId && (
        <motion.div
          key="callStatusBar"
          initial="exit"
          animate="enter"
          exit="exit"
          variants={variants}
          className={styles.callStatusBar}
        >
          <div className={styles.callStatus}>{message}</div>
          {callStatus !== "idle" && callStatus !== "receivingCall" && (
            <div className={styles.callDuration}>
              {formatDuration(callDuration)}
            </div>
          )}
          <div className={styles.buttonMenu}>
            {callStatus === "receivingCall" && (
              <div
                className={cn(styles.button, styles.acceptCallBtn)}
                onClick={handleAcceptCall}
              >
                <FaPhone style={{ height: "20px", width: "20px" }} />
                Accept
              </div>
            )}
            <div
              className={cn(styles.button, styles.endCallBtn)}
              onClick={handleEndCall}
            >
              <FaPhoneSlash style={{ height: "20px", width: "20px" }} />
              {callStatus === "receivingCall" ? "Reject" : "End"}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const Chat = () => {
  const { userData } = useContext(UserContext);
  const {
    sendMessage,
    localStream,
    remoteStream,
    initiateCall,
    callStatus,
    callChatId,
    endCall,
    acceptCall,
    cleanupChat,
    messagesByChatId,
    updateMessagesByChatId,
  } = useContext(ChatContext);

  const { chatId } = useParams();

  const navigate = useNavigate();
  const [newMessage, setNewMessage] = useState("");
  const isMobileView = useMediaQuery(`(max-width: 768px)`);
  const [isSideMenuVisible, setIsSideMenuVisible] = useState(false);
  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [chatData, setChatData] = useState();
  const [otherParticipant, setOtherParticipant] = useState();
  const [matchedUserData, setMatchedUserData] = useState({});
  const { addToast } = useContext(ToastContext);

  useEffect(() => {
    if (chatId) {
      setLoadingMessages(true);
      fetchMessagesForChat(chatId).then((messages) => {
        setLoadingMessages(false);
        updateMessagesByChatId(chatId, messages);
      });

      fetchChatData(chatId).then((data) => {
        setChatData(data);
      });
    }
  }, [chatId]);

  useEffect(() => {
    if (chatData) {
      const otherParticipant = chatData.participants.find(
        (participant) => participant._id !== userData._id
      );
      setOtherParticipant(otherParticipant);

      getUserDataById(otherParticipant._id).then((data) =>
        setMatchedUserData(data)
      );
    }
  }, [chatData, userData._id]);

  useEffect(() => {}, [matchedUserData]);

  useEffect(() => {
    if (messagesByChatId[chatId]) {
      setMessages(messagesByChatId[chatId]);
    }
  }, [messagesByChatId, chatId]);

  useEffect(() => {}, [messages]);

  useEffect(() => {
    return () => {
      cleanupChat();
    };
  }, []);

  useEffect(() => {}, [localStream, remoteStream]);

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      if (newMessage.trim()) {
        // Assuming `sendMessage` is a method from ChatContext for emitting the message
        sendMessage(chatId, newMessage);
        setNewMessage("");
      }
    }
  };

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      // Assuming `sendMessage` is a method from ChatContext for emitting the message
      sendMessage(chatId, newMessage);
      setNewMessage("");
    }
  };

  const toggleSideMenu = () => {
    setIsSideMenuVisible((prev) => !prev);
  };

  const handleLeaveChat = () => {
    navigate("/lobby");
  };

  const handleInitiateCall = async () => {
    try {
      await initiateCall(chatId);
    } catch (err) {
      addToast(err.message, "error");
    }
  };

  const handleAcceptCall = async () => {
    try {
      await acceptCall(chatId);
    } catch (err) {
      addToast(err.message, "error");
    }
  };

  const handleEndCall = async () => {
    try {
      await endCall(chatId);
    } catch (err) {
      addToast(err.message, "error");
    }
  };

  const audioRef = useRef();

  useEffect(() => {
    if (audioRef.current && remoteStream) {
      audioRef.current.srcObject = remoteStream;
    }
  }, [remoteStream]);

  const [isProfileModalVisible, setIsProfileModalVisible] = useState();

  const openProfileModal = () => {
    setIsProfileModalVisible(true);
  };

  const closeProfileModal = () => {
    setIsProfileModalVisible(false);
  };

  const renderMessages = () => {
    return (
      messages?.length > 0 &&
      messages.map((data, index) => <ChatMessage key={index} data={data} />)
    );
  };

  const [isReviewModalVisible, setIsReviewModalVisible] = useState();

  const openReviewModal = () => {
    setIsReviewModalVisible(true);
  };

  const closeReviewModal = () => {
    setIsReviewModalVisible(false);
  };

  const [reviewSubmitted, setReviewSubmitted] = useState(null);

  const handleSubmitReview = async ({ chat, rating, comments }) => {
    try {
      await submitReview({ chat, rating, comments });
      addToast(`Review successfully submitted`, "success");
      setReviewSubmitted(true);
    } catch (err) {
      addToast(err.message, "error");
    }
  };

  return (
    <PageWrapper ticker={false}>
      <div className={cn(styles.chat, {})}>
        <div className={styles.chatBox}>
          <SideMenu
            isSideMenuVisible={isSideMenuVisible}
            handleLeaveChat={handleLeaveChat}
            otherParticipant={otherParticipant}
            chatData={chatData}
            openProfileModal={openProfileModal}
            openReviewModal={openReviewModal}
            reviewSubmitted={reviewSubmitted}
          />
          <div
            className={cn(styles.chatContent, {
              [styles.chatContentContracted]: isSideMenuVisible,
            })}
          >
            <Toolbar
              toggleSideMenu={toggleSideMenu}
              otherParticipant={otherParticipant}
              handleInitiateCall={handleInitiateCall}
              callStatus={callStatus}
              openProfileModal={openProfileModal}
              isMobileView={isMobileView}
              isSideMenuVisible={isSideMenuVisible}
            />

            <CallStatusBar
              callStatus={callStatus}
              chatId={chatId}
              callChatId={callChatId}
              otherParticipant={otherParticipant}
              handleEndCall={handleEndCall}
              handleAcceptCall={handleAcceptCall}
            />

            <audio ref={audioRef} autoPlay playsInline />
            <div className={styles.messages}>
              {loadingMessages ? (
                <div style={{ padding: "10px 0" }}>
                  <LoadingSpinner />
                </div>
              ) : (
                renderMessages()
              )}
            </div>
            <div className={styles.input}>
              <textarea
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={onEnterPress}
              />
              <Button onClick={handleSendMessage}>Send</Button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isProfileModalVisible}
        onClose={closeProfileModal}
        className={styles.userModalStyles}
      >
        <UserProfile
          otherParticipant={otherParticipant}
          matchedUserData={matchedUserData}
          setMatchedUserData={setMatchedUserData}
        />
      </Modal>
      {chatData && matchedUserData && (
        <ReviewModal
          chat={chatData}
          reviewee={matchedUserData}
          isOpen={isReviewModalVisible}
          onSubmit={handleSubmitReview}
          onClose={closeReviewModal}
          reviewSubmitted={reviewSubmitted}
        />
      )}
    </PageWrapper>
  );
};

export default Chat;
