// packages/client/src/api/tags.js
import api from "api";
import handleError from "./handleError";

/**
 * Searches for tags based on various criteria.
 * @param {Object} params - The search parameters.
 * @param {string} params.query - The search query.
 * @param {string} [params.type="autocomplete"] - The type of search (e.g., "autocomplete" or "fullText").
 * @param {number} [params.limit=10] - The maximum number of results to return.
 * @param {string} [params.sort="relevance"] - The sort order of the results ("relevance" || "hits" || "new").
 * @param {number} [params.page=1] - The page number of the results to return.
 * @returns {Promise<Object>} A promise that resolves to an object containing the {tags, totalCount, totalPages}.
 * @throws Will throw an error if the request fails.
 */
export async function searchTags({
  query = "",
  type = "autocomplete",
  limit = 20,
  sort = "relevance",
  page = 1,
}) {
  console.log("searchTags", { query, type, limit, sort, page });
  try {
    const res = await api.get("/tags", {
      params: { query, type, sort, limit, page },
    });

    return res.data; // Expected to return an object with { tags: Array, totalCount: Number, totalPages: Number }
  } catch (error) {
    handleError(error);
  }
}

// Add a new function to fetch a tag by its hyphenated name
export const fetchTagByHyphenatedName = async (hyphenatedName) => {
  try {
    const res = await api.get(`/tags/name/${hyphenatedName}`);
    return res.data;
  } catch (error) {
    handleError(error);
  }
};
