import React, { useEffect, useContext, useState } from "react";
import { LobbyContext } from "contexts/LobbyContext";
import cn from "classnames";
import styles from "../Lobby.module.css";

import Button from "components/Button/Button";
import Tag from "components/Tag/Tag";
import QuoteCarousel from "components/QuoteCarousel/QuoteCarousel";
import { ChatContext } from "contexts/ChatContext";
import { ToastContext } from "contexts/ToastContext";
import { useNavigate } from "react-router-dom";

const WaitingRoom = ({ step, setStep }) => {
  const active = step === 2;
  const {
    isSearching,
    startSearch,
    stopSearch,

    cleanupLocalStream,
  } = useContext(ChatContext);
  const { addToast } = useContext(ToastContext);
  const { story, selectedTags } = useContext(LobbyContext);

  useEffect(() => {
    // if (active) initStream();
  }, [step]);

  const handleBack = () => {
    stopSearch();
    cleanupLocalStream();
    setStep(1);
  };

  const handleSearch = () => {
    try {
      startSearch(selectedTags, story);
    } catch (error) {
      console.error(error.message); // Handle the error here
      addToast(error.message, "error");
      setStep(1);
    }
  };

  return (
    <div
      className={cn(styles.waitingRoom, {
        [styles.active]: active,
        [styles.hidden]: !active,
      })}
    >
      <div className={styles.body}>
        <div className={styles.inputBox}>
          <div className={styles.story}>
            {story.length > 0 ? (
              <p>{story}</p>
            ) : (
              <p className={styles.noStory}>No story provided</p>
            )}
          </div>
          <div className={styles.tags}>
            {selectedTags.length > 0 ? (
              selectedTags.map((tag, i) => (
                <Tag key={i} tag={tag} viewOnly selected />
              ))
            ) : (
              <p className={styles.noTags}>No tags selected</p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.btn}>
        <Button onClick={handleBack}>
          <p>Back</p>
        </Button>
        {!isSearching ? (
          <Button onClick={handleSearch}>
            <p>Start Search</p>
          </Button>
        ) : (
          <Button className={styles.stopSearch} onClick={handleSearch}>
            <p>Stop Search</p>
          </Button>
        )}
      </div>
      <div className={styles.quotes}>
        <QuoteCarousel tags={selectedTags} />
      </div>
    </div>
  );
};

export default WaitingRoom;
