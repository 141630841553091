import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import cn from "classnames";
import styles from "./ScrollWrapper.module.css";

const ScrollWrapper = ({ children }) => {
  const wrapperRef = useRef(null);
  const [atTop, setAtTop] = useState(true);
  const [atBottom, setAtBottom] = useState(true);
  const [isScrollbarPresent, setIsScrollbarPresent] = useState(false);

  useEffect(() => {
    // Using a timeout to give the browser time to render and update layout measurements.
    // This is a workaround because we don't have a direct event to tell us when the DOM has finished painting.
    // The chosen delay is somewhat arbitrary and may not be sufficient in all cases or environments.
    const timeoutId = setTimeout(() => {
      if (wrapperRef.current) {
        // scrollHeight is the height of the content, including content not visible on the screen due to overflow.
        const isContentOverflowing =
          wrapperRef.current.scrollHeight > wrapperRef.current.clientHeight;

        // Log the measurements for debugging purposes.

        // Update state based on whether the content is overflowing.
        // !isContentOverflowing will be true if the content fits within the container without overflow,
        // meaning 'atBottom' should be true to indicate no need for scrolling.
        setAtBottom(!isContentOverflowing);
      }
    }, 200);

    const checkOverflow = () => {
      if (wrapperRef.current) {
        const { scrollHeight, clientHeight } = wrapperRef.current;
        const isContentOverflowing = scrollHeight > clientHeight;
        setIsScrollbarPresent(isContentOverflowing);
        setAtBottom(
          scrollHeight <= clientHeight ||
            scrollHeight - wrapperRef.current.scrollTop <= clientHeight + 1
        );
        setAtTop(wrapperRef.current.scrollTop === 0);
      }
    };

    // Initial check
    checkOverflow();

    // Set up resize event listener to handle dynamic window resizing
    window.addEventListener("resize", checkOverflow);

    // Clear the timeout when the effect cleans up to prevent memory leaks or state updates
    // on unmounted components if the component unmounts within the delay period.
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("resize", checkOverflow);
    };

    // Dependency array with tags to ensure this effect runs when the tags change,
    // which could affect the layout and require a recalculation of overflow.
  }, []);

  const handleScroll = () => {
    if (wrapperRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = wrapperRef.current;
      const isAtTop = scrollTop === 0;
      const isAtBottom = scrollHeight - scrollTop <= clientHeight + 1; // +1 is necessary for a buffer (sometimes it doesn't exactly line up with decimals)

      setAtTop(isAtTop);
      setAtBottom(isAtBottom);
    }
  };

  return (
    <div className={styles.container}>
      <motion.div
        className={styles.scrollIndicator}
        initial={{ opacity: 0, minHeight: "20px" }}
        animate={{
          opacity: atTop ? 0 : 1,
          minHeight: atTop ? "0" : "20px",
          height: atTop ? 0 : "20px",
        }}
        transition={{ duration: 0.5 }}
      >
        <span className={styles.dot}></span>
        <span className={styles.dot}></span>
        <span className={styles.dot}></span>
      </motion.div>
      <div
        className={cn(styles.wrapper, {
          [styles.maskTop]: !atTop,
          [styles.maskBottom]: !atBottom,
          [styles.maskBoth]: !atTop && !atBottom,
        })}
        ref={wrapperRef}
        onScroll={handleScroll}
      >
        {children}
      </div>
      <motion.div
        className={styles.scrollIndicator}
        initial={{ opacity: 0, minHeight: "20px" }}
        animate={{
          opacity: atBottom ? 0 : 1,
          minHeight: atBottom ? "0" : "20px",
          height: atBottom ? 0 : "20px",
        }}
        transition={{ duration: 0.5 }}
      >
        <span className={styles.dot}></span>
        <span className={styles.dot}></span>
        <span className={styles.dot}></span>
      </motion.div>
    </div>
  );
};

export default ScrollWrapper;
