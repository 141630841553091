import React, { useContext, useEffect, useState } from "react";

import self from "./ProfileContent.module.css";
import { UserContext } from "contexts/UserContext"; // Import your UserContext

import Button from "components/Button/Button";

import Tooltip from "components/Tooltip/Tooltip";
import TooltipHeader from "components/Tooltip/TooltipHeader";
import TooltipBody from "components/Tooltip/TooltipBody";
import classNames from "classnames";

import * as USERS_API from "api/users";
import InputWrapper from "components/InputWrapper/InputWrapper";
import validator from "validator";

import { ToastContext } from "contexts/ToastContext";

const UsernameInput = ({
  username,
  setUsername,
  originalUsername,
  addToast,
}) => {
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(null);
  const [message, setMessage] = useState("");
  const [isValid, setIsValid] = useState(true); // Initialize as true assuming username is initially valid

  // Utility to check if a string is alphanumeric with underscore allowed
  const isAlphanumeric = (value) =>
    validator.isAlphanumeric(value, "en-US", { ignore: "_" });

  useEffect(() => {
    // Reset states when username matches the original
    if (username === originalUsername) {
      setIsUsernameAvailable(true);
      setMessage("");
      setIsValid(true);
      return;
    }

    if (username.length > 0) {
      if (isAlphanumeric(username)) {
        // Debounce the API call for efficiency
        const timer = setTimeout(
          () => checkUsernameAvailability(username),
          300
        );
        return () => clearTimeout(timer);
      } else {
        setMessage("Username must be alphanumeric.");
        setIsUsernameAvailable(null); // Reset availability state
        setIsValid(false);
      }
    } else {
      setMessage("");
      setIsUsernameAvailable(null); // Reset availability state
      setIsValid(false);
    }
  }, [username, originalUsername]);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value.trim());
  };

  const checkUsernameAvailability = async (username) => {
    if (username === originalUsername) {
      // Skip check if username is unchanged
      return;
    }

    try {
      addToast("Checking username availability...", "info");
      const data = await USERS_API.checkAvailability(username);

      setIsUsernameAvailable(data.available);
      setMessage(data.message);

      if (data.available) {
        addToast("Username is available!", "success");
      } else {
        addToast(data.message, "error");
      }
    } catch (error) {
      console.error("Failed to check username availability", error);
      addToast("Failed to check username availability", "error");
    }
  };

  useEffect(() => {
    // Update validity based on current state
    setIsValid(
      username.length > 0 && isUsernameAvailable && isAlphanumeric(username)
    );
  }, [username, isUsernameAvailable, originalUsername]);

  return (
    <div className={self.usernameInput}>
      <InputWrapper valid={isValid} helperText={message}>
        <input
          className={self.usernameInput}
          autoFocus
          required
          type="text"
          placeholder={username}
          value={username}
          onChange={handleUsernameChange}
        />
      </InputWrapper>
    </div>
  );
};

const ProfileInfo = ({ userData, addToast }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [username, setUsername] = useState(userData.username);
  const { setUserData } = useContext(UserContext);

  // Format birthdate for display
  const formattedBirthdate = userData.birthdate
    ? new Date(userData.birthdate).toLocaleDateString()
    : "Not provided";

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const handleCancel = () => {
    setIsEditMode(false);
  };

  const handleSave = async () => {
    try {
      const { user } = await USERS_API.updateUserData({ username });
      setUserData(user);
      addToast("User info updated", "success");
      setIsEditMode(false);
    } catch (error) {
      addToast(error.message, "error");
    }
  };

  return (
    <div className={classNames(self.section, self.ricePaper)}>
      <div className={self.header}>
        <p>Profile Information</p>
        <div className={self.actionMenu}>
          {isEditMode ? (
            <>
              <Button onClick={handleSave}>Save</Button>
              <Button color="error" onClick={handleCancel}>
                Cancel
              </Button>
            </>
          ) : (
            <Button className={self.button} onClick={handleEdit}>
              Edit
            </Button>
          )}
        </div>
      </div>
      <div className={self.body}>
        {isEditMode ? (
          <UsernameInput
            username={username}
            setUsername={setUsername}
            originalUsername={userData.username}
            addToast={addToast}
          />
        ) : (
          <div className={self.infoLine}>
            <div className={self.label}>Username</div>
            <div className={self.data}>{userData.username || "Not set"}</div>
            <div className={self.tooltip}>
              <Tooltip>
                <TooltipHeader>test</TooltipHeader>
                <TooltipBody>test</TooltipBody>
              </Tooltip>
            </div>
          </div>
        )}
        <div className={self.infoLine}>
          <div className={self.label}>Email</div>
          <div className={self.data}>{userData.email || "Not set"}</div>
          <div className={self.tooltip}>
            <Tooltip>
              <TooltipHeader>test</TooltipHeader>
              <TooltipBody>test</TooltipBody>
            </Tooltip>
          </div>
        </div>
        <div className={self.infoLine}>
          <div className={self.label}>Location</div>
          <div className={self.data}>{userData.location || "Not set"}</div>
          <div className={self.tooltip}>
            <Tooltip>
              <TooltipHeader>test</TooltipHeader>
              <TooltipBody>test</TooltipBody>
            </Tooltip>
          </div>
        </div>
        <div className={self.infoLine}>
          <div className={self.label}>Gender</div>
          <div className={self.data}>{userData.gender || "Not set"}</div>
        </div>
        <div className={self.infoLine}>
          <div className={self.label}>Birthdate</div>
          <div className={self.data}>{formattedBirthdate || "Not set"}</div>
        </div>
      </div>
    </div>
  );
};

const ProfileContent = () => {
  const { toastList, addToast, removeToast } = useContext(ToastContext);
  const { userData } = useContext(UserContext); // Use UserContext to get user data

  return (
    <div className={self.profileContent}>
      <ProfileInfo userData={userData} addToast={addToast} />
      {/* <LifeExperiences userData={userData} addToast={addToast} /> */}
    </div>
  );
};

export default ProfileContent;
