// packages/client/src/pages/Lobby/TagSelect.jsx
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./TagSelect.module.css";
import cn from "classnames";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import useTagsSearch from "hooks/useTagsSearch";

const TagOption = forwardRef(
  (
    {
      tag,
      i,
      selectTag,
      setIsOpen,
      setHighlightedIndex,
      isTagSelected,
      highlightedIndex,
    },
    ref
  ) => {
    return (
      <li
        ref={ref}
        key={tag._id}
        onClick={(e) => {
          e.stopPropagation();
          selectTag(tag);
          setIsOpen(false);
        }}
        onMouseEnter={(e) => {
          setHighlightedIndex(i);
        }}
        onMouseDown={(e) => e.preventDefault()}
        className={cn(styles.tag, {
          [styles.selected]: isTagSelected(tag),
          [styles.highlighted]: i === highlightedIndex,
        })}
      >
        <div className={styles["tag-header"]}>
          <div className={styles["tag-name"]}>{tag.name}</div>
          <div className={styles["tag-hits"]}>{tag.hits}</div>
        </div>
        <div className={styles["tag-description"]}>{tag.description}</div>
      </li>
    );
  }
);

const TagSelect = forwardRef(({ selected, onSelect }, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  const [query, setQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);

  const { tags, loading, error, hasMore } = useTagsSearch(query, pageNumber);

  const [highlightedIndex, setHighlightedIndex] = useState(null);
  const inputRef = useRef(null);

  const [isInputFocused, setIsInputFocused] = useState(false);

  const mirrorRef = useRef(null);
  const adjustWidth = () => {
    const mirrorText = mirrorRef.current;
    const input = inputRef.current;

    if (mirrorText && input) {
      mirrorText.textContent = query || input.placeholder || " "; // Fallback to placeholder or a single space
      input.style.width = `${mirrorText.offsetWidth + 10}px`; // +10 for a little extra space
    }
  };
  useEffect(() => {
    adjustWidth();
  }, [query, selected]);

  function handleChange(e) {
    setQuery(e.target.value);
  }

  function clearOptions() {
    onSelect();
    setQuery("");
  }

  function selectTag(tag) {
    onSelect(tag);
    setQuery("");
  }

  function isTagSelected(tag) {
    return selected.some((t) => t._id === tag._id);
  }

  useEffect(() => {
    setHighlightedIndex(0);
  }, [isOpen]);

  useEffect(() => {
    if (isInputFocused) {
      setIsOpen(true);
    }
  }, [isInputFocused]);

  useEffect(() => {
    if (query?.length > 0) {
      setIsOpen(true);
    }
  }, [query]);

  const observer = useRef();

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const tagOptionProps = {
    selectTag,
    setIsOpen,
    setHighlightedIndex,
    isTagSelected,
    highlightedIndex,
  };

  return (
    <div
      data-tour="tag-select"
      ref={ref}
      tabIndex={0}
      onFocus={() => {
        inputRef.current.focus();
      }}
      onClick={() => {
        inputRef.current.focus();
      }}
      onBlur={() => {
        setIsOpen(false);
      }}
      className={cn(styles.container, {
        [styles.containerFocused]: isInputFocused,
      })}
    >
      <span className={styles.selected}>
        {selected.map((v) => (
          <button
            key={v._id}
            onClick={(e) => {
              e.stopPropagation();
              selectTag(v);
            }}
            className={styles["option-badge"]}
          >
            {v.name}
            <span className={styles["remove-btn"]}>&times;</span>
          </button>
        ))}
        <input
          ref={inputRef}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setIsInputFocused(false)}
          className={styles.input}
          value={query}
          onChange={handleChange}
          placeholder={
            selected.length > 0
              ? "Select another tag (optional)"
              : "Select a tag"
          }
        />
        <span ref={mirrorRef} className={styles["mirror-text"]}></span>
      </span>
      <button
        className={styles["clear-btn"]}
        onClick={(e) => {
          e.stopPropagation();
          clearOptions();
        }}
      >
        &times;
      </button>
      <div className={styles.divider}></div>
      <div className={styles.caret}></div>
      <ul className={cn(styles.tags, { [styles.show]: isOpen })}>
        {tags.map((tag, i) => {
          if (tags.length === i + 1) {
            return (
              <TagOption
                ref={lastElementRef}
                key={tag._id}
                tag={tag}
                i={i}
                {...tagOptionProps}
              />
            );
          } else {
            return (
              <TagOption tag={tag} key={tag._id} i={i} {...tagOptionProps} />
            );
          }
        })}
        {loading && (
          <li className={styles.loading}>
            <LoadingMessage message="Loading more tags..." />
          </li>
        )}
      </ul>
    </div>
  );
});

export default TagSelect;
