// packages/client/src/pages/ProtectedRoutes/ProtectedRoutes.jsx
import React, { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { UserContext } from "contexts/UserContext";
import { ChatContext } from "contexts/ChatContext";
import LoadingPage from "./LoadingPage/LoadingPage";
import AuthOverlay from "./AuthOverlay/AuthOverlay";

const ProtectedRoutes = (props) => {
  // const { redirectTo, loggedInOnly, chatIdOnly, matchedUserOnly, showOverlay } =
  //   props;
  const { redirectTo, loggedInOnly, showOverlay, registeredOnly } = props;
  const { userData, loading } = useContext(UserContext);

  const isAuthenticated = !!userData; // Ensure userData existence is converted to boolean
  const isRegistered = userData && userData.isRegistered; // Safely access isRegistered

  if (loading) {
    return <LoadingPage />;
  } else {
    if (loggedInOnly && !isAuthenticated) {
      // User is not authenticated but needs to be
      return showOverlay ? (
        <AuthOverlay>
          <Outlet />
        </AuthOverlay>
      ) : (
        <Navigate to={redirectTo} />
      );
    } else if (registeredOnly && !isRegistered) {
      // User is not registered but needs to be
      return <Navigate to={"/register"} />;
    } else {
      // User meets all requirements
      return <Outlet />;
    }
  }
};

export default ProtectedRoutes;
