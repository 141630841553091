import React, { useState } from "react";
import cn from "classnames";
import styles from "./Quote.module.css";

function Quote({ quote, className }) {
  const { text, author } = quote;

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={cn(className, styles.quote)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={`${styles.text} ${isHovered && styles.hovered}`}>
        <p>"{text}"</p>
      </div>
      <div className={`${styles.author} ${isHovered && styles.hovered}`}>
        <p>{author}</p>
      </div>
    </div>
  );
}

export default Quote;
