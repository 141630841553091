// packages/client/src/hooks/useMediaQuery.js
import { useState, useEffect } from "react";

/**
 * Custom hook to track media query matches.
 *
 * @param {string} query - Media query string.
 * @returns {boolean} - Whether the media query matches.
 */
const useMediaQuery = (query) => {
  // Initialize state with the current match status.
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    // Create a MediaQueryList object.
    const mediaQueryList = window.matchMedia(query);

    // Define a handler that updates the state.
    const handleMediaQueryChange = (event) => {
      setMatches(event.matches);
    };

    // Add event listener for changes.
    mediaQueryList.addEventListener("change", handleMediaQueryChange);

    // Set the initial matches state based on the current media query status.
    setMatches(mediaQueryList.matches);

    // Clean up: remove event listener.
    return () => {
      mediaQueryList.removeEventListener("change", handleMediaQueryChange);
    };
  }, [query]);

  return matches;
};

export default useMediaQuery;
