// packages/client/src/components/LifeExperience/StoryEditor.module.css
import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";

import styles from "./StoryEditor.module.css";

import cn from "classnames";

const StoryEditor = forwardRef(
  ({ maxWords: maxWordsInit, story, setStory, className }, ref) => {
    const textAreaRef = useRef(null);
    const [isWordLimitExceeded, setIsWordLimitExceeded] = useState(false); // New state for word limit warning
    const maxWords = maxWordsInit || 25;
    const MAX_HEIGHT = 150;

    // Expose specific properties and methods to parent component
    useImperativeHandle(ref, () => textAreaRef.current);

    const handleInput = (event) => {
      const textArea = event.target;
      textArea.style.height = "auto"; // Reset the height
      textArea.style.height = `${Math.min(
        textArea.scrollHeight,
        MAX_HEIGHT
      )}px`; // Set to scrollHeight or MAX_HEIGHT
    };

    const handleEssayChange = (event) => {
      const text = event.target.value;
      const words = text.split(/\s+/);
      setStory(text); // Always update the story
      setIsWordLimitExceeded(words.length > maxWords); // Update the warning state based on word count
    };

    const clearInput = () => {
      setStory("");
      if (textAreaRef.current) {
        textAreaRef.current.style.height = "auto"; // Reset the height
      }
    };

    const wordCount = story?.trim() ? story?.split(/\s+/).length : 0;

    return (
      <div className={cn(styles.story, className)}>
        <div className={styles.textAreaContainer}>
          <textarea
            ref={textAreaRef}
            data-tour="story-editor"
            className={`${styles.essayTextArea} ${
              isWordLimitExceeded ? styles.textAreaError : ""
            }`}
            value={story}
            onChange={handleEssayChange}
            onInput={handleInput}
            placeholder="Write your story here..."
            rows={3}
            maxLength={1000}
          />
          {story && (
            <button
              className={styles["clear-btn"]}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                clearInput();
              }}
            >
              &times;
            </button>
          )}
        </div>

        <div className={styles.infoBar}>
          {isWordLimitExceeded && (
            <p className={cn(styles.helper, styles.error)}>
              Fyi – the shorter the better
            </p> // Display warning message
          )}
          <p
            className={`${styles.wordCount} ${
              isWordLimitExceeded ? styles.error : ""
            }`}
          >
            Word count: {wordCount} / {maxWords}
          </p>
        </div>
      </div>
    );
  }
);

export default StoryEditor;
