// packages/client/src/components/QuoteCarousel/QuoteCarousel.jsx
import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import cn from "classnames";
import { getQuotesByTags } from "api/quotes";
import styles from "./QuoteCarousel.module.css";
import Quote from "components/Quote/Quote";
import { TbRefresh } from "react-icons/tb";

const QuoteCarousel = ({ tags }) => {
  const [quotes, setQuotes] = useState([]);
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(null);
  const [isSpinning, setIsSpinning] = useState(false);

  useEffect(() => {
    if (tags.length > 0) {
      getQuotesByTags(tags)
        .then((fetchedQuotes) => {
          setQuotes(fetchedQuotes);
          setCurrentQuoteIndex(
            Math.floor(Math.random() * fetchedQuotes.length)
          );
        })
        .catch((error) => {
          console.error("Failed to fetch quotes:", error);
          // Handle error appropriately
        });
    } else {
      setQuotes([]); // Clear quotes if there are no selected tags
    }
  }, [tags]); // Dependency array includes tags

  const handleNextQuote = () => {
    // Start spinning
    setIsSpinning(true);

    if (quotes.length > 1) {
      let nextIndex;
      do {
        nextIndex = Math.floor(Math.random() * quotes.length);
      } while (nextIndex === currentQuoteIndex);

      setCurrentQuoteIndex(nextIndex);
    }

    // Stop spinning after 1 second (or the duration of your animation)
    setTimeout(() => setIsSpinning(false), 1000);
  };

  // Animation variants for fade-in and fade-out
  const quoteVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <div className={styles.container}>
      {quotes.length > 0 && (
        <div className={styles.flexbox}>
          <AnimatePresence mode="wait">
            <motion.div
              className={styles.quote}
              variants={quoteVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.5 }} // Set the duration as needed
              key={currentQuoteIndex}
            >
              <Quote quote={quotes[currentQuoteIndex]} />
            </motion.div>
          </AnimatePresence>
          <TbRefresh
            onClick={handleNextQuote}
            className={cn(styles.refreshBtn, { [styles.spin]: isSpinning })}
          />
        </div>
      )}
    </div>
  );
};

export default QuoteCarousel;
