import { useCallback, useEffect, useState } from "react";
import * as TAGS_API from "../api/tags";
import _ from "lodash";

const useTagsSearch = (query, pageNumber, limit = 20) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [tags, setTags] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setTags([]);
  }, [query]);

  const fetchTags = useCallback(async (query = "", limit = 20, page = 1) => {
    console.log("fetchTags", { query, page });
    try {
      setLoading(true); // Start loading

      const data = await TAGS_API.searchTags({
        query,
        type: "autocomplete",
        limit,
        page,
      });

      setHasMore(data.tags.length > 0);
      setTags((prevTags) => {
        return [...new Set([...prevTags, ...data.tags])];
      });
      setLoading(false);
    } catch (err) {
      console.error("Error fetching  tags:", err);
      setLoading(false); // Stop loading if there is an error
    }
  }, []);

  // Debounce the fetchRecommendedTags function
  const debouncedFetchTags = useCallback(_.debounce(fetchTags, 500), []);

  useEffect(() => {
    console.log("useEffect");
    debouncedFetchTags(query, limit, pageNumber);
  }, [query, limit, pageNumber]);

  return {
    tags,
    fetchTags: debouncedFetchTags,
    loading,
    error,
    hasMore,
  };
};

export default useTagsSearch;
