import React from "react";
import styles from "./NotFoundPage.module.css"; // Import the CSS module

const NotFoundPage = () => {
  return (
    <div className={styles.notFoundContainer}>
      <h1 className={styles.notFoundTitle}>404 Not Found</h1>
      <p className={styles.notFoundText}>
        The page you're looking for doesn't exist.
      </p>
    </div>
  );
};

export default NotFoundPage;
