import handleError from "./handleError";
import api from "./index";

export const submitReview = async ({ chat, rating, comments }) => {
  try {
    const res = await api.post("/reviews", { chat, rating, comments });

    return res.data;
  } catch (err) {
    handleError(err);
  }
};
