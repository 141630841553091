// packages/client/src/components/JoyrideFab/JoyrideFab.jsx
import React from "react";
import styles from "./JoyrideFab.module.css"; // Assume basic styling for the FAB

const JoyrideFab = ({ onClick }) => {
  return (
    <button className={styles.fab} onClick={onClick}>
      ?
    </button>
  );
};

export default JoyrideFab;
