// packages/client/src/contexts/ChatContext.js
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import socket from "socket";
import {
  initializePeerConnection,
  getPeerConnection,
  closePeerConnection,
} from "webrtc";

import ChatMessage from "classes/ChatMessage";
import { addIceCandidate } from "webrtc";
import { processIceCandidateQueue } from "webrtc";
import { getUserDataById } from "api/users";
import { createReview } from "api/reviews";
import { UserContext } from "./UserContext";
import { ToastContext } from "./ToastContext";
import { SoundContext } from "./SoundContext";

const ChatContext = createContext();

socket.on("connect", () => {});

const ChatContextProvider = ({ children }) => {
  const { userData } = useContext(UserContext);
  const { addToast } = useContext(ToastContext);
  const { callingSound, receivingCallSound, messageNotifSound } =
    useContext(SoundContext);
  const navigate = useNavigate();

  const [chats, setChats] = useState({});
  const [isSearching, setIsSearching] = useState(false);
  const [matchAccepted, setMatchAccepted] = useState(null);
  const [matchedUserResponse, setMatchedUserResponse] = useState(null);
  const [callStatus, setCallStatusRaw] = useState("idle"); // calling, receivingCall, inCall

  const callStatusRef = useRef(callStatus);

  const setCallStatus = (newStatus) => {
    const validStatuses = [
      "idle",
      "calling",
      "receivingCall",
      "inCall",
      "callEnded",
    ];
    if (validStatuses.includes(newStatus)) {
      setCallStatusRaw(newStatus);
      callStatusRef.current = newStatus;
    } else {
      console.error(`Invalid call status: ${newStatus}`);
      // Optionally, throw an error or handle this case as needed
    }
  };

  // To make sure that they're always the same
  useEffect(() => {
    callStatusRef.current = callStatus;
  }, [callStatus]);

  const [callChatId, setCallChatId] = useState(null);

  const [callDuration, setCallDuration] = useState(null);

  const [receivedRTCOffer, setReceivedRTCOffer] = useState({
    offer: null,
    chatId: null,
    from: null,
  });

  const resetReceivedRTCOffer = () => {
    setReceivedRTCOffer({
      offer: null,
      chatId: null,
      from: null,
    });
  };

  const [receivedRTCAnswer, setReceivedRTCAnswer] = useState({
    answer: null,
    chatId: null,
    from: null,
  });

  const resetReceivedRTCAnswer = () => {
    setReceivedRTCAnswer({
      answer: null,
      chatId: null,
      from: null,
    });
  };

  const [messagesByChatId, setMessagesByChatId] = useState({});
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [connectionState, setConnectionState] = useState(null);

  // State I'm not sure I still need to track in v2
  const [matchedUser, setMatchedUser] = useState(null);
  const [matchedUserData, setMatchedUserData] = useState(null);
  const [chatId, setChatId] = useState(null);
  const [chatData, setChatData] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    // workaround -> add the listener only once userId has been updated
    if (userData?._id) {
      socket.on("newMessage", (data) => {
        const { chatId } = data;
        const newMessage = new ChatMessage(data, userData?._id);
        setMessagesByChatId((prevMessagesByChatId) => {
          const history = prevMessagesByChatId[chatId] || [];

          return {
            ...prevMessagesByChatId,
            [chatId]: [...history, newMessage],
          };
        });
      });
    }

    return () => {
      socket.off("newMessage");
    };
  }, [userData?._id]);

  useEffect(() => {
    setRemoteStream(new MediaStream());

    socket.on("matchFound", (matchedUser) => {
      setMatchedUser(matchedUser);
      setIsSearching(false); // Assuming the search ends when a match is found
    });

    socket.on("pendingAcceptance", () => {
      setMatchedUserResponse("pending");
    });

    socket.on("matchedUserAccepted", (matchedUserData) => {
      setMatchedUser(matchedUserData);
      setMatchedUserResponse("accepted");
    });

    socket.on("matchedUserRejected", () => {
      setMatchedUser(null);
      setMatchedUserResponse("rejected");
      setIsSearching(true);
      // Trigger a UI popup/modal for match rejected
      setError("rejected");
    });

    socket.on("joinChat", (chatId) => {
      setChatId(chatId);
      navigate(`/chat/${chatId}`);
    });

    socket.on("matchDisconnected", () => {
      handleRemoteUserDisconnection();
      setMatchedUser(null);
      setIsSearching(true);
      // Trigger a UI popup/modal for match disconnected
      setError("disconnected");
    });

    socket.on("userChatsData", (userChats) => {
      setChats(userChats);
    });

    return () => {
      socket.off("matchFound");
      socket.off("pendingAcceptance");
      socket.off("matchedUserAccepted");
      socket.off("matchedUserRejected");
      socket.off("joinChat");
      socket.off("userChatsData");
      socket.off("matchDisconnected");
    };
  }, []);

  // Function to initialize local media stream
  async function initStream(options = { audio: true, video: false }) {
    try {
      // Check if there's already a local stream
      if (localStream) {
        return localStream;
      }

      const { video, audio } = options;
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video,
        audio,
      });
      setLocalStream(mediaStream);

      return mediaStream;
    } catch (error) {
      console.error("Error accessing media devices.", error);
      throw new Error(error);
    }
  }

  function cleanupLocalStream() {
    if (localStream) {
      localStream.getTracks().forEach((track) => track.stop());
      setLocalStream(null);
    } else {
    }
  }

  function cleanupRemoteStream() {
    if (remoteStream) {
      remoteStream.getTracks().forEach((track) => track.stop());
      setRemoteStream(null);
    } else {
    }
  }

  // ! WebRTC functions
  // ! Start a call
  const initiateCall = async (chatId) => {
    let pc = initializePeerConnection();

    // If there is localStream, add it to the WebRTC connection
    if (localStream) {
      localStream.getTracks().forEach((track) => {
        pc.addTrack(track, localStream);
      });
    } else {
      try {
        const stream = await initStream({ audio: true, video: false });
        stream.getTracks().forEach((track) => {
          pc.addTrack(track, stream);
        });
      } catch (err) {
        console.error(err);
      }
    }

    // Set up a listener to monitor the WebRTC's connection state and add it to context state
    pc.onconnectionstatechange = (event) => {
      setConnectionState(pc.connectionState);
    };

    // Set up a listener to monitor for new tracks from the remote peer
    // Triggered when receiving a track from the remote peer
    pc.ontrack = (event) => {
      const newRemoteStream = new MediaStream();
      event.streams[0].getTracks().forEach((track) => {
        newRemoteStream.addTrack(track);
      });
      setRemoteStream(newRemoteStream);
    };

    if (!receivedRTCOffer.offer) {
      // If a WebRTC offer hasn't been received yet, then create one (means you're the initiating client)

      await createOffer(chatId);
      setCallStatus("calling");
      setCallChatId(chatId);
      // Set a timeout to end the call if not connected within a specified time
    }
  };

  useEffect(() => {}, [callChatId]);

  // ! createOffer function
  const createOffer = async (chatId) => {
    let pc = getPeerConnection();

    //Event that fires off when a new offer ICE candidate is created
    pc.onicecandidate = (event) => {
      if (event.candidate) {
        socket.emit("sendIceCandidate", {
          candidate: event.candidate,
          chatId,
        });
      }
    };

    const offer = await pc.createOffer();

    await pc.setLocalDescription(offer); // This triggers pc.onicecandidate

    socket.emit("requestCall", {
      offer: pc.localDescription,
      chatId,
    });
  };

  // ! Initialize WebRTC listener for requestCall
  useEffect(() => {
    socket.on("requestCall", async ({ offer, chatId, from }) => {
      addToast(`${from.username} is calling...`, "orange");
      if (callStatusRef.current === "idle") {
        // Store first (only send answer when accepted)
        setCallStatus("receivingCall");
        setCallChatId(chatId);
        setReceivedRTCOffer({ offer, chatId, from });
      } else {
        socket.emit("isBusy", { chatId });
      }
    });

    socket.on("isBusy", async ({ chatId, from }) => {
      addToast(`${from.username} is currently busy.`);
      setCallStatus("callEnded");
      setCallChatId(null);
    });

    socket.on("callAccepted", async ({ answer, chatId, from }) => {
      setReceivedRTCAnswer({ answer, chatId, from });
      await addAnswer(answer, chatId);
    });

    socket.on("receiveIceCandidate", async ({ candidate, chatId }) => {
      await addIceCandidate(candidate, chatId);
    });

    return () => {
      socket.off("requestCall");
      socket.off("isBusy");
      socket.off("callAccepted");
      socket.off("receiveAnswer");
      socket.off("receiveIceCandidate");

      if (getPeerConnection()) {
        closePeerConnection();
      }
    };
  }, []);

  // ! Establish useEffect for changes in callStatus
  // ! (also used for setting callDuration)
  useEffect(() => {
    let intervalId;
    let callTimeoutId;
    let callEndedId;

    // ! for sounds
    if (callStatus === "calling") {
      callingSound.play();
    } else {
      callingSound.pause();
    }

    if (callStatus === "receivingCall") {
      receivingCallSound.play();
    } else {
      receivingCallSound.pause();
    }

    // ! for call duration
    if (callStatus === "calling" || callStatus === "inCall") {
      // Reset the call duration to 0 whenever a new call starts
      setCallDuration(0);

      // Start a timer to increment the call duration every second
      intervalId = setInterval(() => {
        setCallDuration((prevDuration) => prevDuration + 1);
      }, 1000);
    }

    // ! for call timeout
    if (callStatus === "calling") {
      callTimeoutId = setTimeout(() => {
        if (callStatusRef.current === "calling") {
          addToast("User not answering. Try again later.", "orange"); // Notify user that the call timed out
          endCall(callChatId);
        }
      }, 15500);
    }

    // ! for when call is ended
    if (callStatus === "callEnded") {
      // Wait for 2 seconds before resetting the call status to "idle"
      setConnectionState("closed");

      callEndedId = setTimeout(() => {
        cleanupCall();
      }, 2000); // 3 seconds delay

      // cleanup previous timers
      if (intervalId) clearInterval(intervalId);
      if (callTimeoutId) clearTimeout(callTimeoutId);
    }

    // Cleanup function to clear the interval and timeout when the component unmounts or call status changes
    return () => {
      if (intervalId) clearInterval(intervalId);
      if (callTimeoutId) clearTimeout(callTimeoutId);
      if (callEndedId) clearTimeout(callEndedId);
    };
  }, [callStatus, callChatId]);

  // ! Function for ending call
  const endCall = async (chatId) => {
    setCallStatus("callEnded");
    socket.emit("endCall", { chatId });
  };

  const cleanupCall = async () => {
    cleanupLocalStream();
    setCallStatus("idle");
    setCallChatId(null);
    closePeerConnection();
    resetReceivedRTCOffer();
    resetReceivedRTCAnswer();
    setConnectionState(null);
  };

  // ! Listener for if the other party ends the call
  useEffect(() => {
    // Establish listener for if the offer (the call) is ended
    socket.on("endCall", async ({ chatId, from }) => {
      setCallStatus("callEnded");
    });

    return () => {
      socket.off("endCall");
    };
  }, []);

  // ! Function to answer (accept) the call
  const acceptCall = async (chatId) => {
    let pc = initializePeerConnection();

    // If there is localStream, add it to the WebRTC connection
    if (localStream) {
      localStream.getTracks().forEach((track) => {
        pc.addTrack(track, localStream);
      });
    } else {
      try {
        const stream = await initStream({ audio: true, video: false });
        stream.getTracks().forEach((track) => {
          pc.addTrack(track, stream);
        });
      } catch (err) {
        console.error(err);
      }
    }

    // Set up a listener to monitor the WebRTC's connection state and add it to context state
    pc.onconnectionstatechange = (event) => {
      setConnectionState(pc.connectionState);
    };

    // Set up a listener to monitor for new tracks from the remote peer
    // Triggered when receiving a track from the remote peer
    pc.ontrack = (event) => {
      const newRemoteStream = new MediaStream();
      event.streams[0].getTracks().forEach((track) => {
        newRemoteStream.addTrack(track);
      });
      setRemoteStream(newRemoteStream);
    };

    if (receivedRTCOffer?.offer) {
      // If a WebRTC offer hasn't been received yet, then create one (means you're the initiating client)

      await createAnswer(chatId);
    }
  };

  // ! createAnswer function
  let createAnswer = async (chatId) => {
    let pc = getPeerConnection();

    //Event that fires off when a new answer ICE candidate is created
    pc.onicecandidate = (event) => {
      if (event.candidate) {
        socket.emit("sendIceCandidate", {
          candidate: event.candidate,
          chatId,
        });
      }
    };

    await pc.setRemoteDescription(receivedRTCOffer.offer);

    processIceCandidateQueue(chatId);

    const answer = await pc.createAnswer();

    await pc.setLocalDescription(answer);

    socket.emit("answerCall", {
      answer: pc.localDescription,
      chatId,
    });
  };

  // ! on receiving the answer, pass it to this function to connect
  let addAnswer = async (answer, chatId) => {
    let pc = getPeerConnection();
    if (!pc.currentRemoteDescription) {
      await pc.setRemoteDescription(answer);
      processIceCandidateQueue(chatId);
    }
  };

  // ! useEffect to monitor WebRTC connection state changes
  useEffect(() => {
    if (connectionState === "connected") {
      setCallStatus("inCall");
      addToast("Connected", "success");
    }

    if (connectionState === "closed") {
      addToast("Call ended", "orange");
    }
  }, [connectionState]);

  // Check if the user is registered before allowing search
  function startSearch(tags, story) {
    if (userData?.isRegistered) {
      socket.emit(`startSearch`, { tags, story });
      setIsSearching(true);
    } else {
      throw new Error("User is not registered. Cannot start search.");
    }
  }

  function stopSearch() {
    socket.emit(`stopSearch`);
    setIsSearching(false);
    setMatchedUser(null);
  }

  const getMatchedUserData = async () => {
    try {
      const data = await getUserDataById(matchedUser.userId);
      setMatchedUserData(data.user);
    } catch (error) {}
  };

  function acceptMatch() {
    setMatchAccepted(true);
    setIsSearching(false);

    socket.emit("acceptMatch");
    getMatchedUserData();
  }

  function rejectMatch() {
    setMatchAccepted(false);
    setIsSearching(false);
    socket.emit("rejectMatch");
    setMatchedUser(null);
  }

  function sendMessage(chatId, message) {
    socket.emit("sendMessage", { chatId, message });
  }

  // State for Review Modal visibility
  const [isReviewModalVisible, setIsReviewModalVisible] = useState(false);

  function cleanupChat() {
    setIsReviewModalVisible(true);
    setMatchedUser(null);

    cleanupLocalStream();
    cleanupRemoteStream();
  }

  const handleRemoteUserDisconnection = () => {
    // Perform cleanup of the remote stream
    cleanupRemoteStream();
  };

  const closeReviewModal = () => {
    setIsReviewModalVisible(false);
    setMatchedUserData(null);
    setChatData(null);
  };

  const updateMessagesByChatId = (chatId, messages) => {
    setMessagesByChatId((prevMessagesByChatId) => ({
      ...prevMessagesByChatId,
      [chatId]: messages.map(
        (message) => new ChatMessage(message, userData?._id)
      ),
    }));
  };

  return (
    <ChatContext.Provider
      value={{
        isSearching,
        startSearch,
        stopSearch,
        matchedUser,
        rejectMatch,
        acceptMatch,
        chatId,
        setChatId,
        sendMessage,
        error,
        setError,
        initStream,
        localStream,
        remoteStream,
        callStatus,
        callChatId,
        callDuration,
        initiateCall,
        endCall,
        acceptCall,
        receivedRTCOffer,
        receivedRTCAnswer,
        chats,
        cleanupLocalStream,
        cleanupRemoteStream,
        cleanupChat,
        connectionState,
        matchedUserData,
        isReviewModalVisible,
        setIsReviewModalVisible,

        chatData,
        closeReviewModal,
        matchedUserResponse,
        matchAccepted,
        messagesByChatId,
        updateMessagesByChatId,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export { ChatContext, ChatContextProvider };
