// packages/client/src/pages/Lobby/FilterRoom/FilterRoom.jsx
import React, { useState, useEffect, useContext, useRef } from "react";
import { LobbyContext } from "contexts/LobbyContext";
import cn from "classnames";
import styles from "../Lobby.module.css";
import { UserContext } from "contexts/UserContext";
import Story from "../Story/Story";
import Button from "components/Button/Button";

import TagSelect from "../TagSelect/TagSelect";

import FloatingTooltip from "components/FloatingTooltip/FloatingTooltip";
import RecommendedTags from "../RecommendedTags/RecommendedTags";
import { ReactTyped } from "react-typed";
import { motion } from "framer-motion";

import JoyrideWrapper from "components/JoyrideWrapper/JoyrideWrapper"; // Import JoyrideWrapper
import useRecommendedTags from "hooks/useRecommendedTags";
import useTagsSearch from "hooks/useTagsSearch";

const steps = [
  {
    title: "Welcome to the Lobby!",
    target: `[data-tour='filter-room']`,
    placement: "center",
    content:
      "This is the heart of our community. Here, you can discover new friends and interesting conversations. Let's get started!",
    disableBeacon: true,
  },
  {
    target: `[data-tour='story-input']`,
    title: "Share Your Story",
    content:
      "What's on your mind today? Share a bit about what you're thinking or how you're feeling. Your story helps others connect with you.",
    disableBeacon: true,
  },
  {
    target: `[data-tour='tag-select']`,
    title: "Choose Your Interests",
    content:
      "Select tags that reflect your interests or the topics you wish to discuss. This makes it easier to find chat partners with similar interests.",
    disableBeacon: true,
  },
];

const FilterRoom = ({ step, setStep }) => {
  const active = step === 1;
  const { userData } = useContext(UserContext);

  const { selectedTags, selectTag, isTagSelected, story, setStory } =
    useContext(LobbyContext);

  const {
    fetchRecommendedTags,
    tags: recommendedTags,
    loading: loadingRecommendedTags,
    error: errorLoadingRecommendedTags,
  } = useRecommendedTags();

  useEffect(() => {}, []);

  const handleNext = () => {
    setStep(2);
  };

  const tagSelectRef = useRef(null);

  const [showTagSelectNotification, setShowTagSelectNotification] =
    useState(false);

  const handleNextPreCheck = () => {
    if (selectedTags.length === 0) {
      // Show notification
      setShowTagSelectNotification(true);
      // Optionally, you can hide the notification after a delay
      setTimeout(() => setShowTagSelectNotification(false), 3000);
    } else {
      handleNext(); // Proceed with the original next handler
    }
  };

  const handleStoryChange = async (story) => {
    setStory(story);
    fetchRecommendedTags(story);
  };

  const [animationStep, setAnimationStep] = useState(0);

  const greeting = `Hi, ${
    userData?.username ? userData.username : "stranger"
  }. ^500  What do you want to talk about?`;

  return (
    <>
      <JoyrideWrapper steps={steps} disableScrolling />
      <div
        className={cn(styles.filterRoom, {
          [styles.active]: active,
          [styles.hidden]: !active,
        })}
        data-tour="filter-room"
      >
        <div className={styles.header} data-tour="header">
          <h1>
            <ReactTyped
              strings={[greeting]}
              typeSpeed={50}
              showCursor
              startWhenVisible
              onStart={() => {
                setTimeout(() => {
                  setAnimationStep(1);
                }, 500);
              }}
            />
          </h1>
        </div>
        <div data-tour="input-wrapper" className={styles.inputWrapper}>
          <motion.div
            className={styles.textInput}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
            onAnimationComplete={() => {}}
          >
            <Story
              maxWords={25}
              story={story}
              onChange={handleStoryChange}
              placeholder="What's happening?"
            />
          </motion.div>

          <div style={{ color: "white" }}>
            <RecommendedTags
              recommendedTags={recommendedTags}
              isLoading={loadingRecommendedTags}
              selected={selectedTags}
              onSelect={(tag) => selectTag(tag)}
              isTagSelected={isTagSelected}
            />
          </div>
          <motion.div
            className={styles.tagsInput}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
          >
            <TagSelect
              ref={tagSelectRef}
              onSelect={(tag) => selectTag(tag)}
              selected={selectedTags}
            />
            <FloatingTooltip
              anchorRef={tagSelectRef}
              show={showTagSelectNotification}
            >
              <p>Please pick at least one tag</p>
            </FloatingTooltip>
            <p className={styles.helper}>
              <a href="/tags" target="_blank">
                {" "}
                How do I find the right tag?{" "}
              </a>
            </p>
          </motion.div>
        </div>
        <motion.div
          className={styles.btn}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1 }}
        >
          <Button onClick={handleNextPreCheck}>
            <p>Next</p>
          </Button>
        </motion.div>
      </div>
    </>
  );
};

export default FilterRoom;
