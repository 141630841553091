// packages/client/src/pages/Lobby/Lobby.jsx
import React from "react";

import styles from "./MatchModal.module.css";

import Button from "components/Button/Button";
import Tag from "components/Tag/Tag";

const MatchModal = ({
  matchedUser,
  handleAccept,
  handleReject,
  matchedUserResponse,
  matchAccepted,
}) => {
  const renderDecisionMode = () => {
    return (
      <div className={styles.content}>
        <p className={styles.header}>You've found a match</p>
        <div className={styles.body}>
          <div className={styles.story}>
            {matchedUser.story.length > 0 ? (
              <p>{matchedUser.story}</p>
            ) : (
              <p className={styles.noStory}>No story provided</p>
            )}
          </div>
          <div className={styles.tags}>
            {matchedUser.sharedTags.length > 0 ? (
              matchedUser.sharedTags.map((tag, i) => (
                <Tag key={i} tag={tag} viewOnly selected />
              ))
            ) : (
              <p className={styles.noTags}>No tags selected</p>
            )}
          </div>
        </div>
        <div className={styles.btn}>
          <Button onClick={handleAccept}>
            <p>Accept</p>
          </Button>
          <Button onClick={handleReject} className={styles.reject}>
            <p>Reject</p>
          </Button>
        </div>
      </div>
    );
  };
  const renderWaitingMode = () => {
    return (
      <div className={styles.content}>
        <p className={styles.header}>Waiting for your match to accept...</p>
        <div className={styles.body}>
          <div className={styles.story}>
            {matchedUser.story.length > 0 ? (
              <p>{matchedUser.story}</p>
            ) : (
              <p className={styles.noStory}>No story provided</p>
            )}
          </div>
          <div className={styles.tags}>
            {matchedUser.sharedTags.length > 0 ? (
              matchedUser.sharedTags.map((tag, i) => (
                <Tag key={i} tag={tag} viewOnly selected />
              ))
            ) : (
              <p className={styles.noTags}>No tags selected</p>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.backdrop}>
      {!matchAccepted ? renderDecisionMode() : renderWaitingMode()}
    </div>
  );
};

export default MatchModal;
