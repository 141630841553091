import React, { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import styles from "./MatchFound.module.css";
import Button from "components/Button/Button";

const Tag = ({ tag }) => {
  return (
    <div
      className={styles.tag} // Assuming this class is defined in your styles
    >
      {tag}
    </div>
  );
};

const MatchModal = ({ data, onComplete }) => {
  return (
    <div className={styles.modal}>
      <p className={styles.header}>You've found a match</p>
      <div className={styles.body}>
        <div className={styles.story}>
          <p>{data.story}</p>
        </div>
        <div className={styles.tags}>
          {data.tags.map((tag, i) => (
            <Tag key={i} tag={tag} />
          ))}
        </div>
      </div>
      <div className={styles.btn}>
        <Button onClick={() => onComplete && onComplete()}>
          <p>Accept</p>
        </Button>
        <Button className={styles.reject} disabled>
          <p>Reject</p>
        </Button>
      </div>
    </div>
  );
};

const MatchFound = ({ data, onComplete }) => {
  const [visible, setVisible] = useState(false); // State to control the rendering of ReactTyped
  const containerRef = useRef(null);
  const [animationStep, setAnimationStep] = useState(0); // State to control the

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // Trigger s  etShowTyped based on intersection with the viewport
        entries.forEach((entry) => {
          setVisible(entry.isIntersecting);
        });
      },
      {
        root: null, // null means the viewport
        rootMargin: "0px",
        threshold: 0.1, // Trigger when 10% of the observed element is in view
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.disconnect();
      }
    };
  }, []); // Empty dependency array ensures this effect runs only once

  const variants = {
    initial: { opacity: 0, scale: 0.95 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.95 },
  };

  useEffect(() => {
    if (!visible) {
      setAnimationStep(0);
    }
  }, [visible]);

  return (
    <div ref={containerRef}>
      {visible && (
        <AnimatePresence mode="wait">
          {animationStep === 0 && (
            <motion.div
              key="start"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={variants}
              transition={{ duration: 0.3 }}
              onAnimationStart={() => {
                setTimeout(() => {
                  setAnimationStep((prev) => prev + 1);
                }, 1000);
              }}
            >
              <LoadingSpinner />
            </motion.div>
          )}
          {animationStep > 0 && (
            <MatchModal data={data} onComplete={onComplete} />
          )}
        </AnimatePresence>
      )}
    </div>
  );
};

export default MatchFound;
