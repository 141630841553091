// packages/client/src/components/LifeExperience/StoryEditor.module.css
import React, {
  forwardRef,
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  useCallback,
} from "react";
import cn from "classnames";

import styles from "./TagEditor.module.css";

import { createPopper } from "@popperjs/core";
import Portal from "components/Portal/Portal";
import useTagsSearch from "hooks/useTagsSearch";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";

const TagOption = forwardRef(
  (
    {
      tag,
      i,
      selectTag,
      setIsOpen,
      setHighlightedIndex,
      isTagSelected,
      highlightedIndex,
    },
    ref
  ) => {
    return (
      <li
        ref={ref}
        key={tag._id}
        onClick={(e) => {
          e.stopPropagation();
          selectTag(tag);
          setIsOpen(false);
        }}
        onMouseEnter={(e) => {
          setHighlightedIndex(i);
        }}
        onMouseDown={(e) => e.preventDefault()}
        className={cn(styles.tag, {
          [styles.selected]: isTagSelected(tag),
          [styles.highlighted]: i === highlightedIndex,
        })}
      >
        <div className={styles["tag-header"]}>
          <div className={styles["tag-name"]}>{tag.name}</div>
          <div className={styles["tag-hits"]}>{tag.hits}</div>
        </div>
        <div className={styles["tag-description"]}>{tag.description}</div>
      </li>
    );
  }
);

const TagEditor = forwardRef(({ selected, onSelect, className }, ref) => {
  const [isOpen, setIsOpen] = useState(false); //dropdown

  const [query, setQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1); // Manage current page number
  const { tags, loading, hasMore } = useTagsSearch(query, pageNumber); // Use custom hook for fetching tags

  const [highlightedIndex, setHighlightedIndex] = useState(null);

  const containerRef = useRef(null);
  // Conditionally expose the containerRef to the parent component if a ref is provided
  useImperativeHandle(ref, () => containerRef.current);
  const dropdownRef = useRef(null);

  // Handle input change and reset page number
  const handleInputChange = (e) => {
    setQuery(e.target.value);
    setPageNumber(1); // Reset to first page on new input
  };

  const mirrorRef = useRef(null);
  const inputRef = useRef(null);

  const adjustWidth = () => {
    const mirrorText = mirrorRef.current;
    // const inputRef = inputRef.current;

    if (mirrorText && inputRef.current) {
      mirrorText.textContent = query || query.placeholder || " "; // Fallback to placeholder or a single space
      inputRef.current.style.width = `${mirrorText.offsetWidth + 10}px`; // +10 for a little extra space
    }
  };

  useEffect(() => {
    adjustWidth();
  }, [query]); // Adjust width when input changes

  const [isInputFocused, setIsInputFocused] = useState(false);

  useEffect(() => {
    if (isInputFocused) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [isInputFocused]);

  useEffect(() => {
    let popperInstance = null;

    const adjustDropdownWidth = () => {
      if (dropdownRef.current && containerRef.current) {
        const referenceWidth =
          containerRef.current.getBoundingClientRect().width;
        dropdownRef.current.style.width = `${referenceWidth}px`;
      }
    };

    if (isOpen && containerRef.current && dropdownRef.current) {
      adjustDropdownWidth(); // Adjust width before Popper calculates its position

      popperInstance = createPopper(containerRef.current, dropdownRef.current, {
        placement: "bottom-start",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 2], // Adjust these values to control the dropdown's offset from the input field
            },
          },
          {
            name: "preventOverflow",
            options: {
              boundary: "document", // Ensures the popper can overflow containers but not the viewport
            },
          },
        ],
      });

      // Call this function whenever you expect the width of the reference element might change
      window.addEventListener("resize", adjustDropdownWidth);
    }

    const handleClickOutside = (event) => {
      // Check if the click is outside of both the ellipsis and the dropdown
      if (
        containerRef.current &&
        dropdownRef.current &&
        !containerRef.current.contains(event.target) &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    // Add event listener when the dropdown is open
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      if (popperInstance) {
        popperInstance.destroy();
        popperInstance = null;
      }
      window.removeEventListener("resize", adjustDropdownWidth); // Cleanup resize listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  function clearOptions() {
    onSelect(null);
    setQuery("");
  }

  function isTagSelected(tag) {
    return tag === selected;
  }

  const observer = useRef();

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  function selectTag(tag) {
    onSelect(tag);
    setQuery("");
  }

  const tagOptionProps = {
    selectTag,
    setIsOpen,
    setHighlightedIndex,
    isTagSelected,
    highlightedIndex,
  };

  return (
    <div
      ref={containerRef}
      data-tour="tag-editor"
      className={cn(styles.container, className, {
        [styles.focused]: isInputFocused,
      })}
      onClick={() => {
        inputRef.current.focus();
      }}
      onBlur={() => {
        setIsOpen(false);
      }}
    >
      {selected?.name && (
        <button
          className={styles["option-badge"]}
          onClick={(e) => {
            e.stopPropagation();
            // Add any additional logic you might need when the selected tag button is clicked
          }}
        >
          {selected.name}
          <span
            className={styles["remove-btn"]}
            onClick={() => {
              onSelect(null); // Assuming onSelect with null will clear the selection
            }}
          >
            &times;
          </span>
        </button>
      )}
      <input
        ref={inputRef}
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
        onChange={handleInputChange}
        className={cn(styles.comboboxInput)}
        type="text"
        value={query}
        placeholder="Select the relevant tag"
      />
      <span ref={mirrorRef} className={styles["mirror-text"]}></span>
      <button
        className={styles["clear-btn"]}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          clearOptions();
        }}
      >
        &times;
      </button>
      <div className={styles.divider}></div>
      <div className={styles.caret}></div>
      <Portal>
        <ul
          ref={dropdownRef}
          className={cn(styles.dropdown, { [styles.show]: isOpen })}
        >
          {tags.map((tag, i) => {
            if (tags.length === i + 1) {
              return (
                <TagOption
                  ref={lastElementRef}
                  key={tag._id}
                  tag={tag}
                  i={i}
                  {...tagOptionProps}
                />
              );
            } else {
              return (
                <TagOption tag={tag} key={tag._id} i={i} {...tagOptionProps} />
              );
            }
          })}
          {loading && (
            <li className={styles.loading}>
              <LoadingMessage message="Loading more tags..." />
            </li>
          )}
        </ul>
      </Portal>
    </div>
  );
});

export default TagEditor;

// {filteredTags.map((tag, index) => (
//   <li
//     key={index}
//     onClick={(e) => {
//       e.stopPropagation();
//       onSelect(tag);
//       setIsOpen(false);
//     }}
//     onMouseDown={(e) => e.preventDefault()}
//     style={{ cursor: "pointer" }}
//     className={cn(styles.option, {
//       [styles.selected]: isSelected(tag),
//     })}
//   >
//     <div className={styles["tag-header"]}>
//       <div className={styles["tag-name"]}>{tag.name}</div>
//       {/* <div className={styles["tag-hits"]}>{tag.hits}</div> */}
//     </div>
//     {/* <div className={styles["tag-description"]}>
//         {tag.description}
//       </div> */}
//   </li>
// ))}
