// packages/client/src/pages/Register/StepUsername.jsx
import React, { useContext, useEffect, useRef, useState } from "react";
import validator from "validator";
import common from "../Register.module.css";
import self from "./StepUsername.module.css";
import { motion } from "framer-motion";
import cn from "classnames";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import * as USERS_API from "api/users";
import Tooltip from "components/Tooltip/Tooltip";
import TooltipHeader from "components/Tooltip/TooltipHeader";
import TooltipBody from "components/Tooltip/TooltipBody";
import tooltip from "components/Tooltip/Tooltip.module.css";
import { ToastContext } from "contexts/ToastContext";

import JoyrideWrapper from "components/JoyrideWrapper/JoyrideWrapper";

const InputWrapper = ({ children, valid, helperText, isLoading = false }) => {
  return (
    <>
      <div className={cn(self.inputWrapper, { [self.error]: !valid })}>
        {children}
        {isLoading ? (
          <motion.div
            className={self.icon}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <LoadingSpinner />
          </motion.div>
        ) : valid ? (
          <motion.div
            className={self.icon}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <FaCheckCircle />
          </motion.div>
        ) : (
          <motion.div
            className={self.icon}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <FaTimesCircle />
          </motion.div>
        )}
      </div>
      {helperText.length > 0 && (
        <p className={cn(self.helperText, { [self.error]: !valid })}>
          {helperText}
        </p>
      )}
    </>
  );
};

export const StepUsername = ({
  data,
  onSave,
  handleStepValidity,
  isCurrentStepValid,
}) => {
  const [username, setUsername] = useState(data.username || "");

  const [isUsernameAvailable, setIsUsernameAvailable] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { addToast, toastList, removeToast } = useContext(ToastContext);

  const [steps, setSteps] = useState([
    {
      target: '[data-tour="username-input"]',
      title: "Choose your username",
      content:
        "Choose a username that represents you. Remember, it must be unique!",
      disableBeacon: true,
    },
    {
      target: '[data-tour="username-input"]',
      title: "Safety tips",
      content:
        "While we encourage openness and honesty about life experiences, it's crucial to safeguard your personal identity. Please avoid using any personal information such as your real name, birthday, or address. This helps protect your privacy while online.",
      disableBeacon: true,
    },
  ]);

  useEffect(() => {
    handleStepValidity(
      username.length > 0 && isUsernameAvailable && isAlphanumeric(username)
    );
  }, [username, isUsernameAvailable]);

  useEffect(() => {
    return () => {
      handleStepValidity(false, "reset");
    };
  }, []);

  const isAlphanumeric = (value) =>
    validator.isAlphanumeric(value, "en-US", { ignore: "_" });

  useEffect(() => {
    if (username.length > 0) {
      if (isAlphanumeric(username)) {
        // Debounce the API call for efficiency
        const timer = setTimeout(
          () => checkUsernameAvailability(username),
          300
        );
        return () => clearTimeout(timer);
      } else {
        setMessage("Must be alphanumeric.");
      }
    } else {
      setMessage("");
    }
  }, [username]);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const checkUsernameAvailability = async (username) => {
    try {
      setIsLoading(true);

      const data = await USERS_API.checkAvailability(username);

      if (data.available) {
        addToast(data.message, "success");
      } else {
        addToast(data.message, "orange");
      }
      setIsUsernameAvailable(data.available);
      setMessage(data.message);
      setIsLoading(false);

      if (data.available) {
        onSave({ username });
      }
    } catch (error) {
      console.error("Failed to check username availability", error);
      setIsLoading(false);
    }
  };

  const inputRef = useRef(null);

  return (
    <>
      <JoyrideWrapper runOnInit steps={steps} />

      <div className={common.header}>
        <h1>What should people call you?</h1>
      </div>
      <div className={common["input-group"]}>
        <div className={common["label-tooltip"]}>
          <label>Username</label>
          {/* <UsernameTooltip /> */}
        </div>

        <InputWrapper
          isLoading={isLoading}
          valid={isCurrentStepValid}
          helperText={message}
        >
          <input
            className={self["username-input"]}
            ref={inputRef}
            autoFocus
            required
            type="text"
            placeholder={username}
            value={username}
            onChange={handleUsernameChange}
            data-tour="username-input"
          />
        </InputWrapper>
      </div>
    </>
  );
};
