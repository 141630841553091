// packages/client/src/components/ToastNotif/ToastNotifContainer.jsx
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { AnimatePresence } from "framer-motion";
import styles from "./ToastNotif.module.css";

const ToastNotifContainer = ({ children }) => {
  const [portalNode, setPortalNode] = useState();

  useEffect(() => {
    let fatherElement = document.querySelector("#root");

    const modalElement = document.createElement("div");
    fatherElement.appendChild(modalElement);
    setPortalNode(modalElement);

    return () => {
      fatherElement.removeChild(modalElement);
    };
  }, []);
  return (
    <>
      {portalNode &&
        ReactDOM.createPortal(
          <div className={styles.toastContainer}>
            {" "}
            <AnimatePresence>{children}</AnimatePresence>
          </div>,
          portalNode
        )}
    </>
  );
};

export default ToastNotifContainer;
