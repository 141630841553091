// packages/client/src/components/Modal/Modal.jsx
import ReactDOM from "react-dom";
import React, { Children, useEffect, useRef, useState } from "react";
import styles from "./Modal.module.css";
import Button from "components/Button/Button";
import cn from "classnames";
import ModalButtons from "./ModalButtons";

const Modal = ({
  isOpen: isOpenProp,
  onClose,
  children,
  portalNodeSelector = "#root", // Default to '#root' if no selector is provided
  className,
}) => {
  const [isOpen, setIsOpen] = useState(null);
  const [portalNode, setPortalNode] = useState(null);
  // Use a ref for the modal root element
  const modalRootRef = useRef();

  useEffect(() => {
    setIsOpen(isOpenProp);
  }, [isOpenProp]);

  useEffect(() => {
    const portalNodeProp = document.querySelector(portalNodeSelector);
    const fatherElement = portalNodeProp || document.querySelector("#root");
    const modalElement = document.createElement("div");
    fatherElement.appendChild(modalElement);
    setPortalNode(modalElement);

    return () => {
      fatherElement.removeChild(modalElement);
    };
  }, [portalNodeSelector]);

  const handleBackdropClick = (event) => {
    if (event.target === event.currentTarget) {
      setIsOpen(false);
      onClose();
    }
  };

  return isOpen && portalNode
    ? ReactDOM.createPortal(
        <div className={styles.modal} onClick={handleBackdropClick}>
          <div
            className={cn(styles.box, className)}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles["close-btn"]}>
              <button onClick={onClose}>&times;</button>
            </div>
            {children}
          </div>
        </div>,
        portalNode
      )
    : null;
};

export default Modal;

// const toggleModal = () => {
//   setIsOpen(!isOpen);
//   if (isOpen) {
//     onClose();
//   }
// };

// const hasCustomModalButtons = Children.toArray(children).some(
//   (child) => React.isValidElement(child) && child.type.isModalButtons
// );

// return (
//   <>
//     {isOpen &&
//       portalNode &&
//       ReactDOM.createPortal(
//         <div className={styles.modalContent} onClick={handleBackdropClick}>
//           <div
//             className={cn(styles.modalBox, className)}
//             onClick={(e) => e.stopPropagation()}
//           >
//             {children}
//             {!hasCustomModalButtons && (
//               <ModalButtons>
//                 <Button onClick={toggleModal}>Close</Button>
//               </ModalButtons>
//             )}
//           </div>
//         </div>,
//         portalNode
//       )}{" "}
//   </>
// );
