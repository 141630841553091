// packages/client/src/pages/Lobby/Lobby.jsx
import React, { useState, useEffect, useContext } from "react";
import { LobbyContextProvider } from "contexts/LobbyContext";
import cn from "classnames";
import styles from "./Lobby.module.css";

import Button from "components/Button/Button";

import { ChatContext } from "contexts/ChatContext";
import PageWrapper from "components/PageWrapper/PageWrapper";

import ReviewModal from "components/ReviewModal/ReviewModal";
import { ToastContext } from "contexts/ToastContext";

import FilterRoom from "./FilterRoom/FilterRoom";
import WaitingRoom from "./WaitingRoom/WaitingRoom";
import MatchModal from "./MatchModal/MatchModal";
import { UserContext } from "contexts/UserContext";

const ErrorModal = ({ error }) => {
  const { setError } = useContext(ChatContext);

  let msg;
  if (error === "disconnected")
    msg = `Your match has disconnected. Please search again.`;
  if (error === "rejected")
    msg = `Your match has rejected the connection. Please search again.`;

  return (
    <div className={styles.modalBackdrop}>
      <div className={styles.modalContent}>
        <p className={styles.header}>{msg}</p>
        <div className={styles.btn}>
          <Button onClick={() => setError(false)}>Close</Button>
        </div>
      </div>
    </div>
  );
};

const Lobby = () => {
  const {
    matchedUser,
    matchAccepted,
    acceptMatch,
    rejectMatch,
    error,
    isReviewModalVisible,
    matchedUserData,
    chatData,
    submitReview,
    closeReviewModal,
    matchedUserResponse,
  } = useContext(ChatContext);
  const { userData } = useContext(UserContext);

  const [step, setStep] = useState(1);
  const [showMatchModal, setShowMatchModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const { addToast } = useContext(ToastContext);

  useEffect(() => {
    if (matchedUser) {
      setShowMatchModal(true);
    } else {
      setShowMatchModal(false);
    }
  }, [matchedUser]);

  useEffect(() => {
    if (error) {
      setShowErrorModal(true);
    } else {
      setShowErrorModal(false);
    }
  }, [error]);

  useEffect(() => {
    if (matchedUserResponse === "accepted") {
      addToast(
        "Your match has accepted the request. Waiting on you. ",
        "success"
      );
      return;
    }

    if (matchedUserResponse === "rejected") {
      addToast(
        "Your match has rejected the request. Please try again.",
        "error"
      );
      return;
    }
  }, [matchedUserResponse]);

  const handleAccept = () => {
    acceptMatch();
  };
  const handleReject = () => {
    rejectMatch();
    setShowMatchModal(false);
  };

  const handleSubmitReview = async ({ chat, rating, comments }) => {
    try {
      const review = await submitReview(chat, rating, comments);
      addToast("Review successfully submitted", "success");
    } catch (err) {
      addToast("Error submitting review. Try again later!", "error");
    }
  };

  const handleCloseReview = () => {
    closeReviewModal();
  };

  return (
    <LobbyContextProvider>
      <PageWrapper ticker={true}>
        <div id="lobby" className={styles.lobby}>
          <FilterRoom step={step} setStep={setStep} />
          {/* {step === 2 && <WaitingRoom step={step} setStep={setStep} />} */}
          <WaitingRoom step={step} setStep={setStep} />
          {matchedUser && showMatchModal && (
            <MatchModal
              matchedUser={matchedUser}
              handleAccept={handleAccept}
              handleReject={handleReject}
              matchedUserResponse={matchedUserResponse}
              matchAccepted={matchAccepted}
            />
          )}

          {showErrorModal && <ErrorModal error={error} />}
          {isReviewModalVisible && matchedUserData && (
            <ReviewModal
              chat={chatData}
              userData={matchedUserData}
              isOpen={isReviewModalVisible}
              onSubmit={handleSubmitReview}
              onClose={handleCloseReview}
            />
          )}
        </div>
      </PageWrapper>
    </LobbyContextProvider>
  );
};

export default Lobby;
