// packages/client/src/webrtc/index.js
const servers = {
  iceServers: [
    {
      urls: [`stun:stun1.google.com:19302`, `stun:stun2.1.google.com:19302`],
    },
    {
      urls: "turn:relay1.expressturn.com:3478",
      username: "efQ1CNHKV71F8VH5NZ",
      credential: "zZIGjukgFyzrWujZ",
    },
  ],
};

let peerConnection = null;
// Temporary array to store ice candidates while there still isn't an established peerConnection (answering side)
// Adjusting the iceCandidateQueue to support multiple chat sessions
let iceCandidateQueues = {}; // Maps chatId to an array of ICE candidates

const initializePeerConnection = () => {
  if (!peerConnection) {
    peerConnection = new RTCPeerConnection(servers);
  }
  return peerConnection;
};

let getPeerConnection = () => {
  if (!peerConnection) {
  }
  return peerConnection;
};

let closePeerConnection = () => {
  if (peerConnection) {
    peerConnection.close();
    peerConnection = null;
    iceCandidateQueues = {};
  }
};

const addIceCandidate = async (candidate, chatId) => {
  if (peerConnection && peerConnection.remoteDescription) {
    await peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
  } else {
    if (!iceCandidateQueues[chatId]) {
      iceCandidateQueues[chatId] = []; // Initialize the queue for this chatId if it doesn't exist
    }

    iceCandidateQueues[chatId].push(candidate);
  }
};

const processIceCandidateQueue = async (chatId) => {
  if (iceCandidateQueues[chatId]) {
    while (iceCandidateQueues[chatId].length > 0) {
      const candidate = iceCandidateQueues[chatId].shift();
      await addIceCandidate(candidate, chatId); // Ensure addIceCandidate is adapted to handle chatId
    }
  }
};

export {
  initializePeerConnection,
  getPeerConnection,
  closePeerConnection,
  addIceCandidate,
  processIceCandidateQueue,
};
