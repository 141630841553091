// packages/client/src/components/DatePickerInput/DatePickerInput.jsx
import cn from "classnames";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import styles from "./DatePickerInput.module.css";

const DatePickerInput = ({ className, value, onChange }) => {
  const [date, setDate] = useState(value || null);

  useEffect(() => {
    setDate(value);
  }, [value]);

  const handleChange = (date) => {
    setDate(date);
    onChange(date);
  };

  return (
    <div className={styles.wrapper}>
      <DatePicker
        placeholderText="MM/DD/YYYY"
        selected={date}
        maxDate={new Date()}
        onChange={handleChange}
        icon={<FaCalendarAlt />}
        className={cn(styles.input, className)}
      />
    </div>
  );
};

export default DatePickerInput;
