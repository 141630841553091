// packages/client/src/api/index.js
import axios from "axios";
import { BASE_SERVER_URL } from "config";

const commonConfig = {
  withCredentials: true,
  headers: {
    "bypass-tunnel-reminder": "anyValue", // Set your custom header here
    // Add other headers if needed
  },
  // Other common configuration options
};

const api = axios.create({
  baseURL: `${BASE_SERVER_URL}/api`,
  ...commonConfig,
  // Other configuration options
});

const authApi = axios.create({
  baseURL: `${BASE_SERVER_URL}/auth`,
  ...commonConfig,
  // Specific configuration options for auth
});

export default api;
export { authApi }; // Export the auth API as a named export
