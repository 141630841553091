// packages/client/src/hooks/useMultistepForm.js
import { submitFormData } from "api/users";
import { useState } from "react";

export function useMultistepForm(steps) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const isFirstStep = currentStepIndex === 0;
  const isLastStep = currentStepIndex === steps.length - 1;

  function next() {
    setCurrentStepIndex((i) => {
      if (i >= steps.length - 1) return i;
      return i + 1;
    });
  }

  function back() {
    setCurrentStepIndex((i) => {
      if (i <= 0) return i;
      return i - 1;
    });
  }

  function goTo(index) {
    setCurrentStepIndex(index);
  }

  async function submit(formData) {
    try {
      debugger;
      const response = await submitFormData(formData);

      return response; // Return the response for further processing
    } catch (error) {
      throw error; // Propagate the error for handling in the component
    }
  }

  return {
    currentStepIndex,
    step: steps[currentStepIndex],
    steps,
    isFirstStep,
    isLastStep,
    goTo,
    next,
    back,
    submit,
  };
}
