// packages/client/src/components/Navbar/Navbar.jsx
import React, { useState, useContext } from "react";
import cn from "classnames";

import { UserContext } from "contexts/UserContext";
import logo_chair from "../../assets/logo_chair.png";
import styles from "./Navbar.module.css";
import { useNavigate } from "react-router-dom";

function NavMenuItem({ link, label, handleDropDownMenuToggle }) {
  const navigate = useNavigate();
  // Using window.location to get the current URL details
  const currentPathname = window.location.pathname;
  const currentHash = window.location.hash;

  // Check if either pathname or hash matches

  const isPathname = currentPathname === link;
  const isHash = `/${currentHash}` === link;
  const isActive = isPathname || (isPathname && isHash);
  return (
    <li>
      <a
        onClick={() => {
          handleDropDownMenuToggle();
          navigate(link);
        }}
        className={cn({ [styles.active]: isActive })}
      >
        <p>{label}</p>
      </a>
    </li>
  );
}

function Navbar() {
  const { userData } = useContext(UserContext);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleDropDownMenuToggle = () => {
    setMenuOpen((prev) => !prev);
  };

  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <a href="/">
          <img className={styles.logo_image} src={logo_chair} />
          <div className={styles.logo_text}>
            <p>Stumble</p>
          </div>
        </a>
      </div>
      <div
        className={cn(styles.burgerMenuIcon, {
          [styles.active]: menuOpen,
        })}
        onClick={handleDropDownMenuToggle}
      >
        <div className={styles.line}></div>
        <div className={styles.line}></div>
        <div className={styles.line}></div>
      </div>
      <nav className={cn({ [styles.active]: menuOpen })}>
        <ul>
          <NavMenuItem
            link="/"
            label="Home"
            handleDropDownMenuToggle={handleDropDownMenuToggle}
          />
          <NavMenuItem
            link="/lobby"
            label="Lobby"
            handleDropDownMenuToggle={handleDropDownMenuToggle}
          />
          <NavMenuItem
            link="/tags"
            label="Tags"
            handleDropDownMenuToggle={handleDropDownMenuToggle}
          />
          {userData && (
            <NavMenuItem
              link="/chats"
              label="Chats"
              handleDropDownMenuToggle={handleDropDownMenuToggle}
            />
          )}
          {userData && (
            <NavMenuItem
              link="/profile"
              label="Profile"
              handleDropDownMenuToggle={handleDropDownMenuToggle}
            />
          )}
          {userData ? (
            <NavMenuItem
              link="/login"
              label="Logout"
              handleDropDownMenuToggle={handleDropDownMenuToggle}
            />
          ) : (
            <NavMenuItem
              link="/login"
              label="Login"
              handleDropDownMenuToggle={handleDropDownMenuToggle}
            />
          )}
        </ul>
      </nav>
    </header>
  );
}

export default Navbar;
