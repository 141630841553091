import React from "react";
import styles from "./ErrorMessage.module.css"; // Import the CSS module

const ErrorMessage = ({ message }) => {
  return (
    <div className={styles.errorMessage}>
      <h2 className={styles.errorTitle}>Error</h2>
      <p className={styles.errorText}>{message}</p>
    </div>
  );
};

export default ErrorMessage;
