// packages/client/src/pages/Profile/Profile.jsx
import React, { useContext, useEffect, useState } from "react";
import styles from "./Profile.module.css";
import { UserContext } from "contexts/UserContext";
import PageWrapper from "components/PageWrapper/PageWrapper";
import { IoPersonCircle } from "react-icons/io5"; // Using IoPersonCircle for the avatar

import cn from "classnames";

import ProfileContent from "./ProfileContent";
import LifeExperienceContent from "./LifeExperienceContent";

const StoriesContent = () => <div>Your stories go here.</div>;

const Profile = () => {
  const { userData } = useContext(UserContext);
  const [activeTab, setActiveTab] = useState("profile"); // State to track the active tab

  const renderTabContent = () => {
    switch (activeTab) {
      case "profile":
        return <ProfileContent />;
      case "lifeExperience":
        return <LifeExperienceContent />;
      case "stories":
        return <StoriesContent />;
      default:
        return <ProfileContent />;
    }
  };

  return (
    <PageWrapper>
      <div id="profile" className={styles.wrapper}>
        <div className={styles.banner}>
          <div className={styles.avatar}>
            {userData.profileImage ? (
              <img
                src={userData.profileImage}
                alt="Profile"
                className={styles.profileImage}
              />
            ) : (
              <IoPersonCircle size="100px" />
            )}
          </div>

          <div className={styles.userInfo}>
            <div className={styles.username}>{userData.username}</div>
            <div className={styles.infoLine}>{userData.email}</div>
            <div className={styles.infoLine}>
              Joined on {new Date(userData.createdAt).toLocaleDateString()}
            </div>
          </div>
        </div>
        <div className={styles.selection}>
          {/* Tabs for profile, life experiences, and stories can be added here */}
          <div>
            <div
              className={`${styles.tab} ${
                activeTab === "profile" ? styles.activeTab : ""
              }`}
              onClick={() => setActiveTab("profile")}
            >
              Profile
            </div>
            <div
              className={`${styles.tab} ${
                activeTab === "lifeExperience" ? styles.activeTab : ""
              }`}
              onClick={() => setActiveTab("lifeExperience")}
            >
              Life Experiences
            </div>
            <div
              className={`${styles.tab} ${
                activeTab === "stories" ? styles.activeTab : ""
              }`}
              onClick={() => setActiveTab("stories")}
            >
              Stories
            </div>
          </div>
        </div>
        <div className={styles.window}>{renderTabContent()}</div>
      </div>
    </PageWrapper>
  );
};

export default Profile;
