// useTags.js
import { useState, useEffect } from "react";
import * as TAGS_API from "api/tags";

export const useTags = () => {
  const [availableTags, setAvailableTags] = useState([]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const data = await TAGS_API.searchTags({ limit: 50 });
        setAvailableTags(data.tags);
      } catch (err) {
        console.error("Error fetching tags:", err);
      }
    };

    fetchTags();
  }, []);

  return availableTags;
};
