// packages/client/src/config/index.js

export const IS_PRODUCTION = process.env.REACT_APP_NODE_ENV === "production";
export const HOSTED_SERVER_URL = process.env.REACT_APP_HOSTED_SERVER_URL;
export const TUNNEL_SERVER_URL = process.env.REACT_APP_TUNNEL_SERVER_URL;
export const LOCALHOST_SERVER_URL = process.env.REACT_APP_LOCALHOST_SERVER_URL;
// Use an environment variable to determine if TUNNEL should be used
export const TUNNEL_ON = process.env.REACT_APP_TUNNEL_ON === "true"; // I want this to depend on a variable I set on Netlify,etc.
export const BASE_SERVER_URL = IS_PRODUCTION
  ? HOSTED_SERVER_URL
  : TUNNEL_ON
  ? TUNNEL_SERVER_URL
  : LOCALHOST_SERVER_URL;
