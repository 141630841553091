// packages/client/src/pages/Landing/Landing.jsx
import React, { useEffect, useRef, useState } from "react";
import { MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";
import { MdOutlineKeyboardDoubleArrowUp } from "react-icons/md";
import cn from "classnames";

import useMediaQuery from "hooks/useMediaQuery";
import styles from "./Landing.module.css";
import Button from "components/Button/Button";

import Footer from "components/Footer/Footer";

import { ReactTyped } from "react-typed";
import FloatingInTags from "./FloatingInTags/FloatingInTags";
import AnimatedButton from "./AnimatedButton/AnimatedButton";
import MatchFound from "./MatchFound/MatchFound";
import ScrollWrapper from "components/ScrollWrapper/ScrollWrapper";
import { AnimatePresence, motion } from "framer-motion";

const PageIndicator = ({ totalSlides, currentSlide }) => {
  return (
    <div className={styles.pageIndicatorContainer}>
      {Array.from({ length: totalSlides }, (_, index) => (
        <div
          key={index}
          className={cn(styles.dot, {
            [styles.activeDot]: index === currentSlide,
          })}
        />
      ))}
    </div>
  );
};

const FirstSlide = () => {
  const containerRef = useRef();
  const [visible, setVisible] = useState(false);
  const [animationStep, setAnimationStep] = useState(0);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    const obs = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      },
      {
        root: null, // null means the viewport
        rootMargin: "0px",
        threshold: 0.1, // Trigger when 10% of the observed element is in view
      }
    );
    if (containerRef.current) {
      obs.observe(containerRef.current);
    }
  }, []);

  useEffect(() => {
    if (!visible) {
      setAnimationStep(0);
    }
  }, [visible]);

  return (
    <div ref={containerRef} className={styles.section}>
      {visible && (
        <h1 className={styles.hero}>
          <ReactTyped
            strings={["What is Stumble?"]}
            typeSpeed={75}
            showCursor
          />
        </h1>
      )}
    </div>
  );
};

const SecondSlide = () => {
  const containerRef = useRef();
  const [visible, setVisible] = useState(false);
  const [animationStep, setAnimationStep] = useState(0);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    const obs = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      },
      {
        root: null, // null means the viewport
        rootMargin: "0px",
        threshold: 0.1, // Trigger when 10% of the observed element is in view
      }
    );
    if (containerRef.current) {
      obs.observe(containerRef.current);
    }
  }, []);

  useEffect(() => {
    if (!visible) {
      setAnimationStep(0);
    }
  }, [visible]);

  return (
    <div ref={containerRef} className={styles.section}>
      {visible && (
        <h2>
          <ReactTyped
            strings={[
              "Stumble is a way to find others ^500 going through the same struggles as you.",
            ]}
            typeSpeed={50}
            showCursor
          />
        </h2>
      )}
    </div>
  );
};

const HowItWorksSlide = () => {
  const containerRef = useRef();
  const [visible, setVisible] = useState(false);
  const [animationStep, setAnimationStep] = useState(0);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    const obs = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      },
      {
        root: null, // null means the viewport
        rootMargin: "0px",
        threshold: 0.1, // Trigger when 10% of the observed element is in view
      }
    );
    if (containerRef.current) {
      obs.observe(containerRef.current);
    }
  }, []);

  useEffect(() => {
    if (!visible) {
      setAnimationStep(0);
    }
  }, [visible]);

  return (
    <div
      id={styles["how-it-works"]}
      ref={containerRef}
      className={cn(styles.section)}
    >
      {visible && (
        <h2>
          <ReactTyped
            strings={["How it works: ^2000"]}
            typeSpeed={50}
            showCursor={true}
            onComplete={(el) => {
              el.cursor.hidden = true;
              setAnimationStep((prev) => prev + 1);
            }}
            onReset={() => {}}
            className={styles.firstTyped}
          />
        </h2>
      )}
      <div className={cn(styles.grid)}>
        {visible && animationStep > 0 && (
          <div>
            <p>
              <ReactTyped
                strings={["1. Write your story. ^500"]}
                typeSpeed={50}
                showCursor={true}
                onComplete={(el) => {
                  el.cursor.hidden = true;
                  setAnimationStep((prev) => prev + 1);
                }}
              />
            </p>
            {visible && animationStep > 1 && (
              <div className={styles.story}>
                <ReactTyped
                  strings={["Lost my Mom to cancer. ^1000"]}
                  typeSpeed={50}
                  showCursor={true}
                  onComplete={(el) => {
                    el.cursor.hidden = true;
                    setAnimationStep((prev) => prev + 1);
                  }}
                />
              </div>
            )}
          </div>
        )}
        {visible && animationStep > 2 && (
          <div>
            <p>
              <ReactTyped
                strings={["2. Select your tags. ^1000"]}
                typeSpeed={50}
                showCursor={true}
                onComplete={(el) => {
                  el.cursor.hidden = true;
                  setAnimationStep((prev) => prev + 1);
                }}
              />
            </p>
            {visible && animationStep > 3 && (
              <FloatingInTags
                tags={["grief", "cancer"]}
                onComplete={() => {
                  setTimeout(() => {
                    setAnimationStep((prev) => prev + 1);
                  }, 1000);
                }}
              />
            )}
          </div>
        )}
        {visible && animationStep > 4 && (
          <div>
            <p>
              <ReactTyped
                strings={["3. Find a match. ^500"]}
                typeSpeed={50}
                showCursor={true}
                onComplete={(el) => {
                  setComplete(true);
                  el.cursor.hidden = true;
                  setAnimationStep((prev) => prev + 1);
                }}
              />
            </p>
            {visible && animationStep > 5 && (
              <AnimatedButton
                delay={1000}
                onComplete={() => {
                  setComplete(true);

                  setAnimationStep((prev) => prev + 1);
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const MatchFoundSlide = ({ data, onComplete }) => {
  const containerRef = useRef();
  const [visible, setVisible] = useState(false);
  const [animationStep, setAnimationStep] = useState(0);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    const obs = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      },
      {
        root: null, // null means the viewport
        rootMargin: "0px",
        threshold: 0.1, // Trigger when 10% of the observed element is in view
      }
    );
    if (containerRef.current) {
      obs.observe(containerRef.current);
    }
  }, []);

  useEffect(() => {
    if (!visible) {
      setAnimationStep(0);
    }
  }, [visible]);

  return (
    <div
      id={styles["match-found"]}
      ref={containerRef}
      className={cn(styles.section)}
    >
      <MatchFound data={data} onComplete={() => onComplete && onComplete()} />
    </div>
  );
};

const DuringChatSlide = () => {
  const containerRef = useRef();
  const [visible, setVisible] = useState(false);
  const [animationStep, setAnimationStep] = useState(0);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    const obs = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      },
      {
        root: null, // null means the viewport
        rootMargin: "0px",
        threshold: 0.1, // Trigger when 10% of the observed element is in view
      }
    );
    if (containerRef.current) {
      obs.observe(containerRef.current);
    }
  }, []);

  useEffect(() => {
    if (!visible) {
      setAnimationStep(0);
    }
  }, [visible]);

  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  useEffect(() => {}, [animationStep]);

  return (
    <div
      ref={containerRef}
      id={styles["during-chat"]}
      className={styles.section}
    >
      {visible && (
        <>
          <motion.h2
            variants={variants}
            transition={{ ease: "easeIn", duration: 2 }}
            initial="hidden"
            animate={animationStep >= 0 ? "visible" : "hidden"}
            onAnimationComplete={() => {
              if (animationStep === 0) setAnimationStep(1);
            }}
          >
            During the chat, remember:
          </motion.h2>

          <ScrollWrapper>
            <ol className={styles["main-ol"]}>
              <motion.li
                key={1}
                variants={variants}
                transition={{ ease: "easeIn", duration: 2 }}
                initial="hidden"
                animate={animationStep >= 1 ? "visible" : "hidden"}
                onAnimationComplete={() => {
                  if (animationStep === 1) setAnimationStep(2);
                }}
              >
                The person you are chatting with is a stranger.
                <ol>
                  <li>
                    While many are here for support, always proceed with
                    caution.
                  </li>
                </ol>
              </motion.li>

              <motion.li
                key={2}
                variants={variants}
                transition={{ ease: "easeIn", duration: 2 }}
                initial="hidden"
                animate={animationStep >= 2 ? "visible" : "hidden"}
                onAnimationComplete={() => {
                  if (animationStep === 2) setAnimationStep(3);
                }}
              >
                Keep personal information private.
                <ol>
                  <li>
                    Personal information includes details like your real name,
                    contact information, and other identifiable data.
                  </li>
                  <li>
                    Don't feel pressured to share personal information. If you
                    feel uncomfortable or coerced, please refer to our guide on
                    how to{" "}
                    <a
                      href="/reporting"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Report Inappropriate Behavior
                    </a>
                  </li>
                </ol>
              </motion.li>

              <motion.li
                key={3}
                variants={variants}
                transition={{ ease: "easeIn", duration: 2 }}
                initial="hidden"
                animate={animationStep >= 3 ? "visible" : "hidden"}
                onAnimationComplete={() => {
                  if (animationStep === 3) setAnimationStep(4);
                }}
              >
                Be respectful and empathetic. Many people are here either
                seeking help or looking to offer support.
              </motion.li>
              <motion.li
                key={4}
                variants={variants}
                transition={{ ease: "easeIn", duration: 2 }}
                initial="hidden"
                animate={animationStep >= 4 ? "visible" : "hidden"}
                onAnimationComplete={(obj) => {
                  if (animationStep === 4) setAnimationStep(5);
                }}
              >
                For more rules and reminders about interacting with chatmates
                online, see our{" "}
                <a href="/rules" target="_blank" rel="noopener noreferrer">
                  Chat Rules &amp; Reminders.
                </a>
              </motion.li>
            </ol>
          </ScrollWrapper>
        </>
      )}
    </div>
  );
};

const ReasonSlide = () => {
  const containerRef = useRef();
  const [visible, setVisible] = useState(false);
  const [animationStep, setAnimationStep] = useState(0);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    const obs = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      },
      {
        root: null, // null means the viewport
        rootMargin: "0px",
        threshold: 0.1, // Trigger when 10% of the observed element is in view
      }
    );
    if (containerRef.current) {
      obs.observe(containerRef.current);
    }
  }, []);

  useEffect(() => {
    if (!visible) {
      setAnimationStep(0);
    }
  }, [visible]);

  return (
    <div ref={containerRef} className={styles.section} id={styles.reason}>
      {visible && (
        <p>
          <ReactTyped
            strings={["The world is a big place. ^1500"]}
            typeSpeed={50}
            showCursor={true}
            onComplete={(el) => {
              el.cursor.hidden = true;
              setAnimationStep((prev) => prev + 1);
            }}
          />
        </p>
      )}
      {animationStep > 0 && (
        <p>
          <ReactTyped
            strings={["But life is short. ^1500"]}
            typeSpeed={50}
            showCursor={true}
            onComplete={(el) => {
              el.cursor.hidden = true;
              setAnimationStep((prev) => prev + 1);
            }}
          />
        </p>
      )}
      {animationStep > 1 && (
        <p>
          <ReactTyped
            strings={["And its worthwhile finding the right people. ^1500"]}
            typeSpeed={50}
            showCursor={true}
            onComplete={(el) => {
              // el.cursor.hidden = true;
              setAnimationStep((prev) => prev + 1);
            }}
          />
        </p>
      )}
    </div>
  );
};

const MomSlide = () => {
  const containerRef = useRef();
  const [visible, setVisible] = useState(false);
  const [animationStep, setAnimationStep] = useState(0);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    const obs = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      },
      {
        root: null, // null means the viewport
        rootMargin: "0px",
        threshold: 0.1, // Trigger when 10% of the observed element is in view
      }
    );
    if (containerRef.current) {
      obs.observe(containerRef.current);
    }
  }, []);

  useEffect(() => {
    if (!visible) {
      setAnimationStep(0);
    }
  }, [visible]);
  return (
    <div ref={containerRef} className={styles.section} id={styles.mom}>
      {visible && (
        <p>
          <ReactTyped
            strings={[
              "Ultimately, ^500 I built this in honor of my Mom. ^1500",
            ]}
            typeSpeed={50}
            showCursor={true}
            onComplete={(el) => {
              el.cursor.hidden = true;
              setAnimationStep((prev) => prev + 1);
            }}
          />
        </p>
      )}
      {animationStep > 0 && (
        <p>
          <ReactTyped
            strings={["She was always that person for me.^1500"]}
            typeSpeed={50}
            showCursor={true}
            onComplete={(el) => {
              setAnimationStep((prev) => prev + 1);
            }}
          />
        </p>
      )}
    </div>
  );
};
const LastSlide = () => {
  const containerRef = useRef();
  const [visible, setVisible] = useState(false);
  const [animationStep, setAnimationStep] = useState(0);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    const obs = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      },
      {
        root: null, // null means the viewport
        rootMargin: "0px",
        threshold: 0.1, // Trigger when 10% of the observed element is in view
      }
    );
    if (containerRef.current) {
      obs.observe(containerRef.current);
    }
  }, []);

  useEffect(() => {
    if (!visible) {
      setAnimationStep(0);
    }
  }, [visible]);
  return (
    <div
      ref={containerRef}
      className={cn(styles.section, styles["last-slide"])}
    >
      {visible && (
        <p>
          <ReactTyped
            strings={[
              "My  hope is that through this app^750, you can find that person in someone else.^1000",
            ]}
            typeSpeed={50}
            showCursor={true}
            onComplete={(el) => {
              // el.cursor.hidden = true;
              setAnimationStep((prev) => prev + 1);
            }}
          />
        </p>
      )}
      {animationStep > 0 && (
        <motion.div
          initial={{ opacity: 0 }} // Start with the button invisible
          animate={{ opacity: 1 }} // Fade in to full visibility
          transition={{ duration: 1 }} // Control the speed of the fade-in effect
        >
          <Button href="/lobby">
            <p>Get started</p>
          </Button>
        </motion.div>
      )}
      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
  );
};

const Landing = () => {
  const isMobileView = useMediaQuery(`(max-width: 768px)`);
  const containerRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const newSlideIndex = Array.from(
              containerRef.current.children
            ).indexOf(entry.target);

            setCurrentSlide(newSlideIndex);
          }
        });
      },
      {
        root: containerRef.current,
        threshold: 0.5, // Adjust this value based on when you consider a slide to be "current"
      }
    );

    const slides = Array.from(containerRef.current.children);
    slides.forEach((slide) => observer.observe(slide));

    setTotalSlides(slides.length);

    return () => {
      slides.forEach((slide) => observer.unobserve(slide));
    };
  }, []);

  useEffect(() => {}, [currentSlide]);

  const nextSlide = () => {
    setCurrentSlide((prev) => {
      const nextIndex = Math.min(prev + 1, totalSlides - 1);
      const targetSlide = containerRef.current.childNodes[nextIndex];
      targetSlide.scrollIntoView({ behavior: "smooth", block: "nearest" });

      return nextIndex;
    });
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => {
      const prevIndex = Math.max(prev - 1, 0);
      const targetSlide = containerRef.current.childNodes[prevIndex];
      targetSlide.scrollIntoView({ behavior: "smooth", block: "nearest" });

      return prevIndex;
    });
  };

  return (
    <div className={styles.landing}>
      <div
        className={cn(styles.button, styles.buttonUp, {
          [styles.buttonFade]: currentSlide === 0,
        })}
        onClick={prevSlide}
      >
        <MdOutlineKeyboardDoubleArrowUp />
      </div>
      <div ref={containerRef} className={styles.container}>
        <FirstSlide />
        <SecondSlide />
        <HowItWorksSlide />
        <MatchFoundSlide
          data={{
            story: "Lost my grandma to cancer.",
            tags: ["grief", "cancer"],
          }}
          onComplete={() => {
            nextSlide();
          }}
        />
        <DuringChatSlide />
        {/* Add the Review Slide once Reviews are set up */}
        {/* Philosophy */}
        <ReasonSlide />
        <MomSlide />

        <LastSlide />
      </div>

      <div
        className={cn(styles.button, styles.buttonDown, {
          [styles.buttonFade]: currentSlide === totalSlides - 1,
        })}
        onClick={nextSlide}
      >
        <MdOutlineKeyboardDoubleArrowDown />
      </div>
      <PageIndicator totalSlides={totalSlides} currentSlide={currentSlide} />
    </div>
  );
};

export default Landing;
