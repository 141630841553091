import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./styles.css"; //global styles
import "./constants/colors.css";
import "./constants/heights.css";
import "./constants/breakpoints.css";
import "./constants/boxshadows.css";
import "./constants/zindex.css";
import { UserContextProvider } from "contexts/UserContext";
import { ChatContextProvider } from "contexts/ChatContext";
import { ToastContextProvider } from "contexts/ToastContext";
import { SoundContextProvider } from "contexts/SoundContext";
import { SocketContextProvider } from "contexts/SocketContext";

ReactDOM.createRoot(document.getElementById("root")).render(
  <BrowserRouter>
    <SoundContextProvider>
      <ToastContextProvider>
        <SocketContextProvider>
          <UserContextProvider>
            <ChatContextProvider>
              <App />
            </ChatContextProvider>
          </UserContextProvider>
        </SocketContextProvider>
      </ToastContextProvider>
    </SoundContextProvider>
  </BrowserRouter>
);
