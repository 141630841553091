// packages/client/src/classes/ChatMessage.js

class ChatMessage {
  constructor(data, currentUserId) {
    // Initialize default values upfront
    this.text = "Message content unavailable"; // Default message
    this.sharedTags = []; // Default to no shared tags
    this.senderId = data.senderId; // Assign senderId from data
    this.type = "otherUser"; // Default type, will be updated
    this.timestamp = new Date(data.createdAt) || new Date(); // Assign timestamp
    this.read = data.read || false; // Assign read status

    try {
      // Attempt to parse 'content' if it's a string, assuming all messages are JSON formatted
      const parsedContent = JSON.parse(data.content);
      this.text = parsedContent.text; // Assign text from parsed content
      this.sharedTags = parsedContent.sharedTags || []; // Assign sharedTags if available
    } catch (error) {
      console.error("Error parsing message content:", error);
      // No need to set defaults here as they're already initialized
    }

    // Determine the message type based on senderId and currentUserId
    this.type = this.determineMessageType(
      data.senderId,
      currentUserId,
      data.senderType
    );
  }

  determineMessageType(senderId, currentUserId, senderType) {
    if (senderType === "system") {
      return "system";
    } else if (senderId === currentUserId) {
      return "currentUser";
    }
    return "otherUser";
  }
}

export default ChatMessage;
