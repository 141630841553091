import React from "react";
import ToastNotifContainer from "./ToastNotifContainer";
import ToastNotif from "./ToastNotif";

const ToastNotifs = ({ toastList, removeToast }) => {
  return (
    <ToastNotifContainer>
      {toastList.map((toast) => (
        <ToastNotif
          key={toast.id}
          id={toast.id}
          message={toast.message}
          type={toast.type}
          onClose={() => removeToast(toast.id)}
        />
      ))}
    </ToastNotifContainer>
  );
};

export default ToastNotifs;
