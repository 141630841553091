// packages/client/src/App.js
import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styles from "./App.module.css";

import { Navbar } from "components";
import Views from "pages/Views";
import socket from "socket";
import ToastNotifs from "components/ToastNotif/ToastNotifs";
import { ToastContext } from "contexts/ToastContext";

const App = () => {
  const location = useLocation();
  const hideNavbarRoutes = ["/register", "/new-story"];
  const showNavbar = !hideNavbarRoutes.includes(location.pathname);

  const isRegisterRoute = location.pathname === "/register";
  const isWritingRoute = location.pathname === "/new-story";

  const { toastList, removeToast } = useContext(ToastContext);

  useEffect(() => {
    socket.connect();
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div id="app" className={styles.app}>
      {showNavbar && <Navbar />}
      <main className={styles.main}>
        <Views />
      </main>
      <ToastNotifs toastList={toastList} removeToast={removeToast} />
    </div>
  );
};

export default App;
