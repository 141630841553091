import React, { useContext } from "react";
import cn from "classnames";
import { TalkTicker } from "components";
import styles from "./PageWrapper.module.css";

import { ToastContext } from "contexts/ToastContext";
import Footer from "components/Footer/Footer";

const PageWrapper = ({ children, ticker, footer }) => {
  return (
    <div className={cn(styles.wrapper, { [styles["with-ticker"]]: ticker })}>
      {ticker && <TalkTicker />}
      {children}
      {footer && <Footer />}
    </div>
  );
};

export default PageWrapper;
