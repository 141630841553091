// packages/client/src/components/Tooltip/TooltipBody.jsx
import React from "react";
import styles from "./Tooltip.module.css";
import cn from "classnames";

const TooltipBody = ({ className, children, spacing = 0 }) => {
  // Inline style for setting the gap
  const style = {
    display: "flex",
    flexDirection: "column",
    gap: `${spacing}px`,
  };

  return (
    <div
      className={cn(styles.tooltipBody, className)}
      style={spacing > 0 ? style : null} // Apply the style only if spacing is greater than 0
    >
      {children}
    </div>
  );
};

export default TooltipBody;
