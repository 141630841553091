// packages/client/src/pages/Chat/ChatMessage.jsx
import React, { useState } from "react";
import styles from "./ChatMessage.module.css";

import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import cn from "classnames";

const ChatMessage = ({ data }) => {
  const [showTimestamp, setShowTimestamp] = useState(false);

  const { text, sharedTags, type, timestamp } = data;
  const navigate = useNavigate();

  const getMessageStyle = (type) => {
    switch (type) {
      case "currentUser":
        return `${styles.message} ${styles.currentUser}`;
      case "otherUser":
        return `${styles.message} ${styles.otherUser}`;
      case "system":
        return `${styles.message} ${styles.systemMessage}`;
      default:
        return styles.message;
    }
  };

  const messageClass = getMessageStyle(type);

  const toggleTimestamp = () => {
    setShowTimestamp(!showTimestamp);
  };

  // Format the timestamp (adjust according to your desired format)
  const formattedTimestamp = timestamp.toLocaleString();

  // Animation variants for Framer Motion
  const variants = {
    enter: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5, ease: "easeOut" },
    },
    exit: {
      y: -10, // Adjust this value based on the actual size of your component or desired effect
      opacity: 0,
      transition: { duration: 0.5, ease: "easeIn" },
    },
  };

  const renderSharedTags = () => {
    // Only render shared tags if they exist and it's a system message
    if (type === "system" && sharedTags && sharedTags.length > 0) {
      const tagsMarkup = sharedTags.map((tag) => (
        <span key={tag._id} className={styles.tag}>
          {tag.name}
        </span>
      ));
      return <div className={styles.sharedTags}>{tagsMarkup}</div>;
    }
    return null;
  };

  return (
    <>
      <div
        className={cn(styles.message, {
          [styles.currentUser]: type === "currentUser",
          [styles.otherUser]: type === "otherUser",
          [styles.system]: type === "system",
        })}
      >
        <div className={styles.body} onClick={toggleTimestamp}>
          {text} {renderSharedTags()}
        </div>
        <AnimatePresence>
          {showTimestamp && (
            <motion.div
              key={formattedTimestamp}
              initial="exit"
              animate="enter"
              exit="exit"
              variants={variants}
              transition={{ duration: 0.25 }}
              className={styles.timestamp}
            >
              {formattedTimestamp}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default ChatMessage;
