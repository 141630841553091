const { createContext, useState, useEffect } = require("react");
const callingSoundRaw = require("../assets/sounds/internalRing.mp3");
const messageNotifRaw = require("../assets/sounds/messageNotif.mp3");
const receivingCallSoundRaw = require("../assets/sounds/normalSoftCall.mp3");

const SoundContext = createContext();

const SoundContextProvider = ({ children }) => {
  const callingSound = new Audio(callingSoundRaw);
  callingSound.loop = true;
  const messageNotifSound = new Audio(messageNotifRaw);
  const receivingCallSound = new Audio(receivingCallSoundRaw);
  receivingCallSound.loop = true;
  // useEffect(() => {
  //   const originalTitle = document.title;
  //   const updateTitle = () => {
  //     const unreadCount = getUnreadMessagesCount(); // Implement this based on your app's logic
  //     const hasActiveCall = checkActiveCall(); // Implement this based on your app's logic

  //     let newTitle = originalTitle;
  //     if (unreadCount > 0) {
  //       newTitle = `(${unreadCount}) ${originalTitle}`;
  //     }
  //     if (hasActiveCall) {
  //       newTitle = `📞 ${newTitle}`;
  //     }
  //     document.title = newTitle;
  //   };

  //   updateTitle();

  //   // Optional: Listen for changes in unread messages or call status
  //   // and call updateTitle accordingly.

  //   return () => {
  //     document.title = originalTitle; // Reset title on cleanup
  //   };
  // }, []);

  return (
    <SoundContext.Provider
      value={{ callingSound, messageNotifSound, receivingCallSound }}
    >
      {children}
    </SoundContext.Provider>
  );
};

export { SoundContext, SoundContextProvider };
