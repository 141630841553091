import React from "react";
import fb_sprite from "assets/fb-sprite.png";
import yt_sprite from "assets/yt-sprite.png";
import ig_sprite from "assets/ig-sprite.png";
import x_sprite from "assets/x-sprite.png";
import styles from "./Footer.module.css";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  // Function to handle internal navigation
  const handleNavigate = (path) => {
    navigate(path);
  };
  return (
    <footer id="footer" className={styles.footer}>
      <div className={styles.social}>
        <a href={`https://www.facebook.com`} target="_blank">
          <img src={fb_sprite}></img>
        </a>
        <a href={`https://www.youtube.com`} target="_blank">
          <img src={yt_sprite}></img>
        </a>
        <a href={`https://www.instagram.com`} target="_blank">
          <img src={ig_sprite}></img>
        </a>
        <a href={`https://www.x.com`} target="_blank">
          <img src={x_sprite}></img>
        </a>
      </div>
      <div className={styles.links}>
        <ul>
          <li onClick={() => handleNavigate("/rules")}>Rules</li>
          <li onClick={() => handleNavigate("/terms")}>Terms</li>
          <li onClick={() => handleNavigate("/privacy")}>Privacy</li>
          <li onClick={() => handleNavigate("/contact")}>Contact Us</li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
