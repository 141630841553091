import React, { createContext, useEffect, useState } from "react";

import socket from "socket";

const SocketContext = createContext();

const SocketContextProvider = ({ children }) => {
  const [serverOnline, setServerOnline] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleConnect = () => {
      console.log("Connected to server");
      setServerOnline(true);
      setLoading(false);
    };

    const handleDisconnect = () => {
      console.log("Disconnected from server");
      setServerOnline(false);
      setLoading(false);
    };

    const handleConnectError = (error) => {
      console.log("Connection error");

      setLoading(false);
      setServerOnline(false);
    };

    socket.on("connect", handleConnect);
    socket.on("disconnect", handleDisconnect);
    socket.on("connect_error", handleConnectError);

    // Clean up
    return () => {
      socket.off("connect", handleConnect);
      socket.off("disconnect", handleDisconnect);
      socket.off("connect_error", handleConnectError);
    };
  }, []);

  return (
    <SocketContext.Provider value={{ serverOnline, loading }}>
      {children}
    </SocketContext.Provider>
  );
};

export { SocketContext, SocketContextProvider };
