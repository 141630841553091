export default function formatGender(val) {
  let gender = val.toLowerCase();
  if (gender === "male") {
    return "M";
  } else if (gender === "female") {
    return "F";
  } else {
    return "Other";
  }
}
