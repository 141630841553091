import PageWrapper from "components/PageWrapper/PageWrapper";
import React from "react";
import styles from "./Terms.module.css";
import Footer from "components/Footer/Footer";

const Terms = () => {
  return (
    <PageWrapper footer>
      <div className={styles.terms}>
        <h1>Terms</h1>
        <ol>
          <li>
            <h2>Introduction</h2>
            <ol>
              <li>
                Welcome to Stumble. By using our app, you agree to these Terms
                of Service ("Terms"), entering into a binding contract with
                Stumble. References to "us", "we", and "our" mean Stumble, and
                "you" and "your" refer to you, the user.
              </li>
            </ol>
          </li>
          <li>
            <h2>Access and Use of Stumble</h2>
            <ol>
              <li>
                You must be at least 18 years old or of legal age in your
                jurisdiction.
              </li>
              <li>
                We grant you a limited, non-exclusive, revocable license to use
                Stumble in accordance with these Terms.
              </li>
              <li>
                Stumble reserves the right to modify or discontinue, temporarily
                or permanently, the app or any service to which it connects.
              </li>
            </ol>
          </li>
          <li>
            <h2>User Content</h2>
            <ol>
              <li>
                You may post content, including text and images, which you own
                or have the right to use.
              </li>
              <li>
                By posting, you grant Stumble a non-exclusive, royalty-free
                license to use, modify, and display the content within the app.
              </li>
              <li>
                Stumble does not claim ownership of your content, but you
                confirm having all necessary rights and permissions for its use.
              </li>
            </ol>
          </li>
          <li>
            <h2>Prohibited Conduct</h2>
            <ol>
              <li>
                You agree not to misuse the app or help anyone else do so.
              </li>
              <li>
                You must not attempt to harm our system or secure unauthorized
                access to our data.
              </li>
            </ol>
          </li>

          <li>
            <h2>Modifications to the Terms</h2>
            <ol>
              <li>
                Stumble may modify these Terms at any time, and such
                modifications will be effective immediately upon posting.
              </li>
            </ol>
          </li>
          <li>
            <h2>Contact Us</h2>
            <ol>
              <li>
                For questions or concerns about our privacy practices, please
                contact us at help@stumble.chat.
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </PageWrapper>
  );
};

export default Terms;
