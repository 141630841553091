// packages/client/src/socket/index.js
import { BASE_SERVER_URL } from "config";
import { io } from "socket.io-client";

const socket = io(BASE_SERVER_URL, {
  autoConnect: false,
  withCredentials: true, // important to set this to true to send cookies https://stackoverflow.com/questions/23851603/socket-io-not-sending-cookies-in-the-handshake-causing-the-request-to-stall
  extraHeaders: {
    "bypass-tunnel-reminder": "anyValue",
  },
});

export default socket;
