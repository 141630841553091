// packages/client/src/api/auth.js
import { authApi as api } from "api";
import handleError from "./handleError";

export const fetchUserData = async () => {
  try {
    const res = await api.get("/user");

    return res.data;
  } catch (err) {
    handleError(err);
  }
};

export const setCookie = async () => {
  const res = await api.get("/cookie");
};
