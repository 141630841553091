// packages/client/src/components/ToastNotif/ToastNotif.jsx
import React, { useEffect } from "react";
import { motion } from "framer-motion";
import styles from "./ToastNotif.module.css"; // Import your CSS module here
import { FaXmark } from "react-icons/fa6";

const ToastNotif = ({ id, message, type, onClose, duration = 3000 }) => {
  // Automatically close the toast after a specified duration
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, []);

  // Animation variants
  const variants = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -50 },
  };

  return (
    <motion.div
      className={`${styles.toast} ${styles[type]}`}
      initial="initial"
      animate="animate"
      exit="exit"
      variants={variants}
      transition={{ duration: 0.5 }}
    >
      <span>{message}</span>
      <button onClick={onClose} className={styles.closeButton}>
        <FaXmark />
      </button>
    </motion.div>
  );
};

export default ToastNotif;
