// packages/client/src/components/SearchInput/SearchInput.jsx
import React, { useState } from "react";
import styles from "./SearchInput.module.css";

import { MdSearch } from "react-icons/md";
import cn from "classnames";

const SearchInput = ({ value, onChange, onClear, placeholder }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className={styles.inputContainer}>
      <MdSearch className={styles.icon} />
      <input
        className={cn(styles.input, { [styles.focused]: isFocused })}
        type="text"
        placeholder={placeholder || "Type a sentence..."}
        value={value}
        onChange={onChange}
        onFocus={() => setIsFocused(true)} // Assuming you add isFocused state
        onBlur={() => setIsFocused(false)} // Assuming you add isFocused state
      />
      {value && (
        <button
          className={cn(styles["clear-btn"], styles.icon)}
          onClick={(e) => {
            e.stopPropagation();
            onClear();
          }}
        >
          &times;
        </button>
      )}
    </div>
  );
};

export default SearchInput;
