// packages/client/src/api/chats.js
import api from "api";

// packages/client/src/api/chats.js

export const fetchChatData = async (chatId) => {
  try {
    const res = await api.get(`chats/${chatId}`);
    return res.data;
  } catch (err) {
    console.error(err);
  }
};

export const fetchUserChats = async (userId, page = 1, limit = 10) => {
  try {
    const response = await api.get(
      `chats/user/${userId}?page=${page}&limit=${limit}`
    );
    return response.data; // The chats data
  } catch (error) {
    console.error("Error fetching user chats:", error);
    // Handle the error appropriately
  }
};

export const fetchMessagesForChat = async (chatId, options = {}) => {
  const { skip = 0, limit = 50 } = options;

  try {
    // Construct the query parameters string from the options

    const res = await api.get(`chats/${chatId}/messages`, {
      params: { skip, limit },
    });

    // Assuming the response data is the array of messages
    return res.data; // Make sure to return the data so it can be used where this function is called
  } catch (err) {
    console.error("Error fetching chat messages:", err);
    throw err; // It's often useful to re-throw the error so the calling code can handle it
  }
};
