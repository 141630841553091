import React from "react";
import cn from "classnames";
import styles from "./Tag.module.css";

// const Tag = ({ tag, selected, viewOnly }) => {
//   return (
//     <div
//       className={cn(styles.tag, {
//         [styles.selectedTag]: selected,
//         [styles.viewOnly]: viewOnly,
//       })}
//       key={tag._id}
//     >
//       {tag.name}
//     </div>
//   );
// };

// Added onClick prop to the Tag component
const Tag = ({ tag, selected, viewOnly, onClick }) => {
  return (
    <div
      className={cn(styles.tag, {
        [styles.selectedTag]: selected,
        [styles.viewOnly]: viewOnly,
      })}
      onClick={onClick} // Use the onClick prop
      key={tag._id}
    >
      {tag.name}
    </div>
  );
};

export default Tag;
