// useLifeExperiences.js
import { useState } from "react";

export const useLifeExperiences = (initialData) => {
  const [lifeExperiences, setLifeExperiences] = useState(() => {
    // Initialize lifeExperiences from initialData
    return initialData &&
      initialData.lifeExperiences &&
      initialData.lifeExperiences.length > 0
      ? initialData.lifeExperiences
      : [];
  });

  /**
   * Adds a new life experience to the current list of life experiences.
   * This function updates the state to include the newly provided life experience
   * by appending it to the end of the existing life experiences array. Before calling
   * this function, ensure that the input is validated to meet your application's requirements,
   * especially considering that it does not perform any checks for incomplete last tag stories itself.
   *
   * @param {Object} lifeExperience - The new life experience to be added. Should conform to the structure
   * observed in the existing life experiences, containing both a `tag` and a `story`.
   * @param {Object} lifeExperience.tag - An object representing the tag associated with the new life experience.
   * @param {string} lifeExperience.tag._id - The unique identifier of the tag.
   * @param {string} lifeExperience.tag.name - The name of the tag.
   * @param {string} [lifeExperience.tag.description] - An optional description of the tag.
   * @param {number} [lifeExperience.tag.hits] - An optional number of hits or popularity of the tag.
   * @param {string} lifeExperience.story - The narrative or details of the life experience associated with the tag.
   *
   * @example
   * addLifeExperience({
   *   tag: {
   *     _id: "65436a9256985446898af17a",
   *     name: "grief",
   *     description: "Grief is the mix of feelings you have when you lose someone you love.",
   *     hits: 10
   *   },
   *   story: "It was a challenging time, but I learned a lot about myself."
   * });
   */
  const addLifeExperience = (lifeExperience) => {
    setLifeExperiences((prev) => [...prev, lifeExperience]);
  };

  const editLifeExperience = (index, updatedLifeExperience) => {
    setLifeExperiences((prev) =>
      prev.map((exp, i) => (i === index ? updatedLifeExperience : exp))
    );
  };

  const deleteLifeExperience = (index) => {
    setLifeExperiences((prev) => prev.filter((_, i) => i !== index));
  };

  return {
    lifeExperiences,
    addLifeExperience,
    editLifeExperience,
    deleteLifeExperience,
    setLifeExperiences,
  };
};
