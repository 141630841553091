// packages/client/src/components/Tooltip/Tooltip.jsx
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styles from "./Tooltip.module.css";
import { HiOutlineInformationCircle } from "react-icons/hi";

const Tooltip = ({ children, portalNodeSelector }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [portalNode, setPortalNode] = useState(null);

  useEffect(() => {
    let fatherElement;
    const portalNodeProp = document.querySelector(portalNodeSelector);

    if (!portalNodeProp) {
      fatherElement = document.querySelector("#root");
    } else {
      fatherElement = portalNodeProp;
    }
    const modalElement = document.createElement("div");
    fatherElement.appendChild(modalElement);
    setPortalNode(modalElement);

    return () => {
      fatherElement.removeChild(modalElement);
    };
  }, []);

  const toggleTooltip = () => {
    setIsOpen(!isOpen);
  };

  const handleBackdropClick = (event) => {
    if (event.target === event.currentTarget) {
      setIsOpen(false);
    }
  };

  return (
    <div className={styles.tooltipContainer}>
      <HiOutlineInformationCircle
        className={styles.tooltipIcon}
        onClick={toggleTooltip}
      />
      {isOpen &&
        portalNode &&
        ReactDOM.createPortal(
          <div className={styles.tooltipContent} onClick={handleBackdropClick}>
            <div
              className={styles.tooltipBox}
              onClick={(e) => e.stopPropagation()}
            >
              {children}
              <div className={styles.btnContainer}>
                <button onClick={toggleTooltip} className={styles.closeButton}>
                  Close
                </button>
              </div>
            </div>
          </div>,
          portalNode
        )}
    </div>
  );
};

export default Tooltip;

// references:
// https://www.freecodecamp.org/news/how-to-render-modals-in-react-bbe9685e947e/
