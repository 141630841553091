import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import styles from "./LoadingPage.module.css";
import React from "react";

const LoadingPage = () => {
  return (
    <div className={styles.container}>
      <LoadingMessage message="Loading..." />
    </div>
  );
};

export default LoadingPage;
