import { fetchUserChats } from "api/users";
import PageWrapper from "components/PageWrapper/PageWrapper";
import { UserContext } from "contexts/UserContext";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Chats.module.css";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

const ChatBlock = ({ chatData }) => {
  const [lastMessage, setLastMessage] = useState("");
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    // Assuming you have an API method to fetch the last message
    // fetchLastMessage(chatData._id).then((message) => setLastMessage(message.text));
  }, [chatData._id]);

  const handleClick = () => {
    navigate(`/chat/${chatData._id}`);
  };

  // Identify the other participant (assuming the current user is not included in the participants array)
  const otherParticipant = chatData.participants.find(
    (p) => p._id !== userData._id
  ); // currentUser needs to be defined based on your app's context/state

  return (
    <div className={styles.chatBlock} onClick={handleClick}>
      <div className={styles.participantName}>{otherParticipant.username}</div>
      <div className={styles.lastMessage}>{lastMessage || "No messages"}</div>
      <div className={styles.sharedTags}>
        Shared Tags: {chatData.sharedTags.map((tag) => tag.tag.name).join(", ")}
      </div>
    </div>
  );
};

const Chats = () => {
  const [chats, setChats] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const { userData } = useContext(UserContext);

  useEffect(() => {
    if (userData._id) {
      setIsLoading(true); // Set loading true when starting to fetch
      fetchUserChats(userData._id)
        .then((chats) => {
          setChats(chats);
          setIsLoading(false); // Set loading false upon completion
        })
        .catch((error) => {
          console.error("Failed to fetch chats", error);
          setIsLoading(false); // Ensure loading is set to false even on error
        });
    }
  }, [userData._id]);

  return (
    <PageWrapper ticker={false}>
      <div className={styles.chatsContainer}>
        {isLoading ? (
          <LoadingSpinner /> // Show loading spinner while fetching chats
        ) : chats.length > 0 ? (
          chats.map((chatData) => (
            <ChatBlock key={chatData._id} chatData={chatData} />
          ))
        ) : (
          <p className={styles.noChatsMessage}>No chats found</p>
        )}
      </div>
    </PageWrapper>
  );
};

export default Chats;
