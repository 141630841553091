// Your AuthOverlay component path
import React from "react";
import styles from "./AuthOverlay.module.css"; // Assuming you have CSS module setup
import Button from "components/Button/Button";

function AuthOverlay({ children }) {
  return (
    <>
      <div className={styles.overlay}>
        {/* Overlay Content Here */}
        <div className={styles.modal}>
          <p style={{ textAlign: "center" }}>
            Please log in or sign up here to continue.
          </p>

          <Button href="/login" className={styles.btn}>
            Log In
          </Button>
        </div>
        {/* Buttons or links to login/register */}
      </div>
      <div className={styles.content}>{children}</div>
    </>
  );
}

export default AuthOverlay;
