import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Button from "components/Button/Button";

const AnimatedButton = ({ delay, onComplete }) => {
  const [isSearching, setIsSearching] = useState(false);

  // Trigger switch with a delay and call onComplete once done
  useEffect(() => {
    const switchDelay = delay || 2000; // Delay for switching between buttons
    const timeoutId = setTimeout(() => {
      setIsSearching((prev) => !prev);
      onComplete();
    }, switchDelay);

    return () => clearTimeout(timeoutId);
  }, [delay]);

  const buttonVariants = {
    initial: { opacity: 0, scale: 0.95 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.95 },
  };

  return (
    <div style={{ margin: "1em 0" }}>
      <AnimatePresence mode="wait">
        {!isSearching ? (
          <motion.div
            key="start"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={buttonVariants}
            transition={{ duration: 0.3 }}
          >
            <Button cursor="default">
              <p>Start Search</p>
            </Button>
          </motion.div>
        ) : (
          <motion.div
            key="stop"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={buttonVariants}
            transition={{ duration: 0.3 }}
          >
            <Button color="error" cursor="default">
              <p>Stop Search</p>
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default AnimatedButton;
