// packages/client/src/components/JoyrideWrapper/JoyrideWrapper.jsx
import JoyrideFab from "components/JoyrideFab/JoyrideFab";
import React, { useState } from "react";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";

/**
 * Wraps the React Joyride component to provide a guided tour through the application.
 * This component abstracts Joyride's setup and state management, offering a simple interface
 * to start and stop the tour, along with customizable steps and initial run behavior.
 * It includes a floating action button (FAB) that triggers the start of the tour.
 *
 * The tour's appearance and behavior can be customized through the `styles` and `locale` props.
 * The component listens to Joyride's callback to automatically stop the tour on completion or when skipped.
 *
 * Props:
 * - steps {Array<Object>}: An array of step objects that define the tour. Each step object must at least have
 *   a `target` (CSS selector or an HTMLElement directly) and `content` (string or JSX to display).
 *   See Joyride documentation for more step object options.
 * - runOnInit {boolean}: Indicates whether the tour should start automatically upon component mount. Default is `false`.
 *
 * @param {Object} props The props for configuring the JoyrideWrapper.
 * @param {Array<Object>} props.steps Required. The steps of the tour.
 * @param {boolean} [props.runOnInit=false] Optional. Whether the tour should start automatically.
 * @returns {React.Component} A React component that renders the Joyride tour and a FAB for starting it.
 */
const JoyrideWrapper = ({ steps, runOnInit = false, ...rest }) => {
  const [run, setRun] = useState(runOnInit);
  const [stepIndex, setStepIndex] = useState(0); // Add stepIndex state

  const startTour = () => {
    setRun(true);
    setStepIndex(stepIndex < steps.length ? stepIndex : 0);
  };

  const stopTour = () => setRun(false);

  const handleJoyrideCallback = (data) => {
    const { status, action, index, type } = data;

    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
      action === ACTIONS.CLOSE
    ) {
      stopTour();
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Move to next step only if not closing the modal
      if (action !== ACTIONS.CLOSE) {
        const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
        setStepIndex(nextStepIndex);
      }
    }
  };

  const styles = {
    options: {
      arrowColor: "white", // Example to match modal background
      backgroundColor: "white",
      overlayColor: "rgba(0, 0, 0, 0.6)", // Semi-transparent overlay similar to your `.overlay` class
      textColor: "black",
      primaryColor: "var(--chair-orange)", // Primary button color
      zIndex: 1000, // Ensure it's on top, adjust as needed
      // width: 500, // Adjust based on your modal class
    },
  };

  return (
    <>
      {!run && <JoyrideFab onClick={startTour} />}
      <Joyride
        continuous
        run={run}
        steps={steps}
        showProgress
        stepIndex={stepIndex}
        callback={handleJoyrideCallback}
        styles={styles}
        locale={{
          last: "End",
        }}
        {...rest}
      />
    </>
  );
};

export default JoyrideWrapper;
