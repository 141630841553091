import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import styles from "./NewStory.module.css";
import { debounce } from "lodash";
import cn from "classnames";
import Button from "components/Button/Button";
import {
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
} from "@floating-ui/react";
import { FaEllipsis } from "react-icons/fa6";
import { useInteractions } from "@floating-ui/react";
import { useDismiss } from "@floating-ui/react";
import { FloatingArrow } from "@floating-ui/react";
import useTagsSearch from "hooks/useTagsSearch";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchTagByHyphenatedName } from "api/tags";
import { AnimatePresence, motion } from "framer-motion";
import "react-quill/dist/quill.snow.css"; // for snow theme
import "react-quill/dist/quill.bubble.css"; // for bubble theme
import ReactQuill from "react-quill";
import Portal from "components/Portal/Portal";
import * as API from "api/stories";
import { ToastContext } from "contexts/ToastContext";
import { Tooltip } from "react-tooltip";

/**
 * Moves the cursor to the end of the provided element.
 * @param {Element} element - The element to move the cursor to the end of.
 */
const moveCursorToEnd = (element) => {
  // Check if the element has content
  if (element && document.createRange) {
    // Create a new range
    const range = document.createRange();
    range.selectNodeContents(element);
    range.collapse(false); // Collapse the range to the end point. false means end of the content

    // Get the selection object
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges(); // Remove all ranges from the selection
      selection.addRange(range); // Add the new range
    }
  }
};

const EllipsisMenu = () => {
  const [open, setOpen] = useState(false);
  const arrowRef = useRef(null);
  const { refs, floatingStyles, context } = useFloating({
    open: open,
    onOpenChange: setOpen,
    placement: "bottom-end",
    middleware: [
      offset(1),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
        padding: 5,
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  return (
    <>
      <span
        className={cn(styles.button, styles.menuBtn)}
        onClick={() => setOpen(!open)}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <FaEllipsis />
      </span>

      {open && (
        <ul
          ref={refs.setFloating}
          style={floatingStyles}
          className={styles.menu}
          {...getFloatingProps()}
        >
          <li>Save As Draft</li>
          <li>Set Tags</li>
          <FloatingArrow ref={arrowRef} context={context} fill="white" />
        </ul>
      )}
    </>
  );
};

const TagInput = ({ selectedTag, setSelectedTag }) => {
  const [open, setOpen] = useState(false);
  const [tagQuery, setTagQuery] = useState("");

  const { tags, loading } = useTagsSearch(tagQuery, 1, 5); // Assuming you want to fetch the first page of results

  const arrowRef = useRef(null);
  const { refs, floatingStyles, context } = useFloating({
    open: open,
    onOpenChange: setOpen,
    placement: "bottom-start",
    middleware: [
      offset(5),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
        padding: 5,
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  // Add an onClick handler to the tagInputWrapper
  const handleWrapperClick = () => {
    // Focus on the .tag .input div
    refs.reference.current.focus();
  };

  useEffect(() => {
    if (tagQuery) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [tagQuery]);

  const clearTextContentAndState = () => {
    setTagQuery(""); // Clear the tag query state
    if (refs.reference.current) {
      refs.reference.current.textContent = ""; // Directly clear the contentEditable div's content
    }
  };

  const handleTagSelect = (tag) => {
    setSelectedTag(tag);
    clearTextContentAndState();
  };

  const clearTagInput = (e) => {
    e.stopPropagation(); // Prevent click from propagating
    clearTextContentAndState();
    setOpen(false);
    setSelectedTag(undefined);
  };

  // Animation variants for the selected tag
  const tagVariants = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, x: -100 },
  };

  return (
    <>
      <div className={styles.tagInputWrapper} onClick={handleWrapperClick}>
        <AnimatePresence mode="wait">
          {selectedTag && (
            <motion.div
              key={selectedTag._id}
              variants={tagVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.5 }}
              className={styles.selectedTag} // Use the option-badge class for styling
            >
              {selectedTag.name}
            </motion.div>
          )}
        </AnimatePresence>
        <div
          ref={refs.setReference}
          className={cn(styles.input, styles.tag)}
          placeholder="Tag"
          contentEditable
          value={tagQuery}
          onFocus={(e) => moveCursorToEnd(e.currentTarget)}
          onInput={(e) => setTagQuery(e.currentTarget.textContent)}
          {...getReferenceProps()}
        ></div>
        {(tagQuery || selectedTag) && (
          <span className={styles.button} onClick={clearTagInput}>
            &times;
          </span>
        )}
      </div>
      {open && (loading || tags.length > 0) && (
        <Portal>
          <ul
            ref={refs.setFloating}
            className={styles.menu}
            style={floatingStyles}
            {...getFloatingProps}
          >
            {tags.length > 0 &&
              tags.map((tag) => (
                <li
                  key={tag._id}
                  className={styles.dropdownItem}
                  onClick={() => handleTagSelect(tag)}
                >
                  {tag.name}
                </li>
              ))}
            {loading && <li className={styles.dropdownItem}>Loading...</li>}

            <FloatingArrow ref={arrowRef} context={context} fill="white" />
          </ul>
        </Portal>
      )}
    </>
  );
};

function NewStory() {
  const [title, setTitle] = useState("");

  const handleTitleChange = (event) => {
    setTitle(event.target.innerText);
    setStatus("Saving...");
  };

  const [bodyContent, setBodyContent] = useState("");

  const handleBodyContentChange = (value) => {
    setBodyContent(value);
    setStatus("Saving...");
  };

  const [status, setStatus] = useState("Draft");
  const [selectedTag, setSelectedTag] = useState(undefined);

  const location = useLocation(); // Use useLocation hook to access the current location

  useEffect(() => {
    // Function to fetch tag details
    const fetchTagDetails = async () => {
      const queryParams = new URLSearchParams(location.search);
      const tagParam = queryParams.get("tag");
      if (tagParam) {
        try {
          const response = await fetchTagByHyphenatedName(tagParam);
          setSelectedTag(response); // Assuming the API directly returns the tag object
        } catch (error) {
          console.error("Error fetching tag details:", error);
          setSelectedTag(undefined); // Set selectedTag to undefined if there's an error
        }
      }
    };

    fetchTagDetails();
  }, [location.search]); // Re-run the effect if the search query changes

  useEffect(() => {
    const handleAutoSave = () => {
      console.log("Auto-saving content:", bodyContent);
      setStatus("Saved");
    };

    const debouncedSave = debounce(handleAutoSave, 3000);
    debouncedSave();

    return () => debouncedSave.cancel();
  }, [bodyContent]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // Check if the target is not the title, allowing default behavior in the body
      if (!event.target.classList.contains(styles.title)) {
        // Allow the default behavior (new line) in the body div
        return; // Exit the function early
      }
      event.preventDefault(); // Prevent default only if it's the title
      document.querySelector(`.${styles.body}`).focus();
    }

    // New 'Backspace' key logic for empty .body divs
    if (
      event.key === "Backspace" &&
      event.target.innerText === "" &&
      event.target.classList.contains(styles.body)
    ) {
      event.preventDefault(); // Prevent the default backspace action

      const bodies = document.querySelectorAll(`.${styles.body}`);
      const currentIndex = Array.from(bodies).indexOf(event.target);

      if (currentIndex > 0) {
        // Focus on the preceding .body div
        bodies[currentIndex - 1].focus();
      } else {
        // If it's the first .body, focus on the .title
        document.querySelector(`.${styles.title}`).focus();
      }
    }
  };

  const handlePostClick = (event) => {
    // Check if the clicked element or its parents have the .title or .tag class
    let element = event.target;
    let isTitleOrTagClicked = false;
    while (element && element !== document) {
      if (
        element.classList.contains(styles.title) ||
        element.classList.contains(styles.tag)
      ) {
        isTitleOrTagClicked = true;
        break;
      }
      element = element.parentNode;
    }

    // If neither .title nor .tag is clicked, and the click is within the .post div, focus on the latest .body element
    if (!isTitleOrTagClicked && event.currentTarget === event.target) {
      const bodies = document.querySelectorAll(`.${styles.body}`);
      const lastBody = bodies[bodies.length - 1];
      if (lastBody) lastBody.focus();
    }
  };

  // Define the toolbar options
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // Add other toolbar options as needed
      // Note: Not including font size related options here
    ],
  };

  // Specify which formats are allowed
  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    // Note: Not including 'size' or any font size related formats
  ];

  // Inside your NewStory component
  const [titleTooltip, setTitleTooltip] = useState({
    open: false,
    message: "",
  });
  const [tagTooltip, setTagTooltip] = useState({ open: false, message: "" });
  const [bodyTooltip, setBodyTooltip] = useState({ open: false, message: "" });

  // Function to show tooltip
  const showTooltip = (setter, message) => {
    setter({ open: true, message });
    setTimeout(() => setter({ open: false, message: "" }), 3000); // Hide after 3 seconds
  };

  const { addToast } = useContext(ToastContext);
  const navigate = useNavigate();

  const handlePublish = async () => {
    debugger;
    if (!title.trim()) {
      showTooltip(setTitleTooltip, "Title is required.");
      return;
    }
    if (!selectedTag) {
      showTooltip(setTagTooltip, "Tag is required.");
      return;
    }
    if (!bodyContent.trim()) {
      showTooltip(setBodyTooltip, "Body is required.");
      return;
    }
    try {
      const result = await API.submitStory({
        title,
        body: bodyContent,
        tag: selectedTag?._id, // Ensure you pass the tag ID, not the entire object
      });
      addToast("Story published successfully!", "success"); // Use `addToast` from `ToastContext`
      // Redirect logic here, using your routing library (e.g., react-router-dom)

      navigate(`/stories/${result._id}`);
    } catch (error) {
      addToast("Failed to publish story.", "error"); // Use `addToast` from `ToastContext`
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <span className={styles.status}>{status}</span>

        <Button
          onClick={handlePublish}
          disabled={!title.trim() && !bodyContent.trim()}
        >
          Publish
        </Button>
        <EllipsisMenu />
      </div>
      <div className={styles.post} onClick={handlePostClick}>
        <section data-tooltip-id="title">
          <div
            className={cn(styles.input, styles.title)}
            contentEditable
            placeholder="Title"
            onInput={handleTitleChange}
            onKeyDown={handleKeyDown}
            suppressContentEditableWarning={true}
          />
        </section>
        <Portal>
          <Tooltip
            id="title"
            isOpen={titleTooltip.open}
            content={titleTooltip.message}
            className={styles.tooltip}
            place="bottom"
          ></Tooltip>
        </Portal>
        <section data-tooltip-id="tag">
          <TagInput selectedTag={selectedTag} setSelectedTag={setSelectedTag} />
        </section>

        <Portal>
          <Tooltip
            id="tag"
            isOpen={tagTooltip.open}
            content={tagTooltip.message}
            className={styles.tooltip}
            place="bottom"
          ></Tooltip>
        </Portal>
        <section data-tooltip-id="body">
          <ReactQuill
            theme="bubble"
            value={bodyContent}
            placeholder="Tell your story..."
            modules={modules}
            formats={formats}
            onChange={handleBodyContentChange}
            className={styles.body}
          />
        </section>
        <Portal>
          <Tooltip
            id="body"
            isOpen={bodyTooltip.open}
            content={bodyTooltip.message}
            className={styles.tooltip}
            place="bottom"
          ></Tooltip>
        </Portal>
        {/* <section>
          <div
            className={cn(styles.input, styles.body)}
            contentEditable
            placeholder="Tell your story..."
            onInput={handleContentChange}
            onKeyDown={handleKeyDown}
            suppressContentEditableWarning={true}
          >
            <div>
              <br />
            </div>
          </div>
        </section> */}
      </div>
    </div>
  );
}

export default NewStory;
