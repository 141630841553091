// packages/client/src/contexts/UserContext.js
import { fetchUserData } from "api/auth";

import { BASE_SERVER_URL } from "config";
import React, { createContext, useEffect, useState } from "react";

export const UserContext = createContext();

// Define and export the Google login URL
export const GOOGLE_LOGIN_URL = `${BASE_SERVER_URL}/auth/google`;

export const UserContextProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUserData()
      .then((data) => {
        setUserData(data);
        setLoading(false);
      })
      .catch(() => {
        // Handle the error here if needed
        setLoading(false);
        setUserData(null);
      });

    // setCookie();
  }, []);

  const handleGoogleLogin = () => {
    window.open(GOOGLE_LOGIN_URL, "_self");
  };

  const handleLogout = () => {
    fetch(`${BASE_SERVER_URL}/auth/logout`, {
      method: "POST",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setUserData(null);
          window.location.href = "/";
        }
      });
  };

  const updateUser = (userData) => {
    try {
      setUserData(userData);
    } catch (err) {
      throw new Error("Error updating user" + err.message);
    }
  };

  return (
    <UserContext.Provider
      value={{
        userData,
        setUserData,
        loading,
        handleGoogleLogin,
        handleLogout,
        updateUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
