const { createContext, useState, useCallback } = require("react");

const ToastContext = createContext();

const ToastContextProvider = ({ children }) => {
  const [toastList, setToastList] = useState([]);

  const addToast = useCallback((message, type = "info") => {
    console.trace();
    const id = `toast-${Date.now()}-${Math.random().toString(36).slice(2)}`;

    // Using Date.now() and Math.random() for a unique ID
    setToastList((prev) => [...prev, { id, message, type }]);
  }, []);

  const removeToast = useCallback((id) => {
    setToastList((prev) => prev.filter((toast) => toast.id !== id));
  }, []); // Similarly, dependencies array is empty

  return (
    <ToastContext.Provider value={{ toastList, addToast, removeToast }}>
      {children}
    </ToastContext.Provider>
  );
};

export { ToastContextProvider, ToastContext };

// usage: <ToastNotifs toastList={toastList} removeToast={removeToast} />
