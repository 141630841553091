export default function handleError(error) {
  console.error("API error: ", error);

  if (error.response) {
    // Request made and server responded with a status code outside 2xx
    throw new Error(
      error.response.data.message || "Server responded with an error"
    );
  } else if (error.request) {
    // Request made but no response received
    throw new Error("No response from server");
  } else {
    // Error in setting up the request
    throw new Error("Error in setting up request: " + error.message);
  }
}
