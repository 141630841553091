import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import styles from "./FloatingInTags.module.css";

const Tag = ({ tag, delay, onLastAnimationComplete }) => {
  return (
    <motion.div
      initial={{ opacity: 0, x: -10 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay, duration: 0.5 }}
      onAnimationComplete={onLastAnimationComplete} // This will be called only for the last tag
      className={styles.tag} // Assuming this class is defined in your styles
    >
      {tag}
    </motion.div>
  );
};

const FloatingInTags = ({ tags: tags_arg, onComplete }) => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    setTags(tags_arg);
  }, [tags_arg]);

  const handleAnimationComplete = () => {
    onComplete();
    // Any additional logic you want to execute after the last animation completes
  };

  return (
    <div className={styles.wrapper}>
      {tags.map((tag, i) => (
        <Tag
          key={i}
          tag={tag}
          delay={i * 0.3}
          onLastAnimationComplete={
            i === tags.length - 1 ? handleAnimationComplete : undefined
          }
        />
      ))}
    </div>
  );
};

export default FloatingInTags;
