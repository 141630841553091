// packages/client/src/pages/Register/StepReview.jsx
import React, { useState } from "react";
import common from "../Register.module.css";
import self from "./StepReview.module.css";
import ScrollWrapper from "components/ScrollWrapper/ScrollWrapper";

import { motion } from "framer-motion";
import getAge from "utils/getAge";

import Modal from "components/Modal/Modal";

const SelectedModal = ({ data, onClose }) => {
  return (
    <Modal isOpen onClose={onClose}>
      <div className={self.modalBody}>
        <p className={self.selectedStory}>{data.story}</p>
        <p className={self.tag}>{data.tag.name}</p>
      </div>
    </Modal>
  );
};

const StepReview = ({ data }) => {
  function getGender(val) {
    let gender = val.toLowerCase();
    if (gender === "male") {
      return "(M)";
    } else if (gender === "female") {
      return "(F)";
    } else {
      return "person";
    }
  }

  const [animationStep, setAnimationStep] = useState(0);

  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const [selectedExperience, setSelectedExperience] = useState();

  const selectExperience = ({ tag, story }) => {
    setSelectedExperience({ tag, story });
  };

  const handleCloseModal = () => {
    setSelectedExperience(null);
  };

  return (
    <>
      <div className={common.header}>
        <motion.h1
          variants={variants}
          initial="hidden"
          animate="visible"
          transition={{ type: "fadeIn", duration: 2 }}
        >
          Review your information
        </motion.h1>
      </div>

      <ScrollWrapper>
        <div className={self.container}>
          <div className={self.section}>
            <motion.p
              style={{ textAlign: "center" }}
              variants={variants}
              initial="hidden"
              animate="visible"
              transition={{ duration: 1, delay: 1.5 }}
            >
              Your username is{" "}
              <span className={self.data}>{data.username}</span>
            </motion.p>
          </div>
          <div className={self.section}>
            <motion.p
              variants={variants}
              initial="hidden"
              animate="visible"
              transition={{ duration: 1, delay: 2.5 }}
            >
              You're a{" "}
              <span className={self.data}>{getAge(data.birthdate)}</span> year
              old <span className={self.data}>{getGender(data.gender)}</span>{" "}
              from <span className={self.data}>{data.location}</span>
            </motion.p>
          </div>
          <div className={self.section}>
            {data.lifeExperiences.length > 0 ? (
              <>
                <motion.div
                  variants={variants}
                  initial="hidden"
                  animate="visible"
                  transition={{ duration: 1, delay: 3.5 }}
                >
                  <p className={self.bold} style={{ marginBottom: "10px" }}>
                    ...who has experienced
                  </p>
                </motion.div>
                <div className={self.tagStoriesWrapper}>
                  {data.lifeExperiences.map(({ tag, story }, i) => (
                    <motion.div
                      key={tag._id}
                      className={self.experience}
                      variants={variants}
                      initial="hidden"
                      animate="visible"
                      transition={{ duration: 1, delay: 4 + i }}
                      onClick={() => selectExperience({ tag, story })}
                    >
                      <span className={self.tag}>{tag.name}</span>
                      <p className={self.story}>{story}</p>
                    </motion.div>
                  ))}
                </div>
              </>
            ) : (
              <p style={{ marginBottom: "10px" }}>
                You haven't shared any stories yet...
              </p>
            )}
          </div>
        </div>
      </ScrollWrapper>
      {selectedExperience && (
        <SelectedModal data={selectedExperience} onClose={handleCloseModal} />
      )}
    </>
  );
};

export default StepReview;
