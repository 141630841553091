// packages/client/src/components/Combobox/Combobox.jsx
import ReactDOM from "react-dom";
import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import styles from "./Combobox.module.css";
import cn from "classnames";
import { createPopper } from "@popperjs/core";
import Portal from "components/Portal/Portal";

const Combobox = ({ options, value, onSelect, placeholder, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const comboboxRef = useRef(null);

  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const [input, setInput] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);

  useEffect(() => {
    if (!value) {
      setInput("");
    } else {
      setInput(value);
    }
  }, [value]);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        comboboxRef.current &&
        !comboboxRef.current.contains(event.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [comboboxRef]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
    const input = e.target.value.toLowerCase();
    const filtered = options.filter((option) =>
      option.toLowerCase().startsWith(input)
    );
    setFilteredOptions(filtered);
  };

  function clearOptions() {
    onSelect(null);
  }

  useEffect(() => {
    if (isInputFocused) {
      setIsOpen(true);
    }
  }, [isInputFocused]);

  useEffect(() => {
    let popperInstance = null;

    const adjustDropdownWidth = () => {
      if (dropdownRef.current && comboboxRef.current) {
        const referenceWidth =
          comboboxRef.current.getBoundingClientRect().width;
        dropdownRef.current.style.width = `${referenceWidth}px`;
      }
    };

    if (isOpen && comboboxRef.current && dropdownRef.current) {
      adjustDropdownWidth(); // Adjust width before Popper calculates its position

      popperInstance = createPopper(comboboxRef.current, dropdownRef.current, {
        placement: "bottom-start",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 2], // Adjust these values to control the dropdown's offset from the input field
            },
          },
          {
            name: "preventOverflow",
            options: {
              boundary: "document", // Ensures the popper can overflow containers but not the viewport
            },
          },
        ],
      });

      // Call this function whenever you expect the width of the reference element might change
      window.addEventListener("resize", adjustDropdownWidth);
    }

    return () => {
      if (popperInstance) {
        popperInstance.destroy();
        popperInstance = null;
      }
      window.removeEventListener("resize", adjustDropdownWidth); // Cleanup resize listener
    };
  }, [isOpen]);

  function isSelected(option) {
    return option === value;
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && filteredOptions.length > 0) {
      e.preventDefault(); // Prevent form submission if combobox is used within a form
      onSelect(filteredOptions[0]);
      setIsOpen(false);
    }
  };

  return (
    <div
      ref={comboboxRef}
      className={cn(styles.combobox, { [styles.focused]: isInputFocused })}
      onClick={() => {
        inputRef.current.focus();
      }}
      onBlur={() => {
        setIsOpen(false);
      }}
    >
      <input
        ref={inputRef}
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
        onChange={handleInputChange}
        className={cn(styles.comboboxInput, className)}
        type="text"
        value={input}
        placeholder={placeholder}
        onKeyDown={handleKeyDown} // Add the onKeyDown event handler here
      />
      <button
        className={styles["clear-btn"]}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          clearOptions();
        }}
      >
        &times;
      </button>
      <div className={styles.divider}></div>
      <div className={styles.caret}></div>
      <Portal>
        <ul
          ref={dropdownRef}
          className={cn(styles.dropdown, { [styles.show]: isOpen })}
        >
          {filteredOptions.map((option, index) => (
            <li
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                onSelect(option);
                setIsOpen(false);
              }}
              onMouseDown={(e) => e.preventDefault()}
              style={{ cursor: "pointer" }}
              className={cn(styles["dropdown-item"], {
                [styles.selected]: isSelected(option),
              })}
            >
              {option}
            </li>
          ))}
        </ul>
      </Portal>
    </div>
  );
};

export default Combobox;
